import React from "react";
import { LocalOffer, Settings } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Skeleton, Stack, Typography } from "@mui/material";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FileCloud, ScissorsCutting } from "mdi-material-ui";
import { Center } from "~/components/Center";
import { Helmet } from "~/components/Helmet";
import { ErrorMessage } from "~/components/error-message";
import * as Layout from "~/layout";
import { SidebarSwitch, SidebarTrigger } from "~/layout";
import PlaybackController from "./components/PlaybackController";
import TopicSection from "./components/TopicSection";
import { PlayerConfigProvider } from "./config";
import {
  useDefaultLayoutProfile,
  usePlayerLog,
  usePlayerParams,
} from "./hooks";
import {
  InferenceFrameControls,
  InferenceFrameControlsProvider,
  OnDemandInferenceControls,
  OnDemandInferenceControlsProvider,
  OnDemandInferenceProvider,
} from "./inference";
import { PlayerLayoutStateProvider } from "./layout";
import { PanelLayoutProvider, VisualizationType } from "./panels";
import {
  LogPlaybackSourceProvider,
  PlaybackSettingsProvider,
  PlaybackTimerProvider,
} from "./playback";
import { RecordStoreProvider } from "./record-store";
import {
  DigestionSidebar,
  DigestionStateProvider,
  LabelManagementSection,
  LogSidebar,
  PanelControlsSidebar,
  PlayerSettingsSidebar,
  TaggingSidebar,
} from "./sidebars";
import { TagStoreProvider } from "./tags";
import { Timestep } from "./types";
import { logIsBounded, logIsTooLong } from "./utils";
import {
  ThreeDControlsProvider,
  VisualizationStoreParamsProvider,
} from "./visualizations";

export function PlayerPage() {
  const { logId } = usePlayerParams();

  const logQuery = usePlayerLog();

  const initialLayoutProfile = useDefaultLayoutProfile();

  let content;
  if (logId == null) {
    content = (
      <Center>
        <Typography variant="h5" component="p" paragraph sx={{ px: 4 }}>
          To get started, search for a log in the side panel to the right
        </Typography>
      </Center>
    );
  } else if (logQuery.isSuccess) {
    if (!logIsBounded(logQuery.data)) {
      content = (
        <Center>
          <Typography variant="h5" component="p" paragraph>
            This log doesn't have any records to play yet
          </Typography>
          <LoadingButton
            variant="contained"
            loading={logQuery.isRefetching}
            onClick={() => logQuery.refetch()}
          >
            Refresh
          </LoadingButton>
        </Center>
      );
    } else if (logIsTooLong(logQuery.data)) {
      content = (
        <ErrorMessage disableTypography>
          <ErrorMessage.Paragraph>
            This log is too long to play.
          </ErrorMessage.Paragraph>
          <ErrorMessage.Paragraph>
            Studio only supports logs less than 24 hours long.
          </ErrorMessage.Paragraph>
        </ErrorMessage>
      );
    } else {
      content = <TopicSection />;
    }
  } else {
    content = <TopicSection />;
  }

  const hasLogId = logId != null;

  return (
    <>
      <Helmet>
        <title>Player</title>
      </Helmet>
      <PlayerLayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title={<PlayerHeaderTitle />}
            actions={
              <Stack direction="row" spacing={1}>
                <SidebarTrigger
                  title="Logs"
                  sidebarId="logs"
                  icon={<FileCloud />}
                />
                <SidebarTrigger
                  title="Tagging"
                  sidebarId="tagging"
                  icon={<LocalOffer />}
                />
                <SidebarTrigger
                  title="Create a digestion"
                  sidebarId="digestions"
                  icon={<ScissorsCutting />}
                />
                <SidebarTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              </Stack>
            }
          />
          <PlayerConfigProvider>
            <PlaybackSettingsProvider>
              <PanelLayoutProvider
                // This will reset all panel layout state when switching between
                // there being some log selected and no log selected. It's
                // important for the key not to change when switching between
                // logs since keeping the same panels open is a supported use
                // case. This value should only change in a few situations:
                // 1. Selecting a log for the first time on the player page
                //    (`false` -> `true`)
                // 2. Clicking the browser's back button after situation (1) or
                //    otherwise traversing session history to an entry where the
                //    URL doesn't have a log ID (`true` -> `false`)
                // 3. Clicking the "Player" link in the global navigation when
                //    a log is currently selected (`true` -> `false`)
                key={String(hasLogId)}
                initialLayout={initialLayoutProfile?.layout}
              >
                <LogPlaybackSourceProvider>
                  <VisualizationStoreParamsProvider
                    params={{
                      [VisualizationType.Timeline]: {
                        timestepOverride: Timestep.Decisecond,
                        count: 34 * 10,
                        limit: 100,
                        prefetchBehind: 30 * 10,
                        prefetchAhead: 90 * 10,
                      },
                      [VisualizationType.Chart]: {
                        timestepOverride: Timestep.Second,
                        count: 34,
                        limit: 30,
                        prefetchBehind: 90,
                        prefetchAhead: 30,
                      },
                      [VisualizationType.Image]: {
                        count: 30,
                        limit: 30,
                        prefetchBehind: 30,
                        prefetchAhead: 30 * 7,
                      },
                      [VisualizationType.ThreeD]: {
                        count: 30,
                        limit: 5,
                        prefetchBehind: 10,
                        prefetchAhead: 90,
                      },
                    }}
                  >
                    <RecordStoreProvider>
                      <TagStoreProvider>
                        <PlaybackTimerProvider>
                          <ThreeDControlsProvider>
                            <OnDemandInferenceProvider>
                              <OnDemandInferenceControlsProvider
                                element={<OnDemandInferenceControls />}
                              >
                                <InferenceFrameControlsProvider
                                  element={<InferenceFrameControls />}
                                >
                                  <Layout.Main>
                                    <Stack sx={{ flex: 1, minWidth: 0 }}>
                                      {content}
                                      <PlaybackController />
                                    </Stack>
                                  </Layout.Main>
                                  <Layout.Sidebar>
                                    <DigestionStateProvider>
                                      <SidebarSwitch
                                        options={[
                                          {
                                            sidebarId: "logs",
                                            element: <LogSidebar />,
                                          },
                                          {
                                            sidebarId: "digestions",
                                            element: <DigestionSidebar />,
                                          },
                                          {
                                            sidebarId: "settings",
                                            element: <PlayerSettingsSidebar />,
                                          },
                                          {
                                            sidebarId: "panel-controls",
                                            element: <PanelControlsSidebar />,
                                          },
                                          {
                                            sidebarId: "tagging",
                                            element: (
                                              <TaggingSidebar
                                                renderLabelManagementSection={(
                                                  props,
                                                ) => (
                                                  <LabelManagementSection
                                                    {...props}
                                                  />
                                                )}
                                              />
                                            ),
                                          },
                                        ]}
                                      />
                                    </DigestionStateProvider>
                                  </Layout.Sidebar>
                                </InferenceFrameControlsProvider>
                              </OnDemandInferenceControlsProvider>
                            </OnDemandInferenceProvider>
                          </ThreeDControlsProvider>
                        </PlaybackTimerProvider>
                      </TagStoreProvider>
                    </RecordStoreProvider>
                  </VisualizationStoreParamsProvider>
                </LogPlaybackSourceProvider>
              </PanelLayoutProvider>
            </PlaybackSettingsProvider>
          </PlayerConfigProvider>
        </Layout.Root>
      </PlayerLayoutStateProvider>
      <ReactQueryDevtools position="bottom-left" />
    </>
  );
}

function PlayerHeaderTitle() {
  const { logId } = usePlayerParams();

  const logQuery = usePlayerLog();

  if (logId === null) {
    return "Player";
  } else if (logQuery.isSuccess) {
    return `Player - ${logQuery.data.name}`;
  } else {
    return (
      <>
        Player - <Skeleton width="15ch" sx={{ display: "inline-block" }} />
      </>
    );
  }
}
