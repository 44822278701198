/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ObjectCreateRequest {
  key: string;
  contentType?: string | null;
}

export function ObjectCreateRequestFromJSON(json: any): ObjectCreateRequest {
  return ObjectCreateRequestFromJSONTyped(json);
}

export function ObjectCreateRequestFromJSONTyped(
  json: any,
): ObjectCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    key: json["key"],
    contentType: json["content_type"],
  };
}

export function ObjectCreateRequestToJSON(
  value: ObjectCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    key: value.key,
    content_type: value.contentType,
  };
}
