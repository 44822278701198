import {
  BooleanField,
  NumberField,
  SelectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { processStateOptions, WorkflowSelect } from "~/lqs";
import { listDigestionPartsSchema } from "../schemas";
import { useDigestionPartSearchRequest } from "./DigestionPartTable";

export function DigestionPartFilters() {
  const [request, setRequest] = useDigestionPartSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listDigestionPartsSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <NumberField control={control} name="sequence" />
      <WorkflowSelect control={control} name="workflowId" />
      <BooleanField control={control} name="workflowIdNull" />
      <SelectField
        control={control}
        name="state"
        options={processStateOptions}
      />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
