import {
  CheckboxField,
  NumberField,
  ObjectField,
  SelectField,
} from "~/components/Form";
import {
  EditLqsResourceForm,
  processStateOptions,
  useDigestionPart,
  useUpdateDigestionPart,
  WorkflowSelect,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_DIGESTION_PART_FIELDS,
  editDigestionPartSchema,
} from "../schemas";

export function DigestionPartEditForm() {
  const { digestionId, digestionPartId } = useTypedParams(
    lqsRoutePaths.DIGESTION_PART_EDIT,
  );

  const lqsNavigator = useLqsNavigator({ toDigestionPartDetails: true });

  return (
    <EditLqsResourceForm
      schema={editDigestionPartSchema}
      resourceName="digestion part"
      query={useDigestionPart(digestionId, digestionPartId, {
        select: selectData,
      })}
      editableFields={EDITABLE_DIGESTION_PART_FIELDS}
      mutation={useUpdateDigestionPart(digestionId, digestionPartId)}
      detailsLocation={lqsNavigator.toDigestionPartDetails({
        digestionId,
        digestionPartId,
      })}
    >
      {(control) => (
        <>
          <NumberField control={control} name="sequence" required />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <NumberField control={control} name="progress" />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <ObjectField control={control} name="error" />
          <CheckboxField control={control} name="locked" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
