import { useMemo } from "react";
import { millisecondsToNanoseconds, secondsToNanoseconds } from "~/lib/dates";
import type { Topic } from "~/lqs";
import type { Maybe } from "~/types";
import type { InferenceFrames } from "../../inference";
import { getInferenceRecordType, getInferenceType } from "../../inference";
import { VisualizationType } from "../../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../../playback";
import type {
  PlayerRecord,
  RecordsRequest,
  RecordStore,
  StoreSnapshotParams,
  TopicSubscription,
} from "../../record-store";
import { useRecordStoreContext, useStoreSnapshot } from "../../record-store";
import type { TimestepValue } from "../../types";
import { SampleFrequency, Timestep } from "../../types";
import { floorTimestampForTimestep } from "../../utils";
import { useVisualizationStoreParams } from "../context";
import type {
  ImageFrame,
  ImageFramesRequest,
  ImageFramesSnapshot,
} from "./types";

function createStoreRequests(
  { imageTopic, inferenceTopic, timestamp, timestep }: ImageFramesRequest,
  count: number,
):
  | [imageTopicRequest: RecordsRequest<"image">]
  | [
      imageTopicRequest: RecordsRequest<"image">,
      inferenceTopicRequest: RecordsRequest<
        "image" | "detections" | "segmentations"
      >,
    ] {
  const sharedRequestFields: Pick<
    RecordsRequest<any>,
    "timestamp" | "count" | "frequency"
  > = {
    timestamp,
    count,
    frequency:
      timestep === Timestep.Second
        ? SampleFrequency.Second
        : SampleFrequency.Decisecond,
  };

  const imageRecordsRequest: RecordsRequest<"image"> = {
    recordType: "image",
    topicId: imageTopic.id,
    ...sharedRequestFields,
  };

  const inferenceRecordType = getInferenceRecordType(inferenceTopic);

  if (inferenceTopic == null || inferenceRecordType == null) {
    return [imageRecordsRequest];
  } else {
    const inferenceRecordsRequest: RecordsRequest<
      "image" | "detections" | "segmentations"
    > = {
      recordType: inferenceRecordType,
      topicId: inferenceTopic.id,
      ...sharedRequestFields,
    };

    return [imageRecordsRequest, inferenceRecordsRequest];
  }
}

function subscribeToStore(
  store: RecordStore,
  params: ImageFramesRequest,
  count: number,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
  notifyFn: () => void,
): () => void {
  const [imageRecordsRequest, inferenceRecordsRequest] = createStoreRequests(
    params,
    count,
  );

  const sharedSubscriptionFields: Pick<
    TopicSubscription<never>,
    "limit" | "prefetchBehind" | "prefetchAhead" | "notify"
  > = {
    limit,
    prefetchBehind,
    prefetchAhead,
    notify: notifyFn,
  };

  const unsubscribeFns = new Array<() => void>();

  unsubscribeFns.push(
    store.subscribe({
      ...imageRecordsRequest,
      ...sharedSubscriptionFields,
      topicStartTime: params.imageTopic.startTime,
      topicEndTime: params.imageTopic.endTime,
    }),
  );

  if (inferenceRecordsRequest != null) {
    unsubscribeFns.push(
      store.subscribe({
        ...inferenceRecordsRequest,
        ...sharedSubscriptionFields,
        // The records request will only be defined if the inference topic also
        // is so the non-null assertion on `inferenceTopic` should be fine. It
        // doesn't guarantee the start or end times are non-null though.
        topicStartTime: params.inferenceTopic!.startTime,
        topicEndTime: params.inferenceTopic!.endTime,
      }),
    );
  }

  return () => {
    unsubscribeFns.forEach((unsubscribe) => unsubscribe());
  };
}

function getStoreSnapshot(
  store: RecordStore,
  framesRequest: ImageFramesRequest,
  count: number,
): ImageFramesSnapshot {
  const [imageRecordsRequest, inferenceRecordsRequest] = createStoreRequests(
    framesRequest,
    count,
  );

  const imageRecordsSnapshot = store.getRecords(imageRecordsRequest);

  const inferenceRecordsSnapshot =
    inferenceRecordsRequest == null
      ? null
      : store.getRecords(inferenceRecordsRequest);

  if (imageRecordsSnapshot.status === "rejected") {
    return {
      request: framesRequest,
      ...imageRecordsSnapshot,
    };
  }

  if (inferenceRecordsSnapshot?.status === "rejected") {
    return {
      request: framesRequest,
      ...inferenceRecordsSnapshot,
    };
  }

  if (imageRecordsSnapshot.status === "pending") {
    return {
      request: framesRequest,
      ...imageRecordsSnapshot,
    };
  }

  if (inferenceRecordsSnapshot?.status === "pending") {
    return {
      request: framesRequest,
      ...inferenceRecordsSnapshot,
    };
  }

  const { value: imageRecords } = imageRecordsSnapshot;

  const playbackFrameTimestamp = floorTimestampForTimestep(
    framesRequest.timestamp,
    framesRequest.timestep,
  );

  const mostRecentRecordIndex = imageRecords.findLastIndex(
    (record) =>
      floorTimestampForTimestep(record.timestamp, framesRequest.timestep) <=
      playbackFrameTimestamp,
  );

  const mostRecentRecord: PlayerRecord<"image"> | undefined =
    imageRecords[mostRecentRecordIndex];

  let currentImageFrame: ImageFrame | null = null;
  if (mostRecentRecord !== undefined) {
    const freshDuration =
      framesRequest.timestep === Timestep.Second
        ? secondsToNanoseconds(2)
        : millisecondsToNanoseconds(200);

    const isStale =
      playbackFrameTimestamp -
        floorTimestampForTimestep(
          mostRecentRecord.timestamp,
          framesRequest.timestep,
        ) >
      freshDuration;

    let inferenceFrames: InferenceFrames | null = null;
    if (inferenceRecordsSnapshot != null) {
      const inferenceRecord = inferenceRecordsSnapshot.value.find(
        (record) => record.timestamp === mostRecentRecord.timestamp,
      );

      inferenceFrames = {
        // The inference topic snapshot should only be defined if both the
        // inference topic is defined AND it has a known inference type, so the
        // two non-null assertions below should be safe.
        type: getInferenceType(framesRequest.inferenceTopic)!,
        topicId: framesRequest.inferenceTopic!.id,
        current: inferenceRecord?.data ?? null,
      } as InferenceFrames;
    }

    currentImageFrame = {
      timestamp: mostRecentRecord.timestamp,
      isStale,
      data: mostRecentRecord.data,
      inferenceFrames,
    };
  }

  return {
    request: framesRequest,
    status: "fulfilled",
    value: {
      current: currentImageFrame,
      nextTimestamp: imageRecords[mostRecentRecordIndex + 1]?.timestamp ?? null,
    },
  };
}

/**
 * Create the underlying values to be passed to `useStoreSnapshot` for any
 * component wanting to subscribe to a specific panel's image frames. This logic
 * is shared between `useImageFrames` and the ODI provider, the latter of which
 * needs to conditionally get these values only when various conditions are met,
 * hence why it can't call `useImageFrames` directly.
 */
export function createImageFramesSnapshotParams(
  recordStore: RecordStore,
  imageTopic: Topic,
  inferenceTopic: Maybe<Topic>,
  timestamp: bigint,
  timestep: TimestepValue,
  count: number,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
): StoreSnapshotParams<ImageFramesSnapshot> {
  const request: ImageFramesRequest = {
    imageTopic,
    inferenceTopic,
    timestamp,
    timestep,
  };

  return {
    request,
    subscribe: subscribeToStore.bind(
      null,
      recordStore,
      request,
      count,
      limit,
      prefetchBehind,
      prefetchAhead,
    ),
    getSnapshot: getStoreSnapshot.bind(null, recordStore, request, count),
  };
}

export function useImageFrames({
  topic,
  inferenceTopic,
}: {
  topic: Topic;
  inferenceTopic: Maybe<Topic>;
}): {
  snapshot: ImageFramesSnapshot;
  isPlaceholder: boolean;
} {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const recordStore = useRecordStoreContext();

  const { count, limit, prefetchBehind, prefetchAhead } =
    useVisualizationStoreParams(VisualizationType.Image);

  const { request, subscribe, getSnapshot } = useMemo(
    () =>
      createImageFramesSnapshotParams(
        recordStore,
        topic,
        inferenceTopic,
        playbackSource.timestamp,
        playbackSettings.timestep,
        count,
        limit,
        prefetchBehind,
        prefetchAhead,
      ),
    [
      recordStore,
      topic,
      inferenceTopic,
      playbackSource.timestamp,
      playbackSettings.timestep,
      count,
      limit,
      prefetchBehind,
      prefetchAhead,
    ],
  );

  return useStoreSnapshot({
    request,
    subscribe,
    getSnapshot,
  });
}
