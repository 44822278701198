/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export const TypeEncoding = {
  Ros1: "ros1",
  Rbuf: "rbuf",
  Json: "json",
  Cdr: "cdr",
} as const;
export type TypeEncoding = (typeof TypeEncoding)[keyof typeof TypeEncoding];

export function TypeEncodingFromJSON(json: any): TypeEncoding {
  return TypeEncodingFromJSONTyped(json);
}

export function TypeEncodingFromJSONTyped(json: any): TypeEncoding {
  return json as TypeEncoding;
}

export function TypeEncodingToJSON(value?: TypeEncoding | null): any {
  return value as any;
}
