/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RecordCreateRequest {
  locked?: boolean;
  timestamp: bigint;
  note?: string | null;
  context?: object | null;
  queryData?: object | null;
  auxiliaryData?: object | null;
  dataOffset?: number | null;
  dataLength?: number | null;
  chunkCompression?: string | null;
  chunkOffset?: number | null;
  chunkLength?: number | null;
  source?: string | null;
}

export function RecordCreateRequestFromJSON(json: any): RecordCreateRequest {
  return RecordCreateRequestFromJSONTyped(json);
}

export function RecordCreateRequestFromJSONTyped(
  json: any,
): RecordCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    timestamp: BigInt(json["timestamp"]),
    note: json["note"],
    context: json["context"],
    queryData: json["query_data"],
    auxiliaryData: json["auxiliary_data"],
    dataOffset: json["data_offset"],
    dataLength: json["data_length"],
    chunkCompression: json["chunk_compression"],
    chunkOffset: json["chunk_offset"],
    chunkLength: json["chunk_length"],
    source: json["source"],
  };
}

export function RecordCreateRequestToJSON(
  value: RecordCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    timestamp: value.timestamp.toString(),
    note: value.note,
    context: value.context,
    query_data: value.queryData,
    auxiliary_data: value.auxiliaryData,
    data_offset: value.dataOffset,
    data_length: value.dataLength,
    chunk_compression: value.chunkCompression,
    chunk_offset: value.chunkOffset,
    chunk_length: value.chunkLength,
    source: value.source,
  };
}
