import { useMemo } from "react";
import { usePlayerTopics } from "../../hooks";
import type { InitializedPanelNode } from "../../panels";
import { VisualizationType } from "../../panels";
import { usePlaybackSettings, usePlaybackSource } from "../../playback";
import type { StoreSnapshot, StoreSnapshotParams } from "../../record-store";
import { useRecordStoreContext, useStoreSnapshot } from "../../record-store";
import { useVisualizationStoreParams } from "../../visualizations";
import type { ImageFramesSnapshot } from "../../visualizations/ImageVisualization/types";
import { createImageFramesSnapshotParams } from "../../visualizations/ImageVisualization/use-image-frames";
import { searchForInferenceTopic } from "../utils";
import type { OnDemandInferenceOperation } from "./use-operation-controller";

type NullSnapshot = StoreSnapshot<null, null>;

function createNullSnapshotParams(): StoreSnapshotParams<NullSnapshot> {
  const nullSnapshot: NullSnapshot = {
    request: null,
    status: "fulfilled",
    value: null,
  };

  return {
    request: null,
    subscribe() {
      return () => {};
    },
    getSnapshot() {
      return nullSnapshot;
    },
  };
}

/**
 * Get a snapshot of relevant records from the record store for the current ODI
 * operation, if any. In the case where there's no ODI operation, an "null"
 * snapshot will be returned which essentially means "nothing is being read
 * from the record store."
 */
export function useInferenceStoreSnapshot(
  operation: OnDemandInferenceOperation | null,
): {
  snapshot: ImageFramesSnapshot | NullSnapshot;
  isPlaceholder: boolean;
} {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const playerTopicsQuery = usePlayerTopics();

  const recordStore = useRecordStoreContext();

  let panel: InitializedPanelNode | null = null;
  if (operation !== null) {
    panel = operation.panel;
  }

  const { count, limit, prefetchBehind, prefetchAhead } =
    useVisualizationStoreParams(VisualizationType.Image);

  const { request, subscribe, getSnapshot } = useMemo((): StoreSnapshotParams<
    ImageFramesSnapshot | NullSnapshot
  > => {
    if (
      playbackSource.isLoading ||
      panel == null ||
      playerTopicsQuery.data == null
    ) {
      return createNullSnapshotParams();
    }

    const imageTopic = playerTopicsQuery.data.find(
      (topic) => topic.name === panel.topicName,
    );

    if (imageTopic == null) {
      return createNullSnapshotParams();
    }

    const inferenceTopicSearchResult = searchForInferenceTopic(
      panel,
      playerTopicsQuery.data,
    );

    return createImageFramesSnapshotParams(
      recordStore,
      imageTopic,
      inferenceTopicSearchResult.topic,
      playbackSource.timestamp,
      playbackSettings.timestep,
      count,
      limit,
      prefetchBehind,
      prefetchAhead,
    );
  }, [
    playbackSource.isLoading,
    playbackSource.timestamp,
    playbackSettings.timestep,
    panel,
    playerTopicsQuery.data,
    recordStore,
    count,
    limit,
    prefetchBehind,
    prefetchAhead,
  ]);

  return useStoreSnapshot({
    request,
    subscribe,
    getSnapshot,
  });
}

export function isNullSnapshot(
  storeSnapshot: NullSnapshot | ImageFramesSnapshot,
): storeSnapshot is NullSnapshot {
  return storeSnapshot.request === null;
}
