import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import { merge } from "~/lib/std";
import type {
  DigestionCreateRequest,
  ListTopicsRequest,
  Log,
  Record,
  Topic,
  TopicKeys,
  TopicListResponse,
} from "~/lqs";
import {
  circumventPagination,
  ProcessState,
  topicKeys,
  useDataStoreClients,
} from "~/lqs";
import type { DraftDigestionTopic } from "./types";

const API_MAX_LIMIT = 100;

interface UseCreateDigestionParams
  extends Pick<
    DigestionCreateRequest,
    "name" | "workflowId" | "workflowContext"
  > {
  logId: Log["id"];
  draftDigestionTopics: ReadonlyArray<DraftDigestionTopic>;
}

export function useTopics<TData = TopicListResponse>(
  request: ListTopicsRequest,
  options?: UseQueryOptions<
    TopicListResponse,
    unknown,
    TData,
    TopicKeys["list"]
  >,
) {
  const { topicApi } = useDataStoreClients();

  return useQuery({
    queryKey: topicKeys.list(request),
    queryFn(context) {
      return request.limit === -1
        ? circumventPagination(
            topicApi.listTopics.bind(topicApi),
            API_MAX_LIMIT,
            request,
            context,
          )
        : topicApi.listTopics(request, context);
    },
    ...options,
  });
}

export function useCreateDigestion() {
  const { digestionApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({
      logId,
      draftDigestionTopics,
      name,
      workflowId,
      workflowContext,
    }: UseCreateDigestionParams) {
      const newDigestion = await digestionApi.createDigestion({
        digestionCreateRequest: {
          logId,
          name,
          workflowId,
          workflowContext,
        },
      });

      await Promise.all(
        draftDigestionTopics.map((draftDigestionTopic) =>
          digestionApi.createDigestionTopic({
            digestionId: newDigestion.data.id,
            digestionTopicCreateRequest: draftDigestionTopic,
          }),
        ),
      );

      return digestionApi.updateDigestion({
        digestionId: newDigestion.data.id,
        digestionUpdateRequest: {
          state: ProcessState.Queued,
        },
      });
    },
  });
}

export function useClassifyDetectionsQuality({ topic }: { topic: Topic }) {
  const { topicApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({
      detectionTopicId,
      timestamp,
      classification,
    }: {
      detectionTopicId: Topic["id"];
      timestamp: Record["timestamp"];
      classification: "accept" | "reject";
    }) {
      const {
        data: { context },
      } = await topicApi.fetchRecord({
        topicId: topic.id,
        timestamp,
      });

      return topicApi.updateRecord({
        topicId: topic.id,
        timestamp,
        recordUpdateRequest: {
          context: mergeDetectionsClassificationIntoContext(
            context,
            detectionTopicId,
            classification,
          ),
        },
      });
    },
  });
}

function mergeDetectionsClassificationIntoContext(
  context: object | null,
  detectionTopicId: Topic["id"],
  classification: "accept" | "reject",
): object {
  return merge({}, context ?? {}, {
    studio: {
      ml: {
        inference: {
          [detectionTopicId]: classification,
        },
      },
    },
  });
}
