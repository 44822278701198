import type { FieldNamesMarkedBoolean } from "react-hook-form";
import type { z } from "zod";
import type { ResourceTableModel } from "../Table";

export function getChangedFields<
  TFieldValues extends Record<
    string,
    | number
    | bigint
    | string
    | Date
    | boolean
    | null
    | undefined
    // This just includes fields whose values are objects. Nested form fields
    // aren't supported
    | object
  >,
>(
  values: TFieldValues,
  dirtyFields: Partial<FieldNamesMarkedBoolean<TFieldValues>>,
): Partial<TFieldValues> {
  const changedFields: Partial<TFieldValues> = {};

  for (const field in values) {
    if (dirtyFields[field as keyof typeof dirtyFields]) {
      changedFields[field] = values[field];
    }
  }

  return changedFields;
}

export type SchemaShape<TType extends object> = {
  [Key in keyof TType]-?: z.ZodType<TType[Key], z.ZodTypeDef, any>;
};

export type TableSchemaShape<TType extends object> = Omit<
  SchemaShape<TType>,
  keyof ResourceTableModel
>;
