import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enableMapSet, enablePatches } from "immer";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { GlobalLoadingFallback } from "~/components/GlobalLoadingFallback";
import "~/css/styles.css";
import { AuthenticationGuard } from "~/domain/auth";
import {
  DsmCommonResourcesProvider,
  DsmNavigation,
  DSMProvider,
  GuestLandingPage,
} from "~/dsm";
import {
  DataStoreCreatePage,
  DataStoreDetailsPage,
  DataStoreEditPage,
  DataStoresPage,
  DsmUserCreatePage,
  DsmUserDetailsPage,
  DsmUserEditPage,
  DsmUserTablePage,
} from "~/dsm/pages";
import * as dsmPaths from "~/dsm/paths";
import { DefaultErrorFallback } from "~/errors";
import { NavigationElementProvider } from "~/layout";
import {
  DataStoreProvider,
  dataStoreRoutePaths,
  InLqsSectionProvider,
  LqsNavigation,
} from "~/lqs";
import {
  ApiKeyPage,
  ApiKeysPage,
  DataStoreDashboardPage,
  DigestionPage,
  DigestionPartPage,
  DigestionPartsPage,
  DigestionsPage,
  DigestionTopicPage,
  DigestionTopicsPage,
  EditApiKeyPage,
  EditDigestionPage,
  EditDigestionPartPage,
  EditDigestionTopicPage,
  EditGroupPage,
  EditIngestionPage,
  EditIngestionPartPage,
  EditLabelPage,
  EditLogPage,
  EditLogQueryPage,
  EditObjectStorePage,
  EditRecordPage,
  EditRolePage,
  EditTagPage,
  EditTopicPage,
  EditWorkflowHookPage,
  EditWorkflowPage,
  GroupPage,
  GroupsPage,
  IngestionPage,
  IngestionPartPage,
  IngestionPartsPage,
  IngestionsPage,
  LabelPage,
  LabelsPage,
  LogObjectPage,
  LogObjectsPage,
  LogPage,
  LogQueriesPage,
  LogQueryPage,
  LogsPage,
  LqsUserCreatePage,
  LqsUserDetailsPage,
  LqsUserEditPage,
  LqsUserTablePage,
  NewApiKeyPage,
  NewDigestionPage,
  NewDigestionPartPage,
  NewDigestionTopicPage,
  NewGroupPage,
  NewIngestionPage,
  NewIngestionPartPage,
  NewLabelPage,
  NewLogPage,
  NewLogQueryPage,
  NewObjectStorePage,
  NewRecordPage,
  NewRolePage,
  NewTagPage,
  NewTopicPage,
  NewWorkflowHookPage,
  NewWorkflowPage,
  NotFoundPage,
  ObjectStoreObjectPage,
  ObjectStoreObjectsPage,
  ObjectStorePage,
  ObjectStoresPage,
  PlayerPage,
  ProfilePage,
  RecordPage,
  RecordsPage,
  RolePage,
  RolesPage,
  StudioHomepage,
  TaggingPage,
  TagPage,
  TagsPage,
  TopicPage,
  TopicsPage,
  UploadLogObjectPage,
  UploadPage,
  WorkflowHookPage,
  WorkflowHooksPage,
  WorkflowPage,
  WorkflowsPage,
} from "~/pages";
import * as paths from "~/paths";
import { CommonQueryClientProvider } from "~/providers/CommonQueryClientProvider";
import ThemeProvider from "~/providers/ThemeProvider";
import {
  BytesFormatControl,
  ColorSchemeControl,
  DateDisplayControl,
  GlobalSettingsControlsProvider,
  SettingsProvider,
} from "~/settings";

enablePatches();
enableMapSet();

const ROUTER_BASENAME = process.env.PUBLIC_URL || undefined;

export default function App() {
  return (
    <SettingsProvider>
      <GlobalSettingsControlsProvider
        controls={
          <>
            <ColorSchemeControl />
            <DateDisplayControl />
            <BytesFormatControl />
          </>
        }
      >
        <HelmetProvider>
          <ThemeProvider>
            <AuthenticationGuard
              pendingFallback={<GlobalLoadingFallback />}
              unauthenticatedFallback={<GuestLandingPage />}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
                  <DSMProvider>
                    <CommonQueryClientProvider>
                      <Router basename={ROUTER_BASENAME}>
                        <SnackbarProvider maxSnack={1}>
                          <Routes>
                            <Route
                              element={
                                <DsmCommonResourcesProvider>
                                  <NavigationElementProvider
                                    element={<DsmNavigation />}
                                  >
                                    <Outlet />
                                  </NavigationElementProvider>
                                </DsmCommonResourcesProvider>
                              }
                            >
                              <Route
                                path={paths.STUDIO_HOMEPAGE}
                                element={<StudioHomepage />}
                              />
                              <Route
                                path={dsmPaths.DATASTORE_TABLE}
                                element={<DataStoresPage />}
                              />
                              <Route
                                path={dsmPaths.DATASTORE_CREATE}
                                element={<DataStoreCreatePage />}
                              />
                              <Route
                                path={dsmPaths.DATASTORE_DETAILS}
                                element={<DataStoreDetailsPage />}
                              />
                              <Route
                                path={dsmPaths.DATASTORE_EDIT}
                                element={<DataStoreEditPage />}
                              />
                              <Route
                                path={dsmPaths.USER_TABLE}
                                element={<DsmUserTablePage />}
                              />
                              <Route
                                path={dsmPaths.USER_CREATE}
                                element={<DsmUserCreatePage />}
                              />
                              <Route
                                path={dsmPaths.USER_DETAILS}
                                element={<DsmUserDetailsPage />}
                              />
                              <Route
                                path={dsmPaths.USER_EDIT}
                                element={<DsmUserEditPage />}
                              />
                            </Route>
                            <Route
                              element={
                                <DataStoreProvider>
                                  <InLqsSectionProvider>
                                    <NavigationElementProvider
                                      element={<LqsNavigation />}
                                    >
                                      <Outlet />
                                    </NavigationElementProvider>
                                  </InLqsSectionProvider>
                                </DataStoreProvider>
                              }
                            >
                              <Route
                                path={dataStoreRoutePaths.DATASTORE_DASHBOARD}
                                element={<DataStoreDashboardPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.UPLOAD}
                                element={<UploadPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.PLAYER}
                                element={<PlayerPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.TAGGING}
                                element={<TaggingPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_TABLE}
                                element={<LogsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_CREATE}
                                element={<NewLogPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_DETAILS}
                                element={<LogPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_EDIT}
                                element={<EditLogPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_TAG_TABLE}
                                element={<TagsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_TAG_CREATE}
                                element={<NewTagPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_TAG_DETAILS}
                                element={<TagPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_TAG_EDIT}
                                element={<EditTagPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_OBJECT_TABLE}
                                element={<LogObjectsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_OBJECT_UPLOAD}
                                element={<UploadLogObjectPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_OBJECT_DETAILS}
                                element={<LogObjectPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_QUERY_TABLE}
                                element={<LogQueriesPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_QUERY_CREATE}
                                element={<NewLogQueryPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_QUERY_DETAILS}
                                element={<LogQueryPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LOG_QUERY_EDIT}
                                element={<EditLogQueryPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.INGESTION_TABLE}
                                element={<IngestionsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.INGESTION_CREATE}
                                element={<NewIngestionPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.INGESTION_DETAILS}
                                element={<IngestionPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.INGESTION_EDIT}
                                element={<EditIngestionPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.INGESTION_PART_TABLE}
                                element={<IngestionPartsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.INGESTION_PART_CREATE}
                                element={<NewIngestionPartPage />}
                              />
                              <Route
                                path={
                                  dataStoreRoutePaths.INGESTION_PART_DETAILS
                                }
                                element={<IngestionPartPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.INGESTION_PART_EDIT}
                                element={<EditIngestionPartPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_TABLE}
                                element={<DigestionsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_CREATE}
                                element={<NewDigestionPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_DETAILS}
                                element={<DigestionPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_EDIT}
                                element={<EditDigestionPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_TOPIC_TABLE}
                                element={<DigestionTopicsPage />}
                              />
                              <Route
                                path={
                                  dataStoreRoutePaths.DIGESTION_TOPIC_CREATE
                                }
                                element={<NewDigestionTopicPage />}
                              />
                              <Route
                                path={
                                  dataStoreRoutePaths.DIGESTION_TOPIC_DETAILS
                                }
                                element={<DigestionTopicPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_TOPIC_EDIT}
                                element={<EditDigestionTopicPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_PART_TABLE}
                                element={<DigestionPartsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_PART_CREATE}
                                element={<NewDigestionPartPage />}
                              />
                              <Route
                                path={
                                  dataStoreRoutePaths.DIGESTION_PART_DETAILS
                                }
                                element={<DigestionPartPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.DIGESTION_PART_EDIT}
                                element={<EditDigestionPartPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.TOPIC_TABLE}
                                element={<TopicsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.TOPIC_CREATE}
                                element={<NewTopicPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.TOPIC_DETAILS}
                                element={<TopicPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.TOPIC_EDIT}
                                element={<EditTopicPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.RECORD_TABLE}
                                element={<RecordsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.RECORD_CREATE}
                                element={<NewRecordPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.RECORD_DETAILS}
                                element={<RecordPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.RECORD_EDIT}
                                element={<EditRecordPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.USER_TABLE}
                                element={<LqsUserTablePage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.USER_CREATE}
                                element={<LqsUserCreatePage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.USER_DETAILS}
                                element={<LqsUserDetailsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.USER_EDIT}
                                element={<LqsUserEditPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.GROUP_TABLE}
                                element={<GroupsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.GROUP_CREATE}
                                element={<NewGroupPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.GROUP_DETAILS}
                                element={<GroupPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.GROUP_EDIT}
                                element={<EditGroupPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.API_KEY_TABLE}
                                element={<ApiKeysPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.API_KEY_CREATE}
                                element={<NewApiKeyPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.API_KEY_DETAILS}
                                element={<ApiKeyPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.API_KEY_EDIT}
                                element={<EditApiKeyPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.ROLE_TABLE}
                                element={<RolesPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.ROLE_CREATE}
                                element={<NewRolePage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.ROLE_DETAILS}
                                element={<RolePage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.ROLE_EDIT}
                                element={<EditRolePage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.WORKFLOW_TABLE}
                                element={<WorkflowsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.WORKFLOW_CREATE}
                                element={<NewWorkflowPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.WORKFLOW_DETAILS}
                                element={<WorkflowPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.WORKFLOW_EDIT}
                                element={<EditWorkflowPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.WORKFLOW_HOOK_TABLE}
                                element={<WorkflowHooksPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.WORKFLOW_HOOK_CREATE}
                                element={<NewWorkflowHookPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.WORKFLOW_HOOK_DETAILS}
                                element={<WorkflowHookPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.WORKFLOW_HOOK_EDIT}
                                element={<EditWorkflowHookPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LABEL_TABLE}
                                element={<LabelsPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LABEL_CREATE}
                                element={<NewLabelPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LABEL_DETAILS}
                                element={<LabelPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.LABEL_EDIT}
                                element={<EditLabelPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.OBJECT_STORE_TABLE}
                                element={<ObjectStoresPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.OBJECT_STORE_CREATE}
                                element={<NewObjectStorePage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.OBJECT_STORE_DETAILS}
                                element={<ObjectStorePage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.OBJECT_STORE_EDIT}
                                element={<EditObjectStorePage />}
                              />
                              <Route
                                path={
                                  dataStoreRoutePaths.OBJECT_STORE_OBJECT_TABLE
                                }
                                element={<ObjectStoreObjectsPage />}
                              />
                              <Route
                                path={
                                  dataStoreRoutePaths.OBJECT_STORE_OBJECT_DETAILS
                                }
                                element={<ObjectStoreObjectPage />}
                              />
                              <Route
                                path={dataStoreRoutePaths.PROFILE}
                                element={<ProfilePage />}
                              />
                            </Route>
                            <Route path="*" element={<NotFoundPage />} />
                          </Routes>
                        </SnackbarProvider>
                      </Router>
                    </CommonQueryClientProvider>
                  </DSMProvider>
                </ErrorBoundary>
              </LocalizationProvider>
            </AuthenticationGuard>
          </ThemeProvider>
        </HelmetProvider>
      </GlobalSettingsControlsProvider>
    </SettingsProvider>
  );
}
