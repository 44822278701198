import LOGQS_ICON from "~/images/logqs_icon.png";
import LOGQS_LOGO from "~/images/logqs_logo.png";

export { LOGQS_ICON, LOGQS_LOGO };

export const LOGQS_ORIGIN = "https://logqs.com/";

export const DOCS_ORIGIN = "https://docs.logqs.com";

export const NOTEBOOK_HELP_LINK =
  "https://colab.research.google.com/drive/1RevEpY40lj-jGjl8WZRvzxxKXRC4NtSp#scrollTo=e9YbbSlcJ7ua";
