/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProcessState } from "./ProcessState";
import {
  ProcessStateFromJSON,
  ProcessStateFromJSONTyped,
  ProcessStateToJSON,
} from "./ProcessState";

export interface DigestionPartUpdateRequest {
  locked?: boolean;
  workflowId?: string | null;
  workflowContext?: object | null;
  state?: ProcessState;
  progress?: number | null;
  error?: object | null;
  sequence?: number;
  index?: Array<any> | null;
}

export function DigestionPartUpdateRequestFromJSON(
  json: any,
): DigestionPartUpdateRequest {
  return DigestionPartUpdateRequestFromJSONTyped(json);
}

export function DigestionPartUpdateRequestFromJSONTyped(
  json: any,
): DigestionPartUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    workflowId: json["workflow_id"],
    workflowContext: json["workflow_context"],
    state:
      json["state"] === undefined
        ? undefined
        : ProcessStateFromJSON(json["state"]),
    progress: json["progress"],
    error: json["error"],
    sequence: json["sequence"],
    index: json["index"],
  };
}

export function DigestionPartUpdateRequestToJSON(
  value: DigestionPartUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    workflow_id: value.workflowId,
    workflow_context: value.workflowContext,
    state: ProcessStateToJSON(value.state),
    progress: value.progress,
    error: value.error,
    sequence: value.sequence,
    index: value.index,
  };
}
