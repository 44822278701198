import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Workflow } from "~/lqs";
import { LqsResourceTable, useWorkflows } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { listWorkflowsSchema } from "../schemas";

function WorkflowNameCell({ workflow }: { workflow: Workflow }) {
  const lqsNavigator = useLqsNavigator({ toWorkflowDetails: true });

  return (
    <TableCell>
      {workflow.name}
      <Link
        component={RouterLink}
        to={lqsNavigator.toWorkflowDetails({ workflowId: workflow.id })}
      >
        <Typography variant="body2">{workflow.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<Workflow>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell: (workflow) => <WorkflowNameCell workflow={workflow} />,
  },
  {
    accessor: "_default",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useWorkflowSearchRequest, WorkflowSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listWorkflowsSchema,
  });

export function WorkflowTable() {
  const [request, setRequest] = useWorkflowSearchRequest();

  const lqsNavigator = useLqsNavigator({ toWorkflowCreate: true });

  const searchQuery = useWorkflows(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="workflow"
      resourceCreateLocation={lqsNavigator.toWorkflowCreate()}
      getRowKey={(workflow) => workflow.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
