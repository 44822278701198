/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Topic } from "./Topic";
import { TopicFromJSON, TopicFromJSONTyped, TopicToJSON } from "./Topic";

export interface TopicDataResponse {
  data: Topic;
}

export function TopicDataResponseFromJSON(json: any): TopicDataResponse {
  return TopicDataResponseFromJSONTyped(json);
}

export function TopicDataResponseFromJSONTyped(json: any): TopicDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: TopicFromJSON(json["data"]),
  };
}

export function TopicDataResponseToJSON(
  value: TopicDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: TopicToJSON(value.data),
  };
}
