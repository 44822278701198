import React from "react";
import type { FieldValues } from "react-hook-form";
import type { Path } from "react-router-dom";
import type { OverrideProperties } from "type-fest";
import type { EditResourceFormProps } from "~/components/Form";
import { EditResourceForm } from "~/components/Form";

// TODO: Is this component necessary now that DataStore locations are the same
//  as DSM locations?
type EditLqsResourceFormProps<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
> = OverrideProperties<
  EditResourceFormProps<TRequest, TFormValues, TResource>,
  { detailsLocation: Partial<Path> }
>;

export function EditLqsResourceForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
>({
  detailsLocation,
  ...rest
}: EditLqsResourceFormProps<TRequest, TFormValues, TResource>) {
  return <EditResourceForm {...rest} detailsLocation={detailsLocation} />;
}
