import { styled, Typography } from "@mui/material";
import { assertNever } from "~/utils";
import { useFormatPlaybackTimestamp, usePlaybackSource } from "../playback";

const Root = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  fontFamily: "monospace",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.grey["300"]
      : theme.palette.grey["700"],
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  margin: 0,
  paddingInline: theme.spacing(1),
  paddingBlock: theme.spacing(0.5),
}));

export function TimestampSelection({
  value,
}: {
  value: "current" | "range-start" | "range-end";
}) {
  const playbackSource = usePlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  let effectiveValue;
  switch (value) {
    case "current": {
      effectiveValue = playbackSource.timestamp;

      break;
    }
    case "range-start": {
      effectiveValue = playbackSource.range?.startTime;

      break;
    }
    case "range-end": {
      effectiveValue = playbackSource.range?.endTime;

      break;
    }
    default: {
      assertNever(value);
    }
  }

  return (
    <Root>
      {effectiveValue == null ? "-" : formatPlaybackTimestamp(effectiveValue)}
    </Root>
  );
}
