import React from "react";
import type { UseMutationResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { FieldValues } from "react-hook-form";
import type { Path } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import type { z } from "zod";
import { Card } from "../Card";
import { Form } from "./Form";
import type { UseStudioFormProps, UseStudioFormReturn } from "./hooks";
import { useStudioForm } from "./hooks";

export interface NewResourceFormProps<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResponse extends object,
> extends Required<
    Pick<
      UseStudioFormProps<z.ZodType<TFormValues, z.ZodTypeDef, unknown>>,
      "schema" | "defaultValues"
    >
  > {
  resourceName: string;
  mutation: UseMutationResult<TResponse, unknown, TRequest>;
  createDetailsLocation: (response: TResponse) => Partial<Path>;
  children: (
    control: UseStudioFormReturn<TFormValues>["control"],
  ) => React.ReactNode;
}

export function NewResourceForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResponse extends object,
>({
  schema,
  defaultValues,
  resourceName,
  mutation,
  createDetailsLocation,
  children,
}: NewResourceFormProps<TRequest, TFormValues, TResponse>) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useStudioForm({
    schema,
    defaultValues,
    onSubmit: function onSubmit(values: TFormValues) {
      mutation.mutate(values, {
        onSuccess(response) {
          navigate(createDetailsLocation(response));
        },
        onError() {
          enqueueSnackbar(`Unable to create ${resourceName}`, {
            variant: "error",
          });
        },
      });
    } as any,
  });

  return (
    <Card>
      <Form
        onSubmit={handleSubmit}
        loading={mutation.isLoading}
        submitText="Create"
      >
        {children(control)}
      </Form>
    </Card>
  );
}
