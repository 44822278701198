import React from "react";
import { Skeleton, Stack } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { assertNever } from "~/utils";
import { QueryRenderer } from "../QueryRenderer";
import type { ForeignResourceRenderer } from "../Table";
import { getFieldKey, renderField } from "./fields";
import type { ResourceField } from "./types";

export interface ResourceFieldsProps<
  TResource extends object,
  TForeignResources extends string,
> {
  query: UseQueryResult<TResource>;
  fields: ReadonlyArray<ResourceField<NoInfer<TResource>, TForeignResources>>;
  renderForeignResource: ForeignResourceRenderer<TForeignResources>;
}

export function ResourceFields<
  TResource extends object,
  TForeignResources extends string,
>({
  query,
  fields,
  renderForeignResource,
}: ResourceFieldsProps<TResource, TForeignResources>) {
  return (
    <QueryRenderer
      query={query}
      loading={<Stack spacing={2}>{fields.map(renderSkeletonField)}</Stack>}
      success={(resource) => (
        <Stack spacing={2} component="dl">
          {fields.map((field) => (
            <div key={getFieldKey(field)}>
              {renderField(resource, field, renderForeignResource)}
            </div>
          ))}
        </Stack>
      )}
    />
  );
}

function renderSkeletonField(field: ResourceField<any, any>) {
  let valueSkeleton: React.ReactNode;

  const { dataType } = field;
  switch (dataType) {
    case "text":
    case "id":
    case "number":
    case "percent":
    case "timestamp":
    case "bigint":
    case "datetime":
    case "bytes":
    case "boolean":
    case "foreign-key": {
      valueSkeleton = <Skeleton variant="text" sx={{ maxWidth: 350 }} />;

      break;
    }
    case "json":
    case "pre": {
      valueSkeleton = <Skeleton variant="rounded" height={250} />;

      break;
    }
    default: {
      assertNever(dataType);
    }
  }

  return (
    <div key={getFieldKey(field)}>
      <Skeleton variant="text" sx={{ maxWidth: 200 }} />
      {valueSkeleton}
    </div>
  );
}
