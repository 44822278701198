import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import {
  EditLqsResourceForm,
  useObjectStore,
  useUpdateObjectStore,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_OBJECT_STORE_FIELDS,
  editObjectStoreSchema,
} from "../schemas";

export function ObjectStoreEditForm() {
  const { objectStoreId } = useTypedParams(lqsRoutePaths.OBJECT_STORE_EDIT);

  const lqsNavigator = useLqsNavigator({ toObjectStoreDetails: true });

  return (
    <EditLqsResourceForm
      schema={editObjectStoreSchema}
      resourceName="object store"
      query={useObjectStore(objectStoreId, { select: selectData })}
      editableFields={EDITABLE_OBJECT_STORE_FIELDS}
      mutation={useUpdateObjectStore(objectStoreId)}
      detailsLocation={lqsNavigator.toObjectStoreDetails({ objectStoreId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="bucketName" required />
          <TextField control={control} name="accessKeyId" />
          <TextField control={control} name="regionName" />
          <TextField control={control} name="endpointUrl" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
