import React from "react";
import { Typography } from "@mui/material";
import type { JsonArray, JsonObject } from "type-fest";
import { z } from "zod";
import { getFieldLabel } from "~/domain/common";
import {
  Boolean,
  Bytes,
  Datetime,
  formatNumber,
  formatPercent,
  Timestamp,
} from "~/format";
import { invariant } from "~/lib/invariant";
import { assertNever } from "~/utils";
import { CopyButton } from "../CopyableText";
import type { ForeignResourceRenderer } from "../Table";
import { JsonField } from "./JsonField";
import { Pre } from "./Pre";
import type { AccessorResourceField, ResourceField } from "./types";

const uuidSchema = z.string().uuid();

const numberSchema = z.number();

const bigintSchema = z.bigint();

const datetimeSchema = z.date();

const booleanSchema = z.boolean();

const jsonSchema = z
  .unknown()
  .refine((value): value is JsonObject | JsonArray => {
    return typeof value === "object";
  });

export function renderField<
  TResource extends object,
  TForeignResources extends string,
>(
  resource: TResource,
  field: ResourceField<TResource, TForeignResources>,
  renderForeignResource: ForeignResourceRenderer<TForeignResources> | undefined,
) {
  const label = field.label ?? getFieldLabel(field.accessor);
  const content = getAccessorFieldContent(
    resource[field.accessor],
    field,
    renderForeignResource,
  );

  return (
    <React.Fragment key={getFieldKey(field)}>
      <Typography
        component="dt"
        sx={{
          color: "text.secondary",
          fontWeight: "bold",
        }}
      >
        {label}
      </Typography>
      <Typography component="dd">{content}</Typography>
    </React.Fragment>
  );
}

function getAccessorFieldContent(
  fieldData: unknown,
  field: AccessorResourceField<any, any>,
  renderForeignResource: ForeignResourceRenderer<any> | undefined,
): React.ReactNode {
  if (fieldData == null) {
    return "-";
  }

  const { dataType } = field;
  switch (dataType) {
    case "text": {
      return String(fieldData);
    }
    case "id": {
      const value = String(fieldData);

      return (
        <span>
          {value} <CopyButton text={value} size="small" />
        </span>
      );
    }
    case "pre": {
      return <Pre>{String(fieldData)}</Pre>;
    }
    case "number": {
      return formatNumber(numberSchema.parse(fieldData));
    }
    case "percent": {
      return formatPercent(numberSchema.parse(fieldData));
    }
    case "timestamp": {
      return <Timestamp value={bigintSchema.parse(fieldData)} />;
    }
    case "bigint": {
      // TODO: Format this better
      return String(bigintSchema.parse(fieldData));
    }
    case "datetime": {
      return <Datetime date={datetimeSchema.parse(fieldData)} />;
    }
    case "bytes": {
      return <Bytes value={numberSchema.parse(fieldData)} />;
    }
    case "boolean": {
      return <Boolean value={booleanSchema.parse(fieldData)} />;
    }
    case "json": {
      return <JsonField value={jsonSchema.parse(fieldData)} />;
    }
    case "foreign-key": {
      invariant(
        renderForeignResource !== undefined,
        "No foreign resource renderer provided",
      );

      return renderForeignResource(
        uuidSchema.parse(fieldData),
        field.resourceType,
      );
    }
    default: {
      assertNever(dataType);
    }
  }
}

export function getFieldKey(field: ResourceField<any, any>): React.Key {
  return field.label ?? getFieldLabel(field.accessor);
}
