import React, { useCallback } from "react";
import { Box } from "@mui/material";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../actions";
import PanelLayout from "../components/PanelLayout";
import { TopicTree } from "../components/topic-tree";
import type { UninitializedPanelNode, VisualizationFilter } from "../panels";
import { VisualizationType } from "../panels";

export function TopicSelector({
  panel,
  topics,
}: {
  panel: UninitializedPanelNode;
  topics: ReadonlyArray<Topic>;
}) {
  const {
    selectPanelTopic: dispatchSelectPanelTopic,
    setTopicSearchFilter,
    setTopicVisualizationFilter,
  } = usePlayerActions();

  const handleSelect = useCallback(
    (topic: Topic) => {
      dispatchSelectPanelTopic(panel.id, topic);
    },
    [dispatchSelectPanelTopic, panel.id],
  );

  function handleSearchChange(newSearch: string | null): void {
    setTopicSearchFilter(panel, newSearch);
  }

  function handleVisualizationFilterChange(
    newVisualizationFilter: VisualizationFilter | null,
  ): void {
    setTopicVisualizationFilter(panel, newVisualizationFilter);
  }

  return (
    <PanelLayout>
      <Box
        sx={{
          width: 1,
          height: 1,
          overflowY: "auto",
          container: "panel-container / size",
        }}
      >
        <Box sx={{ width: 1, maxWidth: "60ch", p: 2 }}>
          <TopicTree
            filterSectionStyles={{
              "@container panel-container (height <= 400px)": {
                position: "static",
              },
            }}
            defaultHighlighted={panel.topicSelection.lastSelected}
            topics={topics}
            onSelect={handleSelect}
            defaultSearch={panel.topicSelection.search}
            onSearchChange={handleSearchChange}
            showVisualizationFilters
            defaultVisualizationFilter={
              panel.topicSelection[VisualizationType.Image]
                ? VisualizationType.Image
                : panel.topicSelection[VisualizationType.ThreeD]
                  ? VisualizationType.ThreeD
                  : panel.topicSelection[VisualizationType.Map]
                    ? VisualizationType.Map
                    : null
            }
            onVisualizationFilterChange={handleVisualizationFilterChange}
          />
        </Box>
      </Box>
    </PanelLayout>
  );
}
