/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  GroupCreateRequest,
  GroupDataResponse,
  GroupListResponse,
  GroupUpdateRequest,
  HTTPValidationError,
} from "../models";
import {
  GroupCreateRequestFromJSON,
  GroupCreateRequestToJSON,
  GroupDataResponseFromJSON,
  GroupDataResponseToJSON,
  GroupListResponseFromJSON,
  GroupListResponseToJSON,
  GroupUpdateRequestFromJSON,
  GroupUpdateRequestToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models";

export interface CreateGroupRequest {
  groupCreateRequest: GroupCreateRequest;
}

export interface DeleteGroupRequest {
  groupId: string;
  lockToken?: string | null;
}

export interface FetchGroupRequest {
  groupId: string;
}

export interface ListGroupsRequest {
  id?: string | null;
  name?: string | null;
  nameLike?: string | null;
  defaultWorkflowId?: string | null;
  defaultWorkflowIdNull?: boolean | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateGroupRequest {
  groupId: string;
  groupUpdateRequest: GroupUpdateRequest;
  lockToken?: string | null;
}

export class GroupApi extends runtime.BaseAPI {
  async createGroup(
    this: GroupApi,
    requestParameters: CreateGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GroupDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.groupCreateRequest,
      "groupCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/groups`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GroupCreateRequestToJSON(requestParameters.groupCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return GroupDataResponseFromJSON(jsonValue);
  }

  async deleteGroup(
    this: GroupApi,
    requestParameters: DeleteGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.groupId, "groupId");

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/groups/{group_id}`.replace(
          `{${"group_id"}}`,
          encodeURIComponent(String(requestParameters.groupId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchGroup(
    this: GroupApi,
    requestParameters: FetchGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GroupDataResponse> {
    runtime.assertParamIsPresent(requestParameters.groupId, "groupId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/groups/{group_id}`.replace(
          `{${"group_id"}}`,
          encodeURIComponent(String(requestParameters.groupId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return GroupDataResponseFromJSON(jsonValue);
  }

  async listGroups(
    this: GroupApi,
    requestParameters: ListGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GroupListResponse> {
    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.defaultWorkflowId != null) {
      queryParameters["default_workflow_id"] =
        requestParameters.defaultWorkflowId;
    }

    if (requestParameters.defaultWorkflowIdNull != null) {
      queryParameters["default_workflow_id_null"] =
        requestParameters.defaultWorkflowIdNull;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/groups`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return GroupListResponseFromJSON(jsonValue);
  }

  async updateGroup(
    this: GroupApi,
    requestParameters: UpdateGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GroupDataResponse> {
    runtime.assertParamIsPresent(requestParameters.groupId, "groupId");

    runtime.assertParamIsPresent(
      requestParameters.groupUpdateRequest,
      "groupUpdateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/groups/{group_id}`.replace(
          `{${"group_id"}}`,
          encodeURIComponent(String(requestParameters.groupId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: GroupUpdateRequestToJSON(requestParameters.groupUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return GroupDataResponseFromJSON(jsonValue);
  }
}
