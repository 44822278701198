import { BooleanField, FuzzyField, useStudioForm } from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { listRolesSchema } from "../schemas";
import { useRoleSearchRequest } from "./RoleTable";

export function RoleFilters() {
  const [request, setRequest] = useRoleSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listRolesSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <BooleanField control={control} name="disabled" />
      <BooleanField control={control} name="_default" />
      <BooleanField control={control} name="managed" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
