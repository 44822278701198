import { nanosecondsToDate } from "~/lib/dates";
import type { DateDisplay } from "~/settings";
import { assertNever } from "~/utils";

const commonOptions: Readonly<Intl.DateTimeFormatOptions> = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

const utcFormatter = new Intl.DateTimeFormat(undefined, {
  ...commonOptions,
  timeZone: "UTC",
});

const localFormatter = new Intl.DateTimeFormat(undefined, {
  ...commonOptions,
});

export function formatDateOrTimestamp(
  value: Date | bigint,
  dateDisplay: DateDisplay,
): string {
  const date = typeof value === "bigint" ? nanosecondsToDate(value) : value;

  switch (dateDisplay) {
    case "utc": {
      return utcFormatter.format(date);
    }
    case "local": {
      return localFormatter.format(date);
    }
    default: {
      assertNever(dateDisplay);
    }
  }
}
