/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface LabelUpdateRequest {
  value?: string;
  note?: string | null;
}

export function LabelUpdateRequestFromJSON(json: any): LabelUpdateRequest {
  return LabelUpdateRequestFromJSONTyped(json);
}

export function LabelUpdateRequestFromJSONTyped(json: any): LabelUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    value: json["value"],
    note: json["note"],
  };
}

export function LabelUpdateRequestToJSON(
  value: LabelUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    value: value.value,
    note: value.note,
  };
}
