import type React from "react";
import { createSafeContext } from "~/contexts";

const [useInLqsSection, InLqsSectionContext] =
  createSafeContext<boolean>("InLqsContext");

export function InLqsSectionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <InLqsSectionContext.Provider value={true}>
      {children}
    </InLqsSectionContext.Provider>
  );
}

export function LqsSectionGuard({ children }: { children: React.ReactNode }) {
  const inLqsSection = useInLqsSection({ strict: false });

  if (inLqsSection) {
    return children;
  } else {
    return null;
  }
}
