import { Box } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import {
  CheckboxField,
  Form,
  FormSkeleton,
  getChangedFields,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { pick } from "~/lib/std";
import type { APIKey } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useApiKey,
  useDeleteApiKey,
  useUpdateApiKey,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editApiKeySchema } from "../schemas";

export function ApiKeyDetails() {
  const { apiKeyId } = useTypedParams(lqsRoutePaths.API_KEY_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toApiKeyEdit: true,
    toApiKeyTable: true,
  });

  const query = useApiKey(apiKeyId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="API key"
      query={query}
      editLocation={lqsNavigator.toApiKeyEdit({ apiKeyId })}
      deleteMutation={useDeleteApiKey(apiKeyId)}
      getReadableName={(apiKey) => apiKey.name}
      listLocation={lqsNavigator.toApiKeyTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: 3,
            }}
          >
            {infoSection}
            {manageSection}
          </Box>
        </>
      }
      secondaryGridColumn={historySection}
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<APIKey> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "foreign-key", resourceType: "user", accessor: "userId" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<APIKey> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["text", "text"]} />}
        success={(apiKey) => <InfoSectionImpl apiKey={apiKey} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ apiKey }: { apiKey: APIKey }) {
  const updateApiKey = useUpdateApiKey(apiKey.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editApiKeySchema.pick({ name: true, disabled: true });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(apiKey, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateApiKey.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("API key updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update API key", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateApiKey.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="name" required />
      <CheckboxField control={control} name="disabled" />
    </Form>
  );
}
