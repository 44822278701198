import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Query } from "~/lqs";
import { LqsResourceTable, useLogQueries } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { listLogQueriesSchema } from "../schemas";

function LogQueryNameCell({ logQuery }: { logQuery: Query }) {
  const lqsNavigator = useLqsNavigator({ toLogQueryDetails: true });

  return (
    <TableCell>
      {logQuery.name ?? "-"}
      <Link
        component={RouterLink}
        to={lqsNavigator.toLogQueryDetails({
          logId: logQuery.logId,
          queryId: logQuery.id,
        })}
      >
        <Typography variant="body2">{logQuery.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<Query>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell: (logQuery) => <LogQueryNameCell logQuery={logQuery} />,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useLogQuerySearchRequest, LogQuerySearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listLogQueriesSchema,
  });

export function LogQueryTable() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_QUERY_TABLE);

  const lqsNavigator = useLqsNavigator({ toLogQueryCreate: true });

  const [request, setRequest] = useLogQuerySearchRequest();

  const searchQuery = useLogQueries(logId, preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="query"
      resourceCreateLocation={lqsNavigator.toLogQueryCreate({ logId })}
      getRowKey={(logQuery) => logQuery.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
