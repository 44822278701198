import {
  BooleanField,
  NumberField,
  TextField,
  TimestampField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { IngestionSelect, WorkflowSelect } from "~/lqs";
import { listRecordsSchema } from "../schemas";
import { useRecordSearchRequest } from "./RecordTable";

export function RecordFilters() {
  const [request, setRequest] = useRecordSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listRecordsSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TimestampField control={control} name="timestamp" />
      <TimestampField control={control} name="timestampGte" />
      <TimestampField control={control} name="timestampLte" />
      <IngestionSelect control={control} name="ingestionId" />
      <WorkflowSelect control={control} name="workflowId" />
      <BooleanField control={control} name="workflowIdNull" />
      <TextField control={control} name="source" />
      <BooleanField control={control} name="altered" />
      <NumberField control={control} name="dataLengthGte" />
      <NumberField control={control} name="dataLengthLte" />
      <NumberField control={control} name="dataOffsetGte" />
      <NumberField control={control} name="dataOffsetLte" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
