import type { ValueOf } from "ts-essentials";
import type { OverrideProperties } from "type-fest";
import type { DigestionTopicCreateRequest, Log } from "~/lqs";

// Record down-sampling frequency. Units are records per second
export const SampleFrequency = {
  Second: 1,
  Decisecond: 10,
} as const;

export type SampleFrequencyValue = ValueOf<typeof SampleFrequency>;

export const PlaybackSpeed = {
  TimesOne: 1,
  TimesTwo: 2,
  TimesFive: 5,
  TimesTen: 10,
} as const;

export type PlaybackSpeedValue = ValueOf<typeof PlaybackSpeed>;

export const Timestep = {
  Second: "second",
  Decisecond: "decisecond",
} as const;

export type TimestepValue = ValueOf<typeof Timestep>;

export interface TimeRange {
  startTime: bigint;
  endTime: bigint;
}

export type BoundedLog = OverrideProperties<Log, TimeRange>;

export type DraftDigestionTopic = Pick<DigestionTopicCreateRequest, "topicId"> &
  TimeRange;
