import { Checkbox, FormControlLabel } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";
import { useField } from "./hooks";
import type { BaseInputProps, FieldPropsFromInputProps } from "./types";

type CheckboxInputProps = BaseInputProps<boolean | null>;

export function CheckboxInput({
  name,
  label = getFieldLabel(name),
  value,
  onChange,
}: CheckboxInputProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value ?? false}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
      label={label}
    />
  );
}

export function CheckboxField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean | null>,
>({
  control,
  ...rest
}: FieldPropsFromInputProps<TFieldValues, TName, CheckboxInputProps>) {
  const { value, onChange } = useField({
    control,
    name: rest.name,
  });

  return <CheckboxInput {...rest} value={value} onChange={onChange} />;
}
