import React from "react";
import { Settings } from "@mui/icons-material";
import { DocumentTitle } from "~/components/document-title";
import * as Layout from "~/layout";
import { SettingsSidebar } from "~/settings";
import { DashboardSections } from "./dashboard-sections";

export function DataStoreDashboardPage() {
  return (
    <>
      <DocumentTitle>Dashboard</DocumentTitle>
      <Layout.LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title="Dashboard"
            actions={
              <Layout.SidebarTrigger
                title="Settings"
                sidebarId="settings"
                icon={<Settings />}
              />
            }
          />
          <Layout.Main>
            <DashboardSections />
          </Layout.Main>
          <Layout.Sidebar>
            <Layout.SidebarSwitch
              options={[
                {
                  sidebarId: "settings",
                  element: <SettingsSidebar />,
                },
              ]}
            />
          </Layout.Sidebar>
        </Layout.Root>
      </Layout.LayoutStateProvider>
    </>
  );
}
