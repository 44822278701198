import React, { useRef } from "react";
import type { ButtonBaseActions } from "@mui/material";
import {
  svgIconClasses,
  styled,
  Divider,
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import useResizeObserver from "use-resize-observer";
import { Center } from "~/components/Center";
import { useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { assertCountableListResponse } from "~/domain/common";
import {
  LogThumbnailCard,
  filterSchema,
  getSortParams,
  GroupsField,
  LabelsField,
  NameField,
  PaginationControls,
  PlayableLogsField,
  SortField,
  useLogsSearchRequest,
} from "~/domain/logs";
import type { Log, LogListResponse } from "~/lqs";
import { useLogs } from "~/lqs";
import { pluralize } from "~/utils";
import { calculateLogMarkers } from "./utils";

const LazyMapView = React.lazy(() => import("./lazy-map-view"));

const classNames = {
  controlsContainer: "controls-container",
  controlsContent: "controls-content",
  results: "results",
} as const;

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  [`& .${classNames.controlsContainer}`]: {
    position: "absolute",
    inset: theme.spacing(1),
    padding: theme.spacing(1),
    pointerEvents: "none",
  },
  [`& .${classNames.controlsContent}`]: {
    width: 450,
    maxWidth: "100%",
    maxHeight: "100%",
    padding: theme.spacing(2),
    pointerEvents: "auto",
  },
  [`& .${classNames.results}`]: {
    minHeight: 0,
    overflowY: "auto",
    // Counteract gap spacing
    marginBlock: theme.spacing(-3),
    padding: theme.spacing(1),
    // Focusing the list item when the user clicks a log marker
    // should scroll smoothly
    scrollBehavior: "smooth",
    "& > *:not(:last-of-type)": {
      marginBlockEnd: theme.spacing(2),
    },
  },
  [`& .maplibregl-marker .${svgIconClasses.root}`]: {
    cursor: "pointer",
    scale: "1",
    color: theme.palette.error.main,
    transition: theme.transitions.create(["scale", "color"]),
    '&[data-active="true"]': {
      scale: "1.15",
      color: theme.palette.error.dark,
    },
  },
}));

export function LogMap({ onClose }: { onClose: () => void }) {
  const [request, setRequest] = useLogsSearchRequest();

  const { ref, width, height } = useResizeObserver();

  const logsQuery = useLogs(
    {
      nameLike: request.name,
      startTimeNull: request.playable ? false : undefined,
      limit: request.limit,
      offset: request.offset,
      groupId: request.groupIds,
      tagLabelIdsIncludes: request.labelIds,
      ...getSortParams(request),
      includeCount: true,
      contextFilter: JSON.stringify([
        {
          var: "sample_coordinates.longitude",
          op: "gt",
          val: -180,
        },
      ]),
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectLogsResponse,
    },
  );

  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: { name: request.name },
    onSubmit: setRequest,
  });

  function handleClose(): void {
    onClose();
    setRequest({ offset: 0 });
  }

  const { makeRefCallback, focusLog } = useLogFocus();

  return (
    <Root ref={ref}>
      {logsQuery.isSuccess && (
        <ErrorBoundary
          fallback={
            <Center>
              <Typography variant="h5" component="p">
                Error initializing map
              </Typography>
            </Center>
          }
        >
          <React.Suspense fallback={null}>
            <LazyMapView
              width={width}
              height={height}
              logMarkers={logsQuery.data.logMarkers}
              onLogClick={focusLog}
            />
          </React.Suspense>
        </ErrorBoundary>
      )}
      <div className={classNames.controlsContainer}>
        <Paper
          className={classNames.controlsContent}
          component={Stack}
          spacing={3}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="h2">
              Logs
            </Typography>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={handleClose}
            >
              Close Map
            </Button>
          </Stack>
          <Stack spacing={1.5}>
            <Stack direction="row" spacing={1.5} sx={{ flex: "none" }}>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ flex: "auto" }}
              >
                <NameField control={control} />
              </Box>
              <SortField request={request} setRequest={setRequest} />
            </Stack>
            <Stack direction="row" spacing={1.5} sx={{ flex: "none" }}>
              <Box sx={{ minWidth: 0, flex: "1 0" }}>
                <GroupsField request={request} setRequest={setRequest} />
              </Box>
              <Box sx={{ minWidth: 0, flex: "1 0" }}>
                <LabelsField request={request} setRequest={setRequest} />
              </Box>
            </Stack>
            <Box>
              <PlayableLogsField request={request} setRequest={setRequest} />
            </Box>
          </Stack>
          <Box sx={{ flex: "none", position: "relative" }}>
            <Divider />
            {logsQuery.isRefetching && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                }}
              />
            )}
          </Box>
          <QueryRenderer
            query={logsQuery}
            loading={<Loading type="circular" />}
            error={<ErrorMessage>Error fetching logs</ErrorMessage>}
            success={({ count, logs }) => (
              <div
                className={classNames.results}
                // Used for thumbnail cards' IntersectionObservers
                data-scroll-root=""
              >
                {logs.length === 0 ? (
                  <Center sx={{ my: 4 }}>
                    <Typography variant="h5" component="p">
                      No logs
                    </Typography>
                  </Center>
                ) : (
                  <>
                    <Typography sx={{ mb: 2 }}>
                      {pluralize(count, "log")}
                    </Typography>
                    {logs.map((log) => (
                      <LogThumbnailCard
                        key={log.id}
                        log={log}
                        dense
                        actionRef={makeRefCallback(log.id)}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
          />
          {logsQuery.isSuccess && (
            <>
              <Divider />
              <PaginationControls
                disableJumping
                logCount={logsQuery.data.count}
                request={request}
                setRequest={setRequest}
              />
            </>
          )}
        </Paper>
      </div>
    </Root>
  );
}

function selectLogsResponse(response: LogListResponse) {
  assertCountableListResponse(response);

  return {
    count: response.count,
    logs: response.data,
    logMarkers: calculateLogMarkers(response.data),
  };
}

function useLogFocus() {
  const mapRef = useRef(new Map<Log["id"], ButtonBaseActions>());

  return {
    makeRefCallback(logId: Log["id"]): React.RefCallback<ButtonBaseActions> {
      return function refCallback(actions) {
        if (actions === null) {
          mapRef.current.delete(logId);
        } else {
          mapRef.current.set(logId, actions);
        }
      };
    },
    focusLog(logId: Log["id"]) {
      mapRef.current.get(logId)?.focusVisible();
    },
  };
}
