import { z } from "zod";
import type { Option } from "~/components/Form/types";
import { createErrorMap } from "~/domain/common";
import { TypeEncoding } from "../sdk";

export const typeEncodingOptions: Option[] = Object.values(TypeEncoding).map(
  (value) => ({
    label: value,
    value,
  }),
);

export const requiredTypeEncodingSchema = z.nativeEnum(TypeEncoding, {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
    invalid_enum_value: "Unexpected value",
  }),
});

export const optionalTypeEncodingSchema = requiredTypeEncodingSchema.nullable();

export const filterTypeEncoding = optionalTypeEncodingSchema.default(null);
