import type { StrictOmit } from "ts-essentials";
import type { HistoryCardProps } from "~/components/DetailsCards";
import { HistoryCard } from "~/components/DetailsCards";
import { renderForeignResource } from "./renderForeignResource";

type DsmHistoryCardProps = StrictOmit<
  HistoryCardProps,
  "renderForeignResource"
>;

export function DsmHistoryCard(props: DsmHistoryCardProps) {
  return (
    <HistoryCard {...props} renderForeignResource={renderForeignResource} />
  );
}
