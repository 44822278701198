import React, { useState } from "react";
import { FormatQuote, FormatQuoteOutlined } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import type { Control, FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";

export interface FuzzyFieldProps<
  TFieldValues extends FieldValues,
  TFuzzyName extends FieldPathByValue<TFieldValues, string | null>,
  TExactName extends FieldPathByValue<TFieldValues, string | null>,
> {
  control: Control<TFieldValues>;
  exactName: TExactName;
  fuzzyName: TFuzzyName;
  label?: string;
}

export function FuzzyField<
  TFieldValues extends FieldValues,
  TFuzzyName extends FieldPathByValue<TFieldValues, string | null>,
  TExactName extends FieldPathByValue<TFieldValues, string | null>,
>({
  control,
  fuzzyName,
  exactName,
  label = getFieldLabel(exactName),
}: FuzzyFieldProps<TFieldValues, TExactName, TFuzzyName>) {
  const fuzzyFieldControl = useController({ control, name: fuzzyName });
  const exactFieldControl = useController({ control, name: exactName });

  // Exact mode should initially line up with whichever field isn't `null`.
  // This does assume only one of the fields at a time can be non-`null` but
  // nothing should break if that assumption is false.
  const [inExactMode, setInExactMode] = useState(
    exactFieldControl.field.value !== null,
  );

  let fieldValue: string;
  let error: boolean;
  let helperText: string | undefined;
  if (inExactMode) {
    fieldValue = exactFieldControl.field.value;
    error = exactFieldControl.fieldState.error !== undefined;
    helperText = exactFieldControl.fieldState.error?.message;
  } else {
    fieldValue = fuzzyFieldControl.field.value;
    error = fuzzyFieldControl.fieldState.error !== undefined;
    helperText = fuzzyFieldControl.fieldState.error?.message;
  }

  function handleFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    // Empty string is replace by null
    const newFieldValue = e.target.value || null;

    if (inExactMode) {
      exactFieldControl.field.onChange(newFieldValue);
    } else {
      fuzzyFieldControl.field.onChange(newFieldValue);
    }
  }

  function handleExactModeChange() {
    if (inExactMode) {
      setInExactMode(false);

      fuzzyFieldControl.field.onChange(fieldValue);
      exactFieldControl.field.onChange(null);
    } else {
      setInExactMode(true);

      exactFieldControl.field.onChange(fieldValue);
      fuzzyFieldControl.field.onChange(null);
    }
  }

  return (
    <TextField
      fullWidth
      label={label}
      value={fieldValue ?? ""}
      onChange={handleFieldChange}
      error={error}
      helperText={helperText ?? inExactMode ? "Using exact match" : " "}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={inExactMode ? "Use fuzzy match" : "Use exact match"}
            >
              <IconButton onClick={handleExactModeChange}>
                {inExactMode ? <FormatQuote /> : <FormatQuoteOutlined />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
}
