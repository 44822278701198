import {
  CheckboxField,
  ObjectField,
  SelectField,
  TextField,
} from "~/components/Form";
import {
  LogSelect,
  NewLqsResourceForm,
  TopicSelect,
  typeEncodingOptions,
  useCreateTopic,
} from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createTopicSchema } from "../schemas";

export function TopicCreateForm() {
  const lqsNavigator = useLqsNavigator({ toTopicDetails: true });

  return (
    <NewLqsResourceForm
      schema={createTopicSchema}
      defaultValues={{
        name: null,
        logId: null,
        associatedTopicId: null,
        strict: false,
        typeName: null,
        typeEncoding: null,
        typeData: null,
        typeSchema: null,
        locked: false,
        note: null,
        context: null,
      }}
      resourceName="topic"
      mutation={useCreateTopic()}
      createDetailsLocation={(response) =>
        lqsNavigator.toTopicDetails({ topicId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <LogSelect control={control} name="logId" required />
          <TopicSelect control={control} name="associatedTopicId" />
          <CheckboxField control={control} name="strict" />
          <TextField control={control} name="typeName" />
          <SelectField
            control={control}
            name="typeEncoding"
            options={typeEncodingOptions}
          />
          <TextField control={control} name="typeData" />
          <ObjectField control={control} name="typeSchema" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
