/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Ingestion } from "./Ingestion";
import {
  IngestionFromJSON,
  IngestionFromJSONTyped,
  IngestionToJSON,
} from "./Ingestion";

export interface IngestionDataResponse {
  data: Ingestion;
}

export function IngestionDataResponseFromJSON(
  json: any,
): IngestionDataResponse {
  return IngestionDataResponseFromJSONTyped(json);
}

export function IngestionDataResponseFromJSONTyped(
  json: any,
): IngestionDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: IngestionFromJSON(json["data"]),
  };
}

export function IngestionDataResponseToJSON(
  value: IngestionDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: IngestionToJSON(value.data),
  };
}
