import { CircularProgress, Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import {
  DetailsLayout,
  LockCard,
  RelatedResource,
} from "~/components/DetailsCards";
import {
  CheckboxField,
  Form,
  FormSkeleton,
  getChangedFields,
  TextField,
  useStudioForm,
} from "~/components/Form";
import {
  OBJECT_KEY_DELIMITER,
  ObjectSearchRequestProvider,
} from "~/components/ObjectExplorer";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { pick } from "~/lib/std";
import type { Topic } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteTopic,
  useTopic,
  useUpdateTopic,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { LogObjectTable } from "../../logs/components/LogObjectTable";
import { editTopicSchema } from "../schemas";
import { RecordSearchRequestProvider, RecordTable } from "./RecordTable";

export function TopicDetails() {
  const { topicId } = useTypedParams(lqsRoutePaths.TOPIC_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toRecordTable: true,
    toTopicEdit: true,
    toTopicTable: true,
  });

  const query = useTopic(topicId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const typeSection = <TypeSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResource
        text="Records"
        to={lqsNavigator.toRecordTable({ topicId })}
        table={
          <RecordSearchRequestProvider embedded>
            <RecordTable />
          </RecordSearchRequestProvider>
        }
      />
      <RelatedObjects query={query} />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="topic"
      query={query}
      updateMutation={useUpdateTopic(topicId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="topic"
      query={query}
      editLocation={lqsNavigator.toTopicEdit({ topicId })}
      deleteMutation={useDeleteTopic(topicId)}
      getReadableName={(topic) => topic.name}
      listLocation={lqsNavigator.toTopicTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {typeSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {typeSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Topic> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "name" },
          { dataType: "foreign-key", resourceType: "log", accessor: "logId" },
          {
            dataType: "foreign-key",
            resourceType: "topic",
            accessor: "associatedTopicId",
          },
          { dataType: "timestamp", accessor: "startTime" },
          { dataType: "timestamp", accessor: "endTime" },
          { dataType: "bytes", accessor: "recordSize" },
          { dataType: "number", accessor: "recordCount" },
          { dataType: "bytes", accessor: "objectSize" },
          { dataType: "number", accessor: "objectCount" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function TypeSection({ query }: { query: UseQueryResult<Topic> }) {
  return (
    <Card title="Type">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "text", accessor: "typeName" },
          { dataType: "text", accessor: "typeEncoding" },
          { dataType: "pre", accessor: "typeData" },
          { dataType: "text", accessor: "typeSchema" },
        ]}
      />
    </Card>
  );
}

function RelatedObjects({ query }: { query: UseQueryResult<Topic> }) {
  const lqsNavigator = useLqsNavigator({ toLogObjectTable: true });

  return (
    <QueryRenderer
      query={query}
      loading={
        <Card>
          <Center sx={{ height: 350 }}>
            <CircularProgress />
          </Center>
        </Card>
      }
      error={
        <Card>
          <ErrorMessage>Couldn't load objects</ErrorMessage>
        </Card>
      }
      success={(topic) => {
        const topicPrefix = ["topics", topic.id, ""].join(OBJECT_KEY_DELIMITER);

        return (
          <RelatedResource
            text="Objects"
            to={lqsNavigator.toLogObjectTable(
              { logId: topic.logId },
              { directory: topicPrefix },
            )}
            table={
              <ObjectSearchRequestProvider embedded>
                <LogObjectTable
                  logId={topic.logId}
                  homeName={topic.id}
                  subResourcePrefix={topicPrefix}
                />
              </ObjectSearchRequestProvider>
            }
          />
        );
      }}
    />
  );
}

function InfoSection({ query }: { query: UseQueryResult<Topic> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["multiline", "text"]} />}
        success={(topic) => <InfoSectionImpl topic={topic} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ topic }: { topic: Topic }) {
  const updateTopic = useUpdateTopic(topic.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editTopicSchema.pick({
    note: true,
    strict: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(topic, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateTopic.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Topic updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update topic", { variant: "error" });
        },
      });
    },
  });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={updateTopic.isLoading}
        submitText="Save Changes"
        disabled={topic.locked}
      >
        <TextField control={control} name="note" multiline />
        <CheckboxField control={control} name="strict" />
      </Form>
      {topic.locked && (
        <Typography variant="body2">
          Unlock this topic to make updates.
        </Typography>
      )}
    </>
  );
}
