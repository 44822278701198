import {
  BooleanField,
  FuzzyField,
  SelectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { processStateOptions, processTypeOptions } from "~/lqs";
import { listWorkflowHooksSchema } from "../schemas";
import { useWorkflowHookSearchRequest } from "./WorkflowHookTable";

export function WorkflowHookFilters() {
  const [request, setRequest] = useWorkflowHookSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listWorkflowHooksSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <FuzzyField control={control} exactName="uri" fuzzyName="uriLike" />
      <SelectField
        control={control}
        name="triggerProcess"
        options={processTypeOptions}
      />
      <SelectField
        control={control}
        name="triggerState"
        options={processStateOptions}
      />
      <BooleanField control={control} name="managed" />
      <BooleanField control={control} name="disabled" />
      <TextField control={control} name="noteLike" label="Note" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
