/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ObjectPart {
  partNumber: number;
  etag: string;
  size: number;
  lastModified: Date | null;
  presignedUrl: string | null;
}

export function ObjectPartFromJSON(json: any): ObjectPart {
  return ObjectPartFromJSONTyped(json);
}

export function ObjectPartFromJSONTyped(json: any): ObjectPart {
  if (json == null) {
    return json;
  }

  return {
    partNumber: json["part_number"],
    etag: json["etag"],
    size: json["size"],
    lastModified:
      json["last_modified"] === null ? null : new Date(json["last_modified"]),
    presignedUrl: json["presigned_url"],
  };
}

export function ObjectPartToJSON(value: ObjectPart | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    part_number: value.partNumber,
    etag: value.etag,
    size: value.size,
    last_modified:
      value.lastModified === null ? null : value.lastModified.toISOString(),
    presigned_url: value.presignedUrl,
  };
}
