/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GroupUpdateRequest {
  locked?: boolean;
  name?: string;
  note?: string | null;
  context?: object | null;
  defaultWorkflowId?: string | null;
}

export function GroupUpdateRequestFromJSON(json: any): GroupUpdateRequest {
  return GroupUpdateRequestFromJSONTyped(json);
}

export function GroupUpdateRequestFromJSONTyped(json: any): GroupUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    name: json["name"],
    note: json["note"],
    context: json["context"],
    defaultWorkflowId: json["default_workflow_id"],
  };
}

export function GroupUpdateRequestToJSON(
  value: GroupUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    name: value.name,
    note: value.note,
    context: value.context,
    default_workflow_id: value.defaultWorkflowId,
  };
}
