import {
  BooleanField,
  FuzzyField,
  NumberField,
  TextField,
  TimestampField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { GroupSelectField, LabelMultiSelect } from "~/lqs";
import { listLogsSchema } from "../schemas";
import { useLogSearchRequest } from "./LogTable";

export function LogFilters() {
  const [request, setRequest] = useLogSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listLogsSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <GroupSelectField control={control} name="groupId" />
      <LabelMultiSelect
        control={control}
        name="tagLabelIdsIncludes"
        label="Tags"
      />
      <BooleanField control={control} name="locked" />
      <TextField control={control} name="noteLike" label="Note" />
      <TimestampField control={control} name="startTimeGte" />
      <TimestampField control={control} name="startTimeLte" />
      <BooleanField control={control} name="startTimeNull" />
      <TimestampField control={control} name="endTimeGte" />
      <TimestampField control={control} name="endTimeLte" />
      <BooleanField control={control} name="endTimeNull" />
      <NumberField control={control} name="recordSizeGte" />
      <NumberField control={control} name="recordSizeLte" />
      <NumberField control={control} name="recordCountGte" />
      <NumberField control={control} name="recordCountLte" />
      <NumberField control={control} name="objectSizeGte" />
      <NumberField control={control} name="objectSizeLte" />
      <NumberField control={control} name="objectCountGte" />
      <NumberField control={control} name="objectCountLte" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
