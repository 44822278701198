/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { UploadState } from "./UploadState";
import {
  UploadStateFromJSON,
  UploadStateFromJSONTyped,
  UploadStateToJSON,
} from "./UploadState";

export interface CloudObject {
  key: string;
  etag: string | null;
  size: number | null;
  lastModified: Date | null;
  presignedUrl: string | null;
  uploadState: UploadState;
}

export function CloudObjectFromJSON(json: any): CloudObject {
  return CloudObjectFromJSONTyped(json);
}

export function CloudObjectFromJSONTyped(json: any): CloudObject {
  if (json == null) {
    return json;
  }

  return {
    key: json["key"],
    etag: json["etag"],
    size: json["size"],
    lastModified:
      json["last_modified"] === null ? null : new Date(json["last_modified"]),
    presignedUrl: json["presigned_url"],
    uploadState: UploadStateFromJSON(json["upload_state"]),
  };
}

export function CloudObjectToJSON(value: CloudObject | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    key: value.key,
    etag: value.etag,
    size: value.size,
    last_modified:
      value.lastModified === null ? null : value.lastModified.toISOString(),
    presigned_url: value.presignedUrl,
    upload_state: UploadStateToJSON(value.uploadState),
  };
}
