import { Button, Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  LockCard,
  RelatedResource,
} from "~/components/DetailsCards";
import {
  Form,
  FormSkeleton,
  getChangedFields,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { pick } from "~/lib/std";
import type { Log } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteLog,
  useLog,
  useUpdateLog,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editLogSchema } from "../schemas";
import {
  LogObjectSearchRequestProvider,
  LogObjectTable,
} from "./LogObjectTable";
import { LogQuerySearchRequestProvider, LogQueryTable } from "./LogQueryTable";
import { TagSearchRequestProvider, TagTable } from "./TagTable";

export function LogDetails() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toLogTagTable: true,
    toLogObjectTable: true,
    toLogQueryTable: true,
    toLogEdit: true,
    toLogTable: true,
  });

  const query = useLog(logId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResource
        text="Tags"
        to={lqsNavigator.toLogTagTable({ logId })}
        table={
          <TagSearchRequestProvider embedded>
            <TagTable />
          </TagSearchRequestProvider>
        }
      />
      <RelatedResource
        text="Objects"
        to={lqsNavigator.toLogObjectTable({ logId })}
        table={
          <LogObjectSearchRequestProvider embedded>
            <LogObjectTable logId={logId} />
          </LogObjectSearchRequestProvider>
        }
      />
      <RelatedResource
        text="Queries"
        to={lqsNavigator.toLogQueryTable({ logId })}
        table={
          <LogQuerySearchRequestProvider embedded>
            <LogQueryTable />
          </LogQuerySearchRequestProvider>
        }
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="log"
      query={query}
      updateMutation={useUpdateLog(logId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="log"
      query={query}
      editLocation={lqsNavigator.toLogEdit({ logId })}
      deleteMutation={useDeleteLog(logId)}
      getReadableName={(log) => log.name}
      listLocation={lqsNavigator.toLogTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Log> }) {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_DETAILS);

  const lqsNavigator = useLqsNavigator();

  return (
    <Card
      title="General"
      action={
        <Button
          variant="contained"
          component={RouterLink}
          to={lqsNavigator.toPlayer({ logId })}
        >
          Open in Player
        </Button>
      }
    >
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "group",
            accessor: "groupId",
          },
          {
            dataType: "foreign-key",
            resourceType: "workflow",
            accessor: "defaultWorkflowId",
          },
          { dataType: "timestamp", accessor: "startTime" },
          { dataType: "timestamp", accessor: "endTime" },
          { dataType: "bytes", accessor: "recordSize" },
          { dataType: "number", accessor: "recordCount" },
          { dataType: "bytes", accessor: "objectSize" },
          { dataType: "number", accessor: "objectCount" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Log> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["text", "multiline"]} />}
        success={(log) => <InfoSectionImpl log={log} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ log }: { log: Log }) {
  const updateLog = useUpdateLog(log.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editLogSchema.pick({ name: true, note: true });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(log, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateLog.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("Log updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update log", { variant: "error" });
        },
      });
    },
  });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        loading={updateLog.isLoading}
        submitText="Save Changes"
        disabled={log.locked}
      >
        <TextField control={control} name="name" />
        <TextField control={control} name="note" multiline />
      </Form>
      {log.locked && (
        <Typography variant="body2">
          Unlock this log to make updates.
        </Typography>
      )}
    </>
  );
}
