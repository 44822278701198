/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Workflow {
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  name: string;
  note: string | null;
  context: object | null;
  managed: boolean;
  _default: boolean;
  disabled: boolean;
  contextSchema: object | null;
}

export function WorkflowFromJSON(json: any): Workflow {
  return WorkflowFromJSONTyped(json);
}

export function WorkflowFromJSONTyped(json: any): Workflow {
  if (json == null) {
    return json;
  }

  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    name: json["name"],
    note: json["note"],
    context: json["context"],
    managed: json["managed"],
    _default: json["default"],
    disabled: json["disabled"],
    contextSchema: json["context_schema"],
  };
}

export function WorkflowToJSON(value: Workflow | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    name: value.name,
    note: value.note,
    context: value.context,
    managed: value.managed,
    default: value._default,
    disabled: value.disabled,
    context_schema: value.contextSchema,
  };
}
