import type { UseQueryResult } from "@tanstack/react-query";
import { commonResourceDetails } from "~/domain/common";
import type { CommonResource } from "~/domain/common";
import { Card } from "../Card";
import type { ForeignResourceRenderer } from "../Table";
import { ResourceFields } from "./ResourceFields";

export interface HistoryCardProps {
  query: UseQueryResult<CommonResource>;
  renderForeignResource: ForeignResourceRenderer<"user">;
}

export function HistoryCard({
  query,
  renderForeignResource,
}: HistoryCardProps) {
  return (
    <Card title="History">
      <ResourceFields
        query={query}
        fields={commonResourceDetails}
        renderForeignResource={renderForeignResource}
      />
    </Card>
  );
}
