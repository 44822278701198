import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { DetailsLayout, ManageCard } from "~/components/DetailsCards";
import {
  CheckboxField,
  Form,
  FormSkeleton,
  getChangedFields,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { pick } from "~/lib/std";
import { selectData } from "~/utils";
import { DsmHistoryCard } from "../../../DsmHistoryCard";
import { DsmResourceFields } from "../../../DsmResourceFields";
import {
  useDataStore,
  useDeleteDataStore,
  useUpdateDataStore,
} from "../../../api";
import * as dsmPaths from "../../../paths";
import type { DataStore } from "../../../sdk";
import { editDataStoreSchema } from "../schemas";
import { UserManagementSection } from "./UserManagementSection";

export function DataStoreDetails() {
  const { dataStoreId } = dsmPaths.useDsmPathParams(dsmPaths.DATASTORE_DETAILS);

  const query = useDataStore(dataStoreId, { select: selectData });
  const deleteMutation = useDeleteDataStore(dataStoreId);

  const generalSection = <GeneralSection query={query} />;
  const userManagementSection = <UserManagementSection />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <DsmHistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="DataStore"
      query={query}
      editLocation={dsmPaths.createDsmPath(dsmPaths.DATASTORE_EDIT, {
        dataStoreId,
      })}
      deleteMutation={deleteMutation}
      getReadableName={(dataStore) => dataStore.name}
      listLocation={dsmPaths.createDsmPath(dsmPaths.DATASTORE_TABLE)}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {userManagementSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {userManagementSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<DataStore> }) {
  return (
    <Card title="General">
      <DsmResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "user",
            accessor: "ownerId",
          },
          { dataType: "text", accessor: "version" },
          { dataType: "text", accessor: "region" },
          { dataType: "text", accessor: "endpoint" },
          { dataType: "json", accessor: "config" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<DataStore> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["text", "multiline", "text"]} />}
        success={(dataStore) => <InfoSectionImpl dataStore={dataStore} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ dataStore }: { dataStore: DataStore }) {
  const updateDataStore = useUpdateDataStore(dataStore.id);

  const { enqueueSnackbar } = useSnackbar();

  const schema = editDataStoreSchema.pick({
    name: true,
    note: true,
    disabled: true,
  });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(dataStore, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateDataStore.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("DataStore updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update DataStore", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateDataStore.isLoading}
      submitText="Save Changes"
    >
      <TextField control={control} name="name" required />
      <TextField control={control} name="note" multiline />
      <CheckboxField control={control} name="disabled" />
    </Form>
  );
}
