import type React from "react";
import { useState } from "react";

export interface PointerLocation {
  /**
   * X coordinate of the pointer relative to top left of the container
   */
  x: number;
  /**
   * Y coordinate of the pointer relative to top left of the container
   */
  y: number;
  /**
   * Width of the container
   */
  width: number;
  /**
   * Height of the container
   */
  height: number;
}

export interface PointerContainerProps
  extends Pick<
    React.HTMLAttributes<HTMLDivElement>,
    "onPointerDown" | "onPointerMove" | "onPointerUp" | "onPointerCancel"
  > {}

export function usePointerLocation({
  enabled = true,
  onInteractionStart,
  onChange,
  onInteractionEnd,
}: {
  enabled?: boolean;
  onInteractionStart?: (e: React.PointerEvent) => boolean | void | undefined;
  onChange: (pointerLocation: PointerLocation) => void;
  onInteractionEnd?: (pointerLocation: PointerLocation) => void;
}): PointerContainerProps {
  const [interactionPointerId, setInteractionPointerId] = useState<
    number | null
  >(null);

  return {
    ...(enabled && {
      onPointerDown(e) {
        if (interactionPointerId !== null) {
          // Already have an ongoing interaction
          return;
        }

        const { currentTarget: container, isPrimary, pointerId } = e;

        if (!isPrimary) {
          // Only track interactions with primary pointer
          return;
        }

        if (onInteractionStart?.(e) === false) {
          // Handler indicates this event should not be allowed to trigger the
          // start of a pointer-tracking interaction
          return;
        }

        // User could move the cursor outside the container during the interaction
        // so ensure pointer capture remains on the container
        container.setPointerCapture(pointerId);
        setInteractionPointerId(pointerId);

        onChange(calculatePointerLocation(e));
      },
      // If there's no ongoing interaction, don't attach the following event
      // handlers, especially `onPointerMove` which would fire almost constantly
      ...(interactionPointerId !== null && {
        onPointerMove(e) {
          if (e.pointerId !== interactionPointerId) {
            // Only handle events from the pointer which started this interaction
            return;
          }

          // Prevent text inside nodes being highlighted during resize
          e.preventDefault();

          onChange(calculatePointerLocation(e));
        },
        onPointerUp(e) {
          if (e.pointerId !== interactionPointerId) {
            // Only handle events from the pointer which started this interaction
            return;
          }

          // Interaction is finished
          setInteractionPointerId(null);

          onInteractionEnd?.(calculatePointerLocation(e));
        },
        onPointerCancel(e) {
          if (e.pointerId !== interactionPointerId) {
            // Only handle events from the pointer which started this interaction
            return;
          }

          // Interaction is finished
          setInteractionPointerId(null);

          onInteractionEnd?.(calculatePointerLocation(e));
        },
      }),
    }),
  };
}

function calculatePointerLocation(
  e: React.PointerEvent<HTMLDivElement>,
): PointerLocation {
  const { currentTarget: container, clientX, clientY } = e;

  const boundingRect = container.getBoundingClientRect();

  return {
    x: clientX - boundingRect.x,
    y: clientY - boundingRect.y,
    width: boundingRect.width,
    height: boundingRect.height,
  };
}
