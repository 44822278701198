/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface APIKeyCreateRequest {
  name: string;
  userId: string;
  disabled?: boolean;
}

export function APIKeyCreateRequestFromJSON(json: any): APIKeyCreateRequest {
  return APIKeyCreateRequestFromJSONTyped(json);
}

export function APIKeyCreateRequestFromJSONTyped(
  json: any,
): APIKeyCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    name: json["name"],
    userId: json["user_id"],
    disabled: json["disabled"],
  };
}

export function APIKeyCreateRequestToJSON(
  value: APIKeyCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value.name,
    user_id: value.userId,
    disabled: value.disabled,
  };
}
