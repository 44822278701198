import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import {
  EditLqsResourceForm,
  GroupSelectField,
  useLog,
  useUpdateLog,
  WorkflowSelect,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_LOG_FIELDS, editLogSchema } from "../schemas";

export function LogEditForm() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_EDIT);

  const lqsNavigator = useLqsNavigator({ toLogDetails: true });

  return (
    <EditLqsResourceForm
      schema={editLogSchema}
      resourceName="log"
      query={useLog(logId, { select: selectData })}
      editableFields={EDITABLE_LOG_FIELDS}
      mutation={useUpdateLog(logId)}
      detailsLocation={lqsNavigator.toLogDetails({ logId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <GroupSelectField control={control} name="groupId" required />
          <WorkflowSelect control={control} name="defaultWorkflowId" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
