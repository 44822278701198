/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RecordUpdateRequest {
  locked?: boolean;
  error?: object | null;
  queryData?: object | null;
  auxiliaryData?: object | null;
  context?: object | null;
  note?: string | null;
}

export function RecordUpdateRequestFromJSON(json: any): RecordUpdateRequest {
  return RecordUpdateRequestFromJSONTyped(json);
}

export function RecordUpdateRequestFromJSONTyped(
  json: any,
): RecordUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    error: json["error"],
    queryData: json["query_data"],
    auxiliaryData: json["auxiliary_data"],
    context: json["context"],
    note: json["note"],
  };
}

export function RecordUpdateRequestToJSON(
  value: RecordUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    error: value.error,
    query_data: value.queryData,
    auxiliary_data: value.auxiliaryData,
    context: value.context,
    note: value.note,
  };
}
