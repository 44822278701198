import { HelpCenter } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Popover,
  Typography,
} from "@mui/material";
import type { PopupState } from "material-ui-popup-state/hooks";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import * as z from "zod";
import type { SchemaShape } from "~/components/Form";
import { Form, SelectField, TextField, useStudioForm } from "~/components/Form";
import type { Option } from "~/components/Form/types";
import { createErrorMap, requiredEmail, requiredText } from "~/domain/common";
import { useCreateTicket, useCurrentDsmUser } from "./api";
import type { TicketCreateRequest } from "./sdk";
import { TicketType } from "./sdk";

const ticketTypeOptions: ReadonlyArray<Option> = Object.values(TicketType).map(
  (value) => {
    let label;
    switch (value) {
      case TicketType.Question: {
        label = "General Question";
        break;
      }
      case TicketType.SoftwareIssue: {
        label = "Report Bug";
        break;
      }
      default: {
        label = value;
      }
    }

    return {
      value,
      label,
    };
  },
);

const ticketFormSchema = z.object({
  type: z.nativeEnum(TicketType, {
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (
          issue.received === z.ZodParsedType.null ||
          issue.received === z.ZodParsedType.undefined
        ) {
          return "Field is required";
        }
      },
    }),
  }),
  email: requiredEmail,
  description: requiredText,
} satisfies SchemaShape<TicketCreateRequest>);

export function SupportTicketButton() {
  const popupState = usePopupState({
    popupId: "support-ticket",
    variant: "popover",
  });

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        disableElevation
        startIcon={<HelpCenter />}
        {...bindTrigger(popupState)}
      >
        Get Support
      </Button>
      <SupportTicketForm popupState={popupState} />
    </>
  );
}

function SupportTicketForm({ popupState }: { popupState: PopupState }) {
  const defaultEmailQuery = useCurrentDsmUser({
    select(response) {
      if (response.data === null) {
        return null;
      }

      if (!checkIsEmail(response.data.username)) {
        return null;
      }

      return response.data.username;
    },
  });

  const createTicketMutation = useCreateTicket();

  const { control, handleSubmit, reset } = useStudioForm({
    schema: ticketFormSchema,
    values: {
      type: TicketType.Question,
      email: defaultEmailQuery.data ?? null,
      description: null,
    },
    onSubmit(values) {
      createTicketMutation.mutate(values);
    },
  });

  function handleExited(): void {
    if (createTicketMutation.isSuccess) {
      reset();
      createTicketMutation.reset();
    } else {
      reset(undefined, { keepValues: true });
    }
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            p: 2,
            width: 350,
          },
        },
      }}
      TransitionProps={{
        onExited: handleExited,
      }}
      {...bindPopover(popupState)}
    >
      <Typography variant="h6" component="p" sx={{ mb: 2 }}>
        LogQS Studio Support
      </Typography>
      <Form
        onSubmit={handleSubmit}
        disabled={createTicketMutation.isSuccess}
        loading={createTicketMutation.isLoading}
        submitText="Send Request"
      >
        <SelectField
          control={control}
          name="type"
          label="Support Type"
          disabled={createTicketMutation.isSuccess}
          size="small"
          options={ticketTypeOptions}
        />
        <TextField
          control={control}
          name="email"
          autoComplete="email"
          disabled={createTicketMutation.isSuccess}
          size="small"
        />
        <TextField
          control={control}
          size="small"
          name="description"
          disabled={createTicketMutation.isSuccess}
          multiline
        />
      </Form>
      {createTicketMutation.isSuccess && (
        <>
          <Divider sx={{ my: 2 }} />
          <Alert severity="success">
            <AlertTitle>Support Request Sent</AlertTitle>
            Thank you for contacting support. We'll be in contact as soon as
            possible.
          </Alert>
        </>
      )}
    </Popover>
  );
}

function checkIsEmail(username: string): boolean {
  return requiredEmail.safeParse(username).success;
}
