/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Hook } from "./Hook";
import { HookFromJSON, HookFromJSONTyped, HookToJSON } from "./Hook";

export interface HookDataResponse {
  data: Hook;
}

export function HookDataResponseFromJSON(json: any): HookDataResponse {
  return HookDataResponseFromJSONTyped(json);
}

export function HookDataResponseFromJSONTyped(json: any): HookDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: HookFromJSON(json["data"]),
  };
}

export function HookDataResponseToJSON(
  value: HookDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: HookToJSON(value.data),
  };
}
