import type { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { MenuItem, TextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";
import { useField } from "./hooks";
import type { BaseInputProps, FieldPropsFromInputProps, Option } from "./types";

interface SelectInputProps
  extends BaseInputProps<string | null>,
    Pick<MuiTextFieldProps, "size"> {
  disabled?: boolean;
  options: ReadonlyArray<Option>;
}

export function SelectInput({
  name,
  label = getFieldLabel(name),
  required,
  size = "medium",
  disabled = false,
  options,
  value,
  onChange,
  errorMessage,
}: SelectInputProps) {
  return (
    <TextField
      select
      fullWidth
      size={size}
      required={required}
      disabled={disabled}
      label={label}
      error={errorMessage !== undefined}
      helperText={errorMessage ?? " "}
      value={value ?? ""}
      onChange={(e) => {
        const rawValue = e.target.value;

        if (rawValue === "") {
          onChange(null);
        } else {
          onChange(rawValue);
        }
      }}
    >
      <MenuItem value="">-</MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export interface SelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
> extends FieldPropsFromInputProps<TFieldValues, TName, SelectInputProps> {}

export function SelectField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
>({
  control,
  onChange: onChangeProp,
  ...rest
}: SelectFieldProps<TFieldValues, TName>) {
  const { value, onChange, errorMessage } = useField({
    control,
    name: rest.name,
  });

  const handleChange: typeof onChange = (value): void => {
    onChange(value);
    onChangeProp?.(value);
  };

  return (
    <SelectInput
      {...rest}
      value={value}
      onChange={handleChange}
      errorMessage={errorMessage}
    />
  );
}
