/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  APIKeyCreateRequest,
  APIKeyDataResponse,
  APIKeyListResponse,
  APIKeyUpdateRequest,
  HTTPValidationError,
} from "../models";
import {
  APIKeyCreateRequestFromJSON,
  APIKeyCreateRequestToJSON,
  APIKeyDataResponseFromJSON,
  APIKeyDataResponseToJSON,
  APIKeyListResponseFromJSON,
  APIKeyListResponseToJSON,
  APIKeyUpdateRequestFromJSON,
  APIKeyUpdateRequestToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models";

export interface CreateApiKeyRequest {
  aPIKeyCreateRequest: APIKeyCreateRequest;
}

export interface DeleteApiKeyRequest {
  apiKeyId: string;
}

export interface FetchApiKeyRequest {
  apiKeyId: string;
}

export interface ListApiKeysRequest {
  name?: string | null;
  nameLike?: string | null;
  userId?: string | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateApiKeyRequest {
  apiKeyId: string;
  aPIKeyUpdateRequest: APIKeyUpdateRequest;
}

export class APIKeyApi extends runtime.BaseAPI {
  async createApiKey(
    this: APIKeyApi,
    requestParameters: CreateApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<APIKeyDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.aPIKeyCreateRequest,
      "aPIKeyCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/apiKeys`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: APIKeyCreateRequestToJSON(requestParameters.aPIKeyCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return APIKeyDataResponseFromJSON(jsonValue);
  }

  async deleteApiKey(
    this: APIKeyApi,
    requestParameters: DeleteApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.apiKeyId, "apiKeyId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/apiKeys/{api_key_id}`.replace(
          `{${"api_key_id"}}`,
          encodeURIComponent(String(requestParameters.apiKeyId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchApiKey(
    this: APIKeyApi,
    requestParameters: FetchApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<APIKeyDataResponse> {
    runtime.assertParamIsPresent(requestParameters.apiKeyId, "apiKeyId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/apiKeys/{api_key_id}`.replace(
          `{${"api_key_id"}}`,
          encodeURIComponent(String(requestParameters.apiKeyId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return APIKeyDataResponseFromJSON(jsonValue);
  }

  async listApiKeys(
    this: APIKeyApi,
    requestParameters: ListApiKeysRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<APIKeyListResponse> {
    const queryParameters: any = {};

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.userId != null) {
      queryParameters["user_id"] = requestParameters.userId;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/apiKeys`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return APIKeyListResponseFromJSON(jsonValue);
  }

  async updateApiKey(
    this: APIKeyApi,
    requestParameters: UpdateApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<APIKeyDataResponse> {
    runtime.assertParamIsPresent(requestParameters.apiKeyId, "apiKeyId");

    runtime.assertParamIsPresent(
      requestParameters.aPIKeyUpdateRequest,
      "aPIKeyUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/apiKeys/{api_key_id}`.replace(
          `{${"api_key_id"}}`,
          encodeURIComponent(String(requestParameters.apiKeyId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: APIKeyUpdateRequestToJSON(requestParameters.aPIKeyUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return APIKeyDataResponseFromJSON(jsonValue);
  }
}
