import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import type {
  Digestion,
  DigestionCreateRequest,
  DigestionPart,
  DigestionPartCreateRequest,
  DigestionPartDataResponse,
  DigestionPartListResponse,
  DigestionPartUpdateRequest,
  DigestionTopic,
  DigestionTopicCreateRequest,
  DigestionTopicDataResponse,
  DigestionTopicListResponse,
  DigestionTopicUpdateRequest,
  DigestionUpdateRequest,
  ListDigestionPartsRequest,
  ListDigestionsRequest,
  ListDigestionTopicsRequest,
} from "~/lqs";
import type { KeyFactory } from "~/types";
import { scheduleQueriesRemoval, stringifyBigintFields } from "~/utils";
import { useDataStoreClients } from "../context";
import { createResourceCrudHooks, getInitialDetailsData } from "./utils";

export const {
  queryKeyFactory: digestionKeys,
  useList: useDigestions,
  useFetch: useDigestion,
  useCreate: useCreateDigestion,
  useUpdate: useUpdateDigestion,
  useDelete: useDeleteDigestion,
} = createResourceCrudHooks({
  baseQueryKey: "digestions",
  getIdentifier(digestion: Digestion) {
    return digestion.id;
  },
  listResource({ signal }, { digestionApi }, request: ListDigestionsRequest) {
    return digestionApi.listDigestions(request, { signal });
  },
  fetchResource({ signal }, { digestionApi }, digestionId: Digestion["id"]) {
    return digestionApi.fetchDigestion({ digestionId }, { signal });
  },
  createResource({ digestionApi }, request: DigestionCreateRequest) {
    return digestionApi.createDigestion({ digestionCreateRequest: request });
  },
  updateResource(
    { digestionApi },
    digestionId: Digestion["id"],
    updates: DigestionUpdateRequest,
  ) {
    return digestionApi.updateDigestion({
      digestionId,
      digestionUpdateRequest: updates,
    });
  },
  deleteResource({ digestionApi }, digestionId: Digestion["id"]) {
    return digestionApi.deleteDigestion({ digestionId });
  },
});

export type DigestionKeys = KeyFactory<typeof digestionKeys>;

export const digestionTopicKeys = {
  all: (digestionId: Digestion["id"]) =>
    [...digestionKeys.all, digestionId, "topics"] as const,
  lists: (digestionId: Digestion["id"]) =>
    [...digestionTopicKeys.all(digestionId), "list"] as const,
  list: (
    digestionId: Digestion["id"],
    request: StrictOmit<ListDigestionTopicsRequest, "digestionId">,
  ) =>
    [
      ...digestionTopicKeys.lists(digestionId),
      stringifyBigintFields(request),
    ] as const,
  fetches: (digestionId: Digestion["id"]) =>
    [...digestionTopicKeys.all(digestionId), "fetch"] as const,
  fetch: (
    digestionId: Digestion["id"],
    digestionTopicId: DigestionTopic["id"],
  ) => [...digestionTopicKeys.fetches(digestionId), digestionTopicId] as const,
};

export type DigestionTopicKeys = KeyFactory<typeof digestionTopicKeys>;

export function useDigestionTopics<TData = DigestionTopicListResponse>(
  digestionId: Digestion["id"],
  request: StrictOmit<ListDigestionTopicsRequest, "digestionId">,
  options?: UseQueryOptions<
    DigestionTopicListResponse,
    unknown,
    TData,
    DigestionTopicKeys["list"]
  >,
): UseQueryResult<TData> {
  const { digestionApi } = useDataStoreClients();

  return useQuery({
    queryKey: digestionTopicKeys.list(digestionId, request),
    queryFn(context) {
      return digestionApi.listDigestionTopics(
        { digestionId, ...request },
        context,
      );
    },
    ...options,
  });
}

export function useDigestionTopic<TData = DigestionTopicDataResponse>(
  digestionId: Digestion["id"],
  digestionTopicId: DigestionTopic["id"],
  options?: StrictOmit<
    UseQueryOptions<
      DigestionTopicDataResponse,
      unknown,
      TData,
      DigestionTopicKeys["fetch"]
    >,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useQuery({
    queryKey: digestionTopicKeys.fetch(digestionId, digestionTopicId),
    queryFn(context) {
      return digestionApi.fetchDigestionTopic(
        { digestionId, digestionTopicId },
        context,
      );
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        digestionTopicKeys.lists(digestionId),
        (digestionTopic: DigestionTopic) =>
          digestionTopic.digestionId === digestionId &&
          digestionTopic.id === digestionTopicId,
      );
    },
  });
}

export function useCreateDigestionTopic(
  digestionId: Digestion["id"],
): UseMutationResult<
  DigestionTopicDataResponse,
  unknown,
  DigestionTopicCreateRequest
> {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return digestionApi.createDigestionTopic({
        digestionId,
        digestionTopicCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: digestionTopicKeys.lists(digestionId),
      });
      queryClient.setQueryData<DigestionTopicDataResponse>(
        digestionTopicKeys.fetch(digestionId, response.data.id),
        response,
      );
    },
  });
}

export function useUpdateDigestionTopic(
  digestionId: Digestion["id"],
  digestionTopicId: DigestionTopic["id"],
): UseMutationResult<
  DigestionTopicDataResponse,
  unknown,
  DigestionTopicUpdateRequest
> {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return digestionApi.updateDigestionTopic({
        digestionId,
        digestionTopicId,
        digestionTopicUpdateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: digestionTopicKeys.lists(digestionId),
      });
      queryClient.setQueryData<DigestionTopicDataResponse>(
        digestionTopicKeys.fetch(digestionId, response.data.id),
        response,
      );
    },
  });
}

export function useDeleteDigestionTopic(
  digestionId: Digestion["id"],
  digestionTopicId: DigestionTopic["id"],
): UseMutationResult<void, unknown, void> {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return digestionApi.deleteDigestionTopic({
        digestionId,
        digestionTopicId,
      });
    },
    onSuccess() {
      queryClient.removeQueries({
        queryKey: digestionTopicKeys.lists(digestionId),
      });
      scheduleQueriesRemoval(() =>
        queryClient.removeQueries({
          queryKey: digestionTopicKeys.fetch(digestionId, digestionTopicId),
        }),
      );
    },
  });
}

export const digestionPartKeys = {
  all: (digestionId: Digestion["id"]) =>
    [...digestionKeys.fetch(digestionId), "parts"] as const,
  lists: (digestionId: Digestion["id"]) =>
    [...digestionPartKeys.all(digestionId), "list"] as const,
  list: (
    digestionId: Digestion["id"],
    request: StrictOmit<ListDigestionPartsRequest, "digestionId">,
  ) => [...digestionPartKeys.lists(digestionId), request] as const,
  fetches: (digestionId: Digestion["id"]) =>
    [...digestionPartKeys.all(digestionId), "fetch"] as const,
  fetch: (digestionId: Digestion["id"], digestionPartId: DigestionPart["id"]) =>
    [...digestionPartKeys.fetches(digestionId), digestionPartId] as const,
};

type DigestionPartKeys = KeyFactory<typeof digestionPartKeys>;

export function useDigestionParts<TData = DigestionPartListResponse>(
  digestionId: Digestion["id"],
  request: StrictOmit<ListDigestionPartsRequest, "digestionId">,
  options?: UseQueryOptions<
    DigestionPartListResponse,
    unknown,
    TData,
    DigestionPartKeys["list"]
  >,
): UseQueryResult<TData> {
  const { digestionApi } = useDataStoreClients();

  return useQuery({
    queryKey: digestionPartKeys.list(digestionId, request),
    queryFn({ signal }) {
      return digestionApi.listDigestionParts(
        { digestionId, ...request },
        { signal },
      );
    },
    ...options,
  });
}

export function useDigestionPart<TData = DigestionPartDataResponse>(
  digestionId: Digestion["id"],
  digestionPartId: DigestionPart["id"],
  options?: StrictOmit<
    UseQueryOptions<
      DigestionPartDataResponse,
      unknown,
      TData,
      DigestionPartKeys["fetch"]
    >,
    "initialData"
  >,
): UseQueryResult<TData> {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useQuery({
    queryKey: digestionPartKeys.fetch(digestionId, digestionPartId),
    queryFn({ signal }) {
      return digestionApi.fetchDigestionPart(
        { digestionId, digestionPartId },
        { signal },
      );
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        digestionPartKeys.lists(digestionId),
        (digestionPart: DigestionPart) =>
          digestionPart.digestionId === digestionId &&
          digestionPart.id === digestionPartId,
      );
    },
  });
}

export function useCreateDigestionPart(
  digestionId: Digestion["id"],
): UseMutationResult<
  DigestionPartDataResponse,
  unknown,
  DigestionPartCreateRequest
> {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn(request) {
      return digestionApi.createDigestionPart({
        digestionId,
        digestionPartCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: digestionPartKeys.lists(digestionId),
      });
      queryClient.setQueryData<DigestionPartDataResponse>(
        digestionPartKeys.fetch(digestionId, response.data.id),
        response,
      );
    },
  });
}

export function useUpdateDigestionPart(
  digestionId: Digestion["id"],
  digestionPartId: DigestionPart["id"],
): UseMutationResult<
  DigestionPartDataResponse,
  unknown,
  DigestionPartUpdateRequest
> {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn(updates) {
      return digestionApi.updateDigestionPart({
        digestionId,
        digestionPartId,
        digestionPartUpdateRequest: updates,
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: digestionPartKeys.lists(digestionId),
      });
      queryClient.setQueryData<DigestionPartDataResponse>(
        digestionPartKeys.fetch(digestionId, response.data.id),
        response,
      );
    },
  });
}

export function useDeleteDigestionPart(
  digestionId: Digestion["id"],
  digestionPartId: DigestionPart["id"],
): UseMutationResult<void, unknown, void> {
  const queryClient = useQueryClient();

  const { digestionApi } = useDataStoreClients();

  return useMutation({
    mutationFn() {
      return digestionApi.deleteDigestionPart({ digestionId, digestionPartId });
    },
    onSuccess() {
      queryClient.removeQueries({
        queryKey: digestionPartKeys.lists(digestionId),
      });
      scheduleQueriesRemoval(() =>
        queryClient.removeQueries({
          queryKey: digestionPartKeys.fetch(digestionId, digestionPartId),
        }),
      );
    },
  });
}
