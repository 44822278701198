import type { FieldValues } from "react-hook-form";
import type { Path } from "react-router-dom";
import type { OverrideProperties } from "type-fest";
import type { NewResourceFormProps } from "~/components/Form";
import { NewResourceForm } from "~/components/Form";

// TODO: Is this component necessary now that LQS locations don't need special
//  handling separate from DSM locations?
type NewLqsResourceFormProps<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResponse extends object,
> = OverrideProperties<
  NewResourceFormProps<TRequest, TFormValues, TResponse>,
  { createDetailsLocation: (response: TResponse) => Partial<Path> }
>;

export function NewLqsResourceForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResponse extends object,
>({
  createDetailsLocation,
  ...rest
}: NewLqsResourceFormProps<TRequest, TFormValues, TResponse>) {
  return (
    <NewResourceForm {...rest} createDetailsLocation={createDetailsLocation} />
  );
}
