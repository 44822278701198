import type { StrictOmit } from "ts-essentials";
import type { ResourceFieldsProps } from "~/components/DetailsCards";
import { ResourceFields } from "~/components/DetailsCards";
import { renderForeignResource } from "./renderForeignResource";
import type { LqsForeignResourceType } from "./types";

type LqsResourceFieldsProps<TResource extends object> = StrictOmit<
  ResourceFieldsProps<TResource, LqsForeignResourceType>,
  "renderForeignResource"
>;

export function LqsResourceFields<TResource extends object>(
  props: LqsResourceFieldsProps<TResource>,
) {
  return (
    <ResourceFields {...props} renderForeignResource={renderForeignResource} />
  );
}
