import React from "react";
import { Box } from "@mui/material";

export function FullWidthContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <Box sx={{ ml: 8, mr: 8 }}>{children}</Box>;
}
