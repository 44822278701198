import React, { useEffect } from "react";
import { Error as ErrorIcon } from "@mui/icons-material";
import {
  Alert,
  Divider,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Loading } from "~/components/Loading";
import { renderQuery } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import type { LogInstantTag, LogRangeTag } from "~/domain/logs";
import {
  LogTagChip,
  useLogInstantTags,
  useLogLevelTags,
  useLogRangeTags,
} from "~/domain/logs";
import { SidebarHeader } from "~/layout";
import type { Tag } from "~/lqs";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";
import { usePlayerActions } from "../../actions";
import { usePlayerLog, usePlayerParams } from "../../hooks";
import { useFormatPlaybackTimestamp } from "../../playback";
import {
  useAreLogTagsShowing,
  useTagActions,
  useTagMutationsEnabled,
} from "../../tags";
import type { LabelManagementSectionProps } from "./label-management-section";

export function TaggingSidebar({
  renderLabelManagementSection,
}: {
  renderLabelManagementSection?: (
    props: LabelManagementSectionProps,
  ) => React.ReactNode;
}) {
  const readonly = renderLabelManagementSection == null;

  const { logId } = usePlayerParams();
  const playerLogQuery = usePlayerLog();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const areLogTagsShowing = useAreLogTagsShowing();
  const tagMutationsEnabled = useTagMutationsEnabled();
  const { toggleLogTags, toggleTagMutations } = useTagActions();

  useEffect(
    function showTagsWhenOpened() {
      toggleLogTags(true);
    },
    [toggleLogTags],
  );

  function handleToggleTags(): void {
    toggleLogTags();
  }

  const toggleTagMutationsHandlerProps = getEventHandlerProps(
    "onChange",
    !readonly &&
      function handleToggleTagMutations(): void {
        toggleTagMutations();
      },
  );

  const logTagsQuery = useLogLevelTags(playerLogQuery.data);
  const logInstantTagsQuery = useLogInstantTags(logId);
  const logRangeTagsQuery = useLogRangeTags(logId);

  const playerActions = usePlayerActions();

  function createTagClickHandler(tag: LogInstantTag | LogRangeTag) {
    return function handleTagClick(): void {
      playerActions.seek(tag.startTime);
    };
  }

  let content;
  if (logId == null) {
    content = (
      <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
        Choose a log in the log sidebar to begin tagging
      </Alert>
    );
  } else {
    content = renderQuery(playerLogQuery, {
      loading: <Loading type="circular" />,
      error: <ErrorMessage>Unable to load log. Cannot tag.</ErrorMessage>,
      success(log) {
        return (
          <>
            <FormControlLabel
              sx={{ justifyContent: "space-between", ml: 0 }}
              control={
                <Switch
                  checked={areLogTagsShowing}
                  onChange={handleToggleTags}
                />
              }
              label="Show timestamped log tags"
              labelPlacement="start"
            />
            {!readonly && (
              <FormControlLabel
                sx={{ justifyContent: "space-between", ml: 0 }}
                control={
                  <Switch
                    checked={tagMutationsEnabled}
                    onChange={toggleTagMutationsHandlerProps.onChange}
                  />
                }
                label="Enable deleting tags"
                labelPlacement="start"
              />
            )}
            <Divider sx={{ my: 2 }} />
            <Typography variant="overline" gutterBottom>
              Log Tags
            </Typography>
            {renderTagsQuery({
              query: logTagsQuery,
              errorText: "Unable to load log-level tags",
              emptyText: "No log-level tags",
              renderChip: (tag) => (
                <LogTagChip
                  key={tag.id}
                  log={log}
                  type="log-level"
                  tag={tag}
                  readonly={readonly || !tagMutationsEnabled}
                  useHashColor
                />
              ),
            })}
            <Typography variant="overline" gutterBottom>
              Log Instant Tags
            </Typography>
            {renderTagsQuery({
              query: logInstantTagsQuery,
              errorText: "Unable to load log instant tags",
              emptyText: "No log instant tags",
              renderChip: (tag) => (
                <LogTagChip
                  key={tag.id}
                  log={log}
                  type="instant"
                  tag={tag}
                  onClick={createTagClickHandler(tag)}
                  formatTimestamp={formatPlaybackTimestamp}
                  readonly={readonly || !tagMutationsEnabled}
                  useHashColor
                />
              ),
            })}
            <Typography variant="overline" gutterBottom>
              Log Range Tags
            </Typography>
            {renderTagsQuery({
              query: logRangeTagsQuery,
              errorText: "Unable to load log range tags",
              emptyText: "No log range tags",
              renderChip: (tag) => (
                <LogTagChip
                  key={tag.id}
                  log={log}
                  type="range"
                  tag={tag}
                  onClick={createTagClickHandler(tag)}
                  formatTimestamp={formatPlaybackTimestamp}
                  readonly={readonly || !tagMutationsEnabled}
                  useHashColor
                />
              ),
            })}
            {!readonly && (
              <>
                <Divider sx={{ mb: 2 }} />
                {renderLabelManagementSection({
                  log,
                  logLevelTagsQuery: logTagsQuery,
                  logInstantTagsQuery,
                  logRangeTagsQuery,
                })}
              </>
            )}
          </>
        );
      },
    });
  }

  return (
    <>
      <SidebarHeader title="Tagging" />
      <Stack>{content}</Stack>
    </>
  );
}

function renderTagsQuery<TTag extends Tag>({
  query,
  errorText,
  emptyText,
  renderChip,
}: {
  query: UseQueryResult<ReadonlyArray<TTag>>;
  errorText: string;
  emptyText: React.ReactNode;
  renderChip: (tag: TTag) => React.ReactNode;
}) {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        mb: 2,
        flexWrap: query.status === "error" ? "nowrap" : "wrap",
      }}
    >
      {renderQuery(query, {
        loading: [1, 2, 3].map((key) => (
          <Skeleton
            key={key}
            variant="rectangular"
            width="8ch"
            height={32}
            sx={{ borderRadius: 4 }}
          />
        )),
        error: (
          <>
            <ErrorIcon color="error" />
            <Typography>{errorText}</Typography>
          </>
        ),
        success(tags) {
          return tags.length === 0 ? (
            <Typography sx={{ fontStyle: "italic", height: 32 }}>
              {emptyText}
            </Typography>
          ) : (
            tags.map(renderChip)
          );
        },
      })}
    </Stack>
  );
}
