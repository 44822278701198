import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import { NewLqsResourceForm, useCreateWorkflow } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createWorkflowSchema } from "../schemas";

export function WorkflowCreateForm() {
  const lqsNavigator = useLqsNavigator({ toWorkflowDetails: true });

  return (
    <NewLqsResourceForm
      schema={createWorkflowSchema}
      defaultValues={{
        name: null,
        _default: false,
        managed: false,
        disabled: false,
        contextSchema: null,
        note: null,
        context: null,
      }}
      resourceName="workflow"
      mutation={useCreateWorkflow()}
      createDetailsLocation={(response) =>
        lqsNavigator.toWorkflowDetails({ workflowId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <CheckboxField control={control} name="_default" />
          <CheckboxField control={control} name="managed" />
          <CheckboxField control={control} name="disabled" />
          <ObjectField control={control} name="contextSchema" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
