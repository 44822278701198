import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout, LockCard } from "~/components/DetailsCards";
import type { DigestionPart } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteDigestionPart,
  useDigestionPart,
  useUpdateDigestionPart,
} from "~/lqs";
import {
  calculateProcessRefetchInterval,
  Process,
} from "~/lqs/components/process";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";

export function DigestionPartDetails() {
  const { digestionId, digestionPartId } = useTypedParams(
    lqsRoutePaths.DIGESTION_PART_DETAILS,
  );

  const lqsNavigator = useLqsNavigator({
    toDigestionPartEdit: true,
    toDigestionPartTable: true,
  });

  const query = useDigestionPart(digestionId, digestionPartId, {
    select: selectData,
    refetchInterval: calculateProcessRefetchInterval,
  });

  const generalSection = <GeneralSection query={query} />;
  const processSection = (
    <Process
      query={query}
      mutation={useUpdateDigestionPart(digestionId, digestionPartId)}
    />
  );
  const lockSection = (
    <LockCard
      resourceName="digestion part"
      query={query}
      updateMutation={useUpdateDigestionPart(digestionId, digestionPartId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="digestion part"
      query={query}
      editLocation={lqsNavigator.toDigestionPartEdit({
        digestionId,
        digestionPartId,
      })}
      deleteMutation={useDeleteDigestionPart(digestionId, digestionPartId)}
      getReadableName={(digestionPart) => digestionPart.id}
      listLocation={lqsNavigator.toDigestionPartTable({ digestionId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {processSection}
        </>
      }
      secondaryGridColumn={
        <>
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {processSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<DigestionPart> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "digestion",
            accessor: "digestionId",
          },
          { dataType: "number", accessor: "sequence" },
        ]}
      />
    </Card>
  );
}
