import React from "react";
import "@mui/material/types/OverridableComponentAugmentation";
import { createRoot } from "react-dom/client";
import App from "./App";
import { configureAuth, configureGeo, initAppConfig } from "./config";

const container = document.getElementById("root")!;
const root = createRoot(container);

initAppConfig().then(() => {
  configureAuth();
  configureGeo();

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});
