import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import { NewLqsResourceForm, useCreateObjectStore } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createObjectStoreSchema } from "../schemas";

export function ObjectStoreCreateForm() {
  const lqsNavigator = useLqsNavigator({ toObjectStoreDetails: true });

  return (
    <NewLqsResourceForm
      schema={createObjectStoreSchema}
      defaultValues={{
        bucketName: null,
        accessKeyId: null,
        secretAccessKey: null,
        regionName: null,
        endpointUrl: null,
        disabled: false,
        note: null,
        context: null,
      }}
      resourceName="object store"
      mutation={useCreateObjectStore()}
      createDetailsLocation={(response) =>
        lqsNavigator.toObjectStoreDetails({ objectStoreId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="bucketName" required />
          <TextField control={control} name="accessKeyId" />
          <TextField control={control} name="secretAccessKey" />
          <TextField control={control} name="regionName" />
          <TextField control={control} name="endpointUrl" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
