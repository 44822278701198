/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ObjectPartCreateRequest {
  partNumber?: number | null;
  size: number;
}

export function ObjectPartCreateRequestFromJSON(
  json: any,
): ObjectPartCreateRequest {
  return ObjectPartCreateRequestFromJSONTyped(json);
}

export function ObjectPartCreateRequestFromJSONTyped(
  json: any,
): ObjectPartCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    partNumber: json["part_number"],
    size: json["size"],
  };
}

export function ObjectPartCreateRequestToJSON(
  value: ObjectPartCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    part_number: value.partNumber,
    size: value.size,
  };
}
