import React from "react";
import { Settings } from "@mui/icons-material";
import { Container } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "~/components/Helmet";
import { SearchValidationErrorFallback } from "~/components/Table";
import * as Layout from "~/layout";
import {
  FullWidthContainer,
  LayoutStateProvider,
  ScrollableContainer,
  SidebarSwitch,
  SidebarTrigger,
} from "~/layout";
import { SettingsSidebar } from "~/settings";
import { DataStoreFilters } from "./DataStoreFilters";
import {
  DataStoreSearchRequestProvider,
  DataStoreTable,
} from "./DataStoreTable";

export function DataStoresPage() {
  return (
    <>
      <Helmet>
        <title>Search DataStores</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title="Search DataStores"
            actions={
              <SidebarTrigger
                sidebarId="settings"
                title="Settings"
                icon={<Settings />}
              />
            }
          />
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <Layout.Main>
                <ScrollableContainer>
                  <Container fixed>
                    <SearchValidationErrorFallback
                      error={error}
                      resetErrorBoundary={resetErrorBoundary}
                    />
                  </Container>
                </ScrollableContainer>
              </Layout.Main>
            )}
          >
            <DataStoreSearchRequestProvider>
              <Layout.Main>
                <ScrollableContainer>
                  <FullWidthContainer>
                    <DataStoreTable />
                  </FullWidthContainer>
                </ScrollableContainer>
              </Layout.Main>
              <Layout.Sidebar>
                <SidebarSwitch
                  options={[
                    {
                      sidebarId: "settings",
                      element: <SettingsSidebar />,
                    },
                    {
                      sidebarId: "filters",
                      element: <DataStoreFilters />,
                    },
                  ]}
                />
              </Layout.Sidebar>
            </DataStoreSearchRequestProvider>
          </ErrorBoundary>
        </Layout.Root>
      </LayoutStateProvider>
    </>
  );
}
