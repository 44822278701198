/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProcessState } from "./ProcessState";
import {
  ProcessStateFromJSON,
  ProcessStateFromJSONTyped,
  ProcessStateToJSON,
} from "./ProcessState";

export interface DigestionPart {
  locked: boolean;
  lockedBy: string | null;
  lockedAt: Date | null;
  lockToken: string | null;
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  workflowId: string | null;
  workflowContext: object | null;
  state: ProcessState;
  progress: number | null;
  transitionedAt: Date | null;
  error: object | null;
  sequence: number;
  digestionId: string;
  index: Array<any> | null;
  logId: string | null;
  groupId: string | null;
}

export function DigestionPartFromJSON(json: any): DigestionPart {
  return DigestionPartFromJSONTyped(json);
}

export function DigestionPartFromJSONTyped(json: any): DigestionPart {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    lockedBy: json["locked_by"],
    lockedAt: json["locked_at"] === null ? null : new Date(json["locked_at"]),
    lockToken: json["lock_token"],
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    workflowId: json["workflow_id"],
    workflowContext: json["workflow_context"],
    state: ProcessStateFromJSON(json["state"]),
    progress: json["progress"],
    transitionedAt:
      json["transitioned_at"] === null
        ? null
        : new Date(json["transitioned_at"]),
    error: json["error"],
    sequence: json["sequence"],
    digestionId: json["digestion_id"],
    index: json["index"],
    logId: json["log_id"],
    groupId: json["group_id"],
  };
}

export function DigestionPartToJSON(
  value: DigestionPart | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    locked_by: value.lockedBy,
    locked_at: value.lockedAt === null ? null : value.lockedAt.toISOString(),
    lock_token: value.lockToken,
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    workflow_id: value.workflowId,
    workflow_context: value.workflowContext,
    state: ProcessStateToJSON(value.state),
    progress: value.progress,
    transitioned_at:
      value.transitionedAt === null ? null : value.transitionedAt.toISOString(),
    error: value.error,
    sequence: value.sequence,
    digestion_id: value.digestionId,
    index: value.index,
    log_id: value.logId,
    group_id: value.groupId,
  };
}
