import { FuzzyField, TextField, useStudioForm } from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { listGroupsSchema } from "../schemas";
import { useGroupSearchRequest } from "./GroupTable";

export function GroupFilters() {
  const [request, setRequest] = useGroupSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listGroupsSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
