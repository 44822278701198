/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TagUpdateRequest {
  labelId?: string;
  topicId?: string | null;
  note?: string | null;
  context?: object | null;
  startTime?: bigint | null;
  endTime?: bigint | null;
}

export function TagUpdateRequestFromJSON(json: any): TagUpdateRequest {
  return TagUpdateRequestFromJSONTyped(json);
}

export function TagUpdateRequestFromJSONTyped(json: any): TagUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    labelId: json["label_id"],
    topicId: json["topic_id"],
    note: json["note"],
    context: json["context"],
    startTime:
      json["start_time"] === undefined
        ? undefined
        : json["start_time"] === null
          ? null
          : BigInt(json["start_time"]),
    endTime:
      json["end_time"] === undefined
        ? undefined
        : json["end_time"] === null
          ? null
          : BigInt(json["end_time"]),
  };
}

export function TagUpdateRequestToJSON(
  value: TagUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    label_id: value.labelId,
    topic_id: value.topicId,
    note: value.note,
    context: value.context,
    start_time:
      value.startTime === undefined
        ? undefined
        : value.startTime === null
          ? null
          : value.startTime.toString(),
    end_time:
      value.endTime === undefined
        ? undefined
        : value.endTime === null
          ? null
          : value.endTime.toString(),
  };
}
