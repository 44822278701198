import type { StrictOmit } from "ts-essentials";
import { z } from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterBoolean,
  filterNumber,
  filterText,
  filterUuid,
  optionalNumber,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredNumber,
  requiredUuid,
} from "~/domain/common";
import type {
  IngestionCreateRequest,
  IngestionPartCreateRequest,
  IngestionPartUpdateRequest,
  IngestionUpdateRequest,
  ListIngestionPartsRequest,
  ListIngestionsRequest,
} from "~/lqs";
import { filterProcessState, requiredProcessStateSchema } from "~/lqs";

export const listIngestionsSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  logId: filterUuid,
  workflowId: filterUuid,
  workflowIdNull: filterBoolean,
  objectStoreId: filterUuid,
  objectKey: filterText,
  objectKeyLike: filterText,
  noteLike: filterText,
  progressGte: filterNumber,
  progressLte: filterNumber,
  progressNull: filterBoolean,
  state: filterProcessState,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListIngestionsRequest,
    | "contextFilter"
    | "workflowContextFilter"
    | "errorLike"
    | "groupId"
    | "includeCount"
  >
>);

export const createIngestionSchema = z.object({
  name: optionalText,
  logId: requiredUuid,
  objectStoreId: optionalUuid,
  objectKey: optionalText,
  state: requiredProcessStateSchema,
  workflowId: optionalUuid,
  workflowContext: optionalObject,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<IngestionCreateRequest>);

export const editIngestionSchema = z.object({
  name: optionalText,
  objectStoreId: optionalUuid,
  objectKey: optionalText,
  state: requiredProcessStateSchema,
  progress: optionalNumber,
  workflowId: optionalUuid,
  workflowContext: optionalObject,
  error: optionalObject,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<IngestionUpdateRequest>);

export const EDITABLE_INGESTION_FIELDS = editIngestionSchema.keyof().options;

export const listIngestionPartsSchema = z.object({
  id: filterUuid,
  sequence: filterNumber,
  source: filterText,
  workflowId: filterUuid,
  workflowIdNull: filterBoolean,
  state: filterProcessState,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListIngestionPartsRequest,
    "ingestionId" | "groupId" | "logId" | "includeCount"
  >
>);

export const createIngestionPartSchema = z.object({
  sequence: requiredNumber,
  source: optionalText,
  state: requiredProcessStateSchema,
  workflowId: optionalUuid,
  workflowContext: optionalObject,
  locked: boolean,
} satisfies SchemaShape<StrictOmit<IngestionPartCreateRequest, "index">>);

export const editIngestionPartSchema = z.object({
  sequence: requiredNumber,
  source: optionalText,
  state: requiredProcessStateSchema,
  progress: optionalNumber,
  workflowId: optionalUuid,
  workflowContext: optionalObject,
  error: optionalObject,
  locked: boolean,
} satisfies SchemaShape<StrictOmit<IngestionPartUpdateRequest, "index">>);

export const EDITABLE_INGESTION_PART_FIELDS =
  editIngestionPartSchema.keyof().options;
