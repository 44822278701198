/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Statement } from "./Statement";
import {
  StatementFromJSON,
  StatementFromJSONTyped,
  StatementToJSON,
} from "./Statement";

export interface Policy {
  statement: Array<Statement>;
}

export function PolicyFromJSON(json: any): Policy {
  return PolicyFromJSONTyped(json);
}

export function PolicyFromJSONTyped(json: any): Policy {
  if (json == null) {
    return json;
  }

  return {
    statement: (json["statement"] as Array<any>).map(StatementFromJSON),
  };
}

export function PolicyToJSON(value: Policy | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    statement: (value.statement as Array<any>).map(StatementToJSON),
  };
}
