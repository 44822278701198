import { useMemo } from "react";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../playback";
import type { SampleFrequencyValue, TimestepValue } from "../types";
import { SampleFrequency, Timestep } from "../types";
import { useRecordStoreContext } from "./context";
import type { RecordStore } from "./store";
import type {
  PlayerRecord,
  RecordsRequest,
  RecordType,
  StoreSnapshot,
} from "./types";
import { useStoreSnapshot } from "./use-store-snapshot";

interface StoreRequest<TRecordType extends RecordType> {
  recordType: TRecordType;
  topic: Topic;
  timestamp: bigint;
  frequency: SampleFrequencyValue;
  count: number;
}

interface StoreSubscription extends StoreRequest<RecordType> {
  limit: number;
  prefetchBehind: number;
  prefetchAhead: number;
}

export type BasicRecordsSnapshot<TRecordType extends RecordType> =
  StoreSnapshot<Array<PlayerRecord<TRecordType>>, StoreRequest<TRecordType>>;

function createStoreRequest<TRecordType extends RecordType>({
  recordType,
  topic,
  timestamp,
  frequency,
  count,
}: StoreRequest<TRecordType>): RecordsRequest<TRecordType> {
  return {
    recordType,
    topicId: topic.id,
    timestamp,
    count,
    frequency,
  };
}

function subscribeToStore(
  store: RecordStore,
  { limit, prefetchBehind, prefetchAhead, ...storeRequest }: StoreSubscription,
  notify: () => void,
): () => void {
  return store.subscribe({
    ...createStoreRequest(storeRequest),
    topicStartTime: storeRequest.topic.startTime,
    topicEndTime: storeRequest.topic.endTime,
    limit,
    prefetchBehind,
    prefetchAhead,
    notify,
  });
}

function getStoreSnapshot<TRecordType extends RecordType>(
  store: RecordStore,
  request: StoreRequest<TRecordType>,
): BasicRecordsSnapshot<TRecordType> {
  const snapshot = store.getRecords(createStoreRequest(request));

  return {
    request,
    ...snapshot,
  };
}

export interface UseRecordsParameters<TRecordType extends RecordType> {
  recordType: TRecordType;
  topic: Topic;
  count: number;
  timestepOverride?: TimestepValue;
  limit: number;
  prefetchBehind?: number;
  prefetchAhead?: number;
}

export function useRecords<TRecordType extends RecordType>({
  recordType,
  topic,
  timestepOverride,
  count,
  limit,
  prefetchBehind = 0,
  prefetchAhead = 0,
}: UseRecordsParameters<TRecordType>): {
  snapshot: BasicRecordsSnapshot<TRecordType>;
  isPlaceholder: boolean;
} {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const recordStore = useRecordStoreContext();

  const frequency = getFrequencyForTimestep(
    timestepOverride ?? playbackSettings.timestep,
  );

  const { storeRequest, subscribe, getSnapshot } = useMemo(() => {
    const storeRequest: StoreRequest<TRecordType> = {
      recordType,
      topic,
      timestamp: playbackSource.timestamp,
      frequency,
      count,
    };

    return {
      storeRequest,
      subscribe: subscribeToStore.bind(null, recordStore, {
        ...storeRequest,
        limit,
        prefetchBehind,
        prefetchAhead,
      }),
      // Use instantiation expression since TS otherwise doesn't preserve
      // generics when binding a function
      getSnapshot: (getStoreSnapshot<TRecordType>).bind(
        null,
        recordStore,
        storeRequest,
      ),
    };
  }, [
    recordStore,
    recordType,
    topic,
    playbackSource.timestamp,
    frequency,
    count,
    limit,
    prefetchBehind,
    prefetchAhead,
  ]);

  return useStoreSnapshot({
    request: storeRequest,
    subscribe,
    getSnapshot,
  });
}

function getFrequencyForTimestep(
  timestep: TimestepValue,
): SampleFrequencyValue {
  switch (timestep) {
    case Timestep.Second: {
      return SampleFrequency.Second;
    }
    case Timestep.Decisecond: {
      return SampleFrequency.Decisecond;
    }
    default: {
      assertNever(timestep);
    }
  }
}
