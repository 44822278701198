import { ObjectField, TextField, TimestampField } from "~/components/Form";
import {
  EditLqsResourceForm,
  LabelSelectField,
  TopicSelect,
  useTag,
  useUpdateTag,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_TAG_FIELDS, editTagSchema } from "../schemas";

export function TagEditForm() {
  const { logId, tagId } = useTypedParams(lqsRoutePaths.LOG_TAG_EDIT);

  const lqsNavigator = useLqsNavigator({ toLogTagDetails: true });

  return (
    <EditLqsResourceForm
      schema={editTagSchema}
      resourceName="tag"
      query={useTag(logId, tagId, { select: selectData })}
      editableFields={EDITABLE_TAG_FIELDS}
      mutation={useUpdateTag(logId, tagId)}
      detailsLocation={lqsNavigator.toLogTagDetails({ logId, tagId })}
    >
      {(control) => (
        <>
          <LabelSelectField control={control} name="labelId" required />
          <TopicSelect control={control} name="topicId" logId={logId} />
          <TextField control={control} name="note" />
          <TimestampField control={control} name="startTime" />
          <TimestampField control={control} name="endTime" />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
