import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Topic } from "~/lqs";
import { LqsResourceTable, useTopics } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { listTopicsSchema } from "../schemas";

function TopicNameCell({ topic }: { topic: Topic }) {
  const lqsNavigator = useLqsNavigator({ toTopicDetails: true });

  return (
    <TableCell>
      {topic.name}
      <Link
        component={RouterLink}
        to={lqsNavigator.toTopicDetails({ topicId: topic.id })}
      >
        <Typography variant="body2">{topic.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<Topic>> = [
  {
    header: "Topic",
    sortKey: "name",
    renderCell: (topic) => <TopicNameCell topic={topic} />,
  },
  {
    accessor: "associatedTopicId",
    dataType: "foreign-key",
    resourceType: "topic",
    defaultHidden: true,
  },
  {
    accessor: "logId",
    dataType: "foreign-key",
    resourceType: "log",
  },
  {
    accessor: "typeName",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "typeEncoding",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "recordSize",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "recordCount",
    dataType: "number",
    isSortable: true,
  },
  {
    accessor: "objectSize",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "objectCount",
    dataType: "number",
    isSortable: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const [useTopicSearchRequest, TopicSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listTopicsSchema,
  });

export function TopicTable() {
  const [request, setRequest] = useTopicSearchRequest();

  const lqsNavigator = useLqsNavigator({ toTopicCreate: true });

  const searchQuery = useTopics(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="topic"
      resourceCreateLocation={lqsNavigator.toTopicCreate()}
      getRowKey={(topic) => topic.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
