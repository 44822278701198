import React from "react";
import { Search } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField as MuiTextField,
  Tooltip,
} from "@mui/material";
import type { Control } from "react-hook-form";
import type { ValueOf } from "type-fest";
import * as z from "zod";
import { TextField } from "~/components/Form";
import { Pagination } from "~/components/Pagination";
import { useSearchRequest } from "~/components/Table";
import { GroupSelectInput, LabelMultiSelectInput } from "~/lqs";
import type { SearchParamAliases } from "~/utils";
import { createPrefixedAliases, assertNever } from "~/utils";
import {
  filterArray,
  filterCheckbox,
  filterText,
  filterUuid,
  requiredUuid,
} from "../common";

export const LIMIT_OPTIONS = [15, 25, 50];

export const SortOptions = {
  NewestStartTime: "newest-start",
  OldestStartTime: "oldest-start",
  NewestCreated: "newest-created",
  OldestCreated: "oldest-created",
} as const;

export const filterSchema = z.object({
  name: filterText,
});

const baseRequestSchema = filterSchema.extend({
  playable: filterCheckbox(true).catch(true),
  // We're planning to support multiple groups eventually so this is plural
  // to be forward-compatible even though it currently won't accept multiple
  groupIds: filterUuid,
  labelIds: filterArray(requiredUuid),
  limit: z.coerce
    .number()
    .refine((arg) => LIMIT_OPTIONS.includes(arg))
    .catch(LIMIT_OPTIONS[0]),
  offset: z.coerce.number().int().nonnegative().catch(0),
  sort: z.nativeEnum(SortOptions).catch(SortOptions.NewestStartTime),
});

const schemaKeys = baseRequestSchema.keyof().options;

export const requestSchema = baseRequestSchema.transform((arg) => {
  // Offset should be multiple of limit
  const isValidOffset = arg.offset % arg.limit === 0;

  return {
    ...arg,
    offset: isValidOffset ? arg.offset : 0,
  };
});

export function useLogsSearchRequest({ prefix = false } = {}) {
  let aliases: SearchParamAliases<z.infer<typeof requestSchema>> | undefined;
  if (prefix) {
    aliases = createPrefixedAliases("log", schemaKeys);
  }

  return useSearchRequest(requestSchema, aliases);
}

type LogsSearchRequest = ReturnType<typeof useLogsSearchRequest>[0];
type SetLogsSearchRequest = ReturnType<typeof useLogsSearchRequest>[1];

export function NameField({
  control,
}: {
  control: Control<{ name: string | null }>;
}) {
  return (
    <TextField
      control={control}
      name="name"
      size="small"
      noHelperText
      endAdornment={
        <InputAdornment position="end">
          <Tooltip title="Search">
            <IconButton type="submit" edge="end" size="small">
              <Search fontSize="small" />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      }
    />
  );
}

export function SortField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleSortChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setRequest({ sort: e.target.value as any });
  }

  return (
    <MuiTextField
      select
      size="small"
      label="Sort by"
      value={request.sort}
      onChange={handleSortChange}
      sx={{ ml: "auto", width: "19ch", flex: "none" }}
    >
      <MenuItem value={SortOptions.NewestStartTime}>Newest (start)</MenuItem>
      <MenuItem value={SortOptions.OldestStartTime}>Oldest (start)</MenuItem>
      <MenuItem value={SortOptions.NewestCreated}>Newest (created)</MenuItem>
      <MenuItem value={SortOptions.OldestCreated}>Oldest (created)</MenuItem>
    </MuiTextField>
  );
}

export function getSortParams(request: { sort: ValueOf<typeof SortOptions> }) {
  switch (request.sort) {
    case SortOptions.NewestStartTime: {
      return { sort: "desc", order: "start_time" };
    }
    case SortOptions.OldestStartTime: {
      return { sort: "asc", order: "start_time" };
    }
    case SortOptions.NewestCreated: {
      return { sort: "desc", order: "created_at" };
    }
    case SortOptions.OldestCreated: {
      return { sort: "asc", order: "created_at" };
    }
    default: {
      assertNever(request.sort);
    }
  }
}

export function PaginationControls({
  disableJumping,
  logCount,
  request,
  setRequest,
}: {
  disableJumping?: boolean;
  logCount: number;
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleLimitChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setRequest({ limit: Number(e.target.value) });
  }

  function handleOffsetChange(newOffset: number): void {
    setRequest({ offset: newOffset });
  }

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <MuiTextField
        select
        size="small"
        label="Results per page"
        value={request.limit}
        onChange={handleLimitChange}
        sx={{ width: "15ch" }}
      >
        {LIMIT_OPTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </MuiTextField>
      <Pagination
        disableJumping={disableJumping}
        count={logCount}
        limit={request.limit}
        offset={request.offset}
        onChange={handleOffsetChange}
      />
    </Stack>
  );
}

export function PlayableLogsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handlePlayableFilterChange(
    e: React.ChangeEvent<HTMLInputElement>,
  ): void {
    setRequest({ playable: e.target.value === "true" });
  }

  return (
    <MuiTextField
      select
      size="small"
      label="Playable"
      value={String(request.playable)}
      onChange={handlePlayableFilterChange}
      sx={{ width: "18ch" }}
    >
      <MenuItem value="true">Playable only</MenuItem>
      <MenuItem value="false">All logs</MenuItem>
    </MuiTextField>
  );
}

export function GroupsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleChange(newGroupId: string | null): void {
    setRequest({ groupIds: newGroupId });
  }

  return (
    <GroupSelectInput
      name="groupIds"
      label="Group"
      size="small"
      noHelperText
      value={request.groupIds}
      onChange={handleChange}
    />
  );
}

export function LabelsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleChange(newLabelIds: Array<string>): void {
    setRequest({ labelIds: newLabelIds });
  }

  return (
    <LabelMultiSelectInput
      name="labelIds"
      label="Tags"
      size="small"
      noHelperText
      value={request.labelIds}
      onChange={handleChange}
    />
  );
}
