import type React from "react";
import { assertNever } from "~/utils";
import { useAuthenticationStatus } from "./useAuthenticationStatus";

export function AuthenticationGuard({
  pendingFallback,
  unauthenticatedFallback,
  children,
}: {
  pendingFallback: React.ReactNode;
  unauthenticatedFallback: React.ReactNode;
  children: React.ReactNode;
}) {
  const authenticationStatus = useAuthenticationStatus();

  switch (authenticationStatus) {
    case "pending": {
      return pendingFallback;
    }
    case "unauthenticated": {
      return unauthenticatedFallback;
    }
    case "authenticated": {
      return children;
    }
    default: {
      assertNever(authenticationStatus);
    }
  }
}
