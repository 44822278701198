/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Log } from "./Log";
import { LogFromJSON, LogFromJSONTyped, LogToJSON } from "./Log";

export interface LogDataResponse {
  data: Log;
}

export function LogDataResponseFromJSON(json: any): LogDataResponse {
  return LogDataResponseFromJSONTyped(json);
}

export function LogDataResponseFromJSONTyped(json: any): LogDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: LogFromJSON(json["data"]),
  };
}

export function LogDataResponseToJSON(
  value: LogDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: LogToJSON(value.data),
  };
}
