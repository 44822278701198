import {
  CheckboxField,
  ObjectField,
  SelectField,
  TextField,
} from "~/components/Form";
import {
  EditLqsResourceForm,
  processStateOptions,
  processTypeOptions,
  useUpdateWorkflowHook,
  useWorkflowHook,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_WORKFLOW_HOOK_FIELDS,
  editWorkflowHookSchema,
} from "../schemas";

export function WorkflowHookEditForm() {
  const { workflowId, hookId } = useTypedParams(
    lqsRoutePaths.WORKFLOW_HOOK_EDIT,
  );

  const lqsNavigator = useLqsNavigator({ toWorkflowHookDetails: true });

  return (
    <EditLqsResourceForm
      schema={editWorkflowHookSchema}
      resourceName="hook"
      query={useWorkflowHook(workflowId, hookId, { select: selectData })}
      editableFields={EDITABLE_WORKFLOW_HOOK_FIELDS}
      mutation={useUpdateWorkflowHook(workflowId, hookId)}
      detailsLocation={lqsNavigator.toWorkflowHookDetails({
        workflowId,
        hookId,
      })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <SelectField
            control={control}
            name="triggerProcess"
            options={processTypeOptions}
            required
          />
          <SelectField
            control={control}
            name="triggerState"
            options={processStateOptions}
            required
          />
          <TextField control={control} name="uri" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
