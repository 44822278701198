import React from "react";
import { Settings } from "@mui/icons-material";
import { Container } from "@mui/material";
import { Helmet } from "~/components/Helmet";
import * as Layout from "~/layout";
import { SettingsSidebar } from "~/settings";
import { DataStoreEditForm } from "./datastore-edit-form";

export function DataStoreEditPage() {
  return (
    <>
      <Helmet>
        <title>Edit DataStore</title>
      </Helmet>
      <Layout.LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title="Edit DataStore"
            actions={
              <Layout.SidebarTrigger
                sidebarId="settings"
                title="Settings"
                icon={<Settings />}
              />
            }
          />
          <Layout.Main>
            <Layout.ScrollableContainer>
              <Container fixed>
                <DataStoreEditForm />
              </Container>
            </Layout.ScrollableContainer>
          </Layout.Main>
          <Layout.Sidebar>
            <Layout.SidebarSwitch
              options={[
                { sidebarId: "settings", element: <SettingsSidebar /> },
              ]}
            />
          </Layout.Sidebar>
        </Layout.Root>
      </Layout.LayoutStateProvider>
    </>
  );
}
