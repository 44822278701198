import { styled, Typography } from "@mui/material";
import { LOGQS_ICON, LOGQS_LOGO } from "~/links";

const Root = styled("div")({
  "& img": {
    display: "block",
    height: 40,
    width: "auto",
  },
});

export function Logo({
  icon = false,
  alt = "LogQS Icon",
  className = "logqs-logo",
}: {
  icon?: boolean;
  alt?: string;
  className?: string;
}) {
  return (
    <Root
      // Forces react to re-mount the image rather than reusing. When toggling
      // between the icon and full-size images, there won't be a quick flash
      // of the old image when the new one is expected
      key={String(icon)}
      className={className}
    >
      <img src={icon ? LOGQS_ICON : LOGQS_LOGO} alt={alt} />
      {!icon && (
        <Typography
          variant="h6"
          component="p"
          sx={{
            color: "secondary.main",
            textAlign: "center",
          }}
        >
          Studio
        </Typography>
      )}
    </Root>
  );
}
