import React, { useImperativeHandle, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { createSafeContext } from "~/contexts";
import type { Maybe } from "~/types";
import type { InitializedPanelNode } from "../../panels";
import type { CameraActions } from "./ThreeDVisualizationControls";

const [useControlsContext, ControlsContext] = createSafeContext<{
  ref: (
    panelId: InitializedPanelNode["id"],
  ) => (actions: CameraActions | null) => void;
  getControls: (panelId: InitializedPanelNode["id"]) => Maybe<CameraActions>;
}>("ThreeDControls");

export function useManageCameraControlsRef(
  panelId: InitializedPanelNode["id"],
): void {
  const { ref } = useControlsContext();

  const { scene, invalidate } = useThree((state) => ({
    scene: state.scene,
    invalidate: state.invalidate,
  }));

  // 3D vis controls need to perform some imperative actions on the scene which
  // can't easily be expressed with props. Those actions are exposed through
  // this imperative handle.
  useImperativeHandle(
    ref(panelId),
    () => {
      const rotationAxisToMethodName = {
        x: "rotateX",
        y: "rotateY",
        z: "rotateZ",
      } as const;

      return {
        rotate(axis, angleRads) {
          const methodName = rotationAxisToMethodName[axis];

          scene[methodName](angleRads);

          invalidate();
        },
        reset() {
          scene.rotation.set(Math.PI, 0, 0);

          invalidate();
        },
      };
    },
    [scene, invalidate],
  );
}

export function useGetCameraControls(
  panelId: InitializedPanelNode["id"],
): () => Maybe<CameraActions> {
  const { getControls } = useControlsContext();

  return getControls.bind(null, panelId);
}

// A 3D vis' controls need to access the camera actions ref for the
// corresponding three.js scene. Since the controls will be in the player's
// sidebar, the ref needs to be lifted up above the panels and the sidebar.
// This provider maintains a map of refs that are written to by a 3D vis'
// controls and read by the panel controls sidebar if the controlled panel is
// showing a 3D vis.
export function ThreeDControlsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const actionsMap = useRef(
    new Map<InitializedPanelNode["id"], CameraActions>(),
  );

  return (
    <ControlsContext.Provider
      value={{
        ref(panelId) {
          return (actions) => {
            if (actions === null) {
              actionsMap.current.delete(panelId);
            } else {
              actionsMap.current.set(panelId, actions);
            }
          };
        },
        getControls(panelId) {
          return actionsMap.current.get(panelId);
        },
      }}
    >
      {children}
    </ControlsContext.Provider>
  );
}
