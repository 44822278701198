import {
  CheckboxField,
  ObjectField,
  SelectField,
  TextField,
} from "~/components/Form";
import {
  EditLqsResourceForm,
  TopicSelect,
  typeEncodingOptions,
  useTopic,
  useUpdateTopic,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_TOPIC_FIELDS, editTopicSchema } from "../schemas";

export function TopicEditForm() {
  const { topicId } = useTypedParams(lqsRoutePaths.TOPIC_EDIT);

  const lqsNavigator = useLqsNavigator({ toTopicDetails: true });

  return (
    <EditLqsResourceForm
      schema={editTopicSchema}
      resourceName="topic"
      query={useTopic(topicId, { select: selectData })}
      editableFields={EDITABLE_TOPIC_FIELDS}
      mutation={useUpdateTopic(topicId)}
      detailsLocation={lqsNavigator.toTopicDetails({ topicId })}
    >
      {(control, topic) => (
        <>
          <TextField control={control} name="name" required />
          <TopicSelect
            control={control}
            name="associatedTopicId"
            logId={topic.logId}
          />
          <CheckboxField control={control} name="strict" />
          <TextField control={control} name="typeName" />
          <SelectField
            control={control}
            name="typeEncoding"
            options={typeEncodingOptions}
          />
          <TextField control={control} name="typeData" />
          <ObjectField control={control} name="typeSchema" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
