import {
  CheckboxField,
  NumberField,
  ObjectField,
  SelectField,
} from "~/components/Form";
import {
  NewLqsResourceForm,
  ProcessState,
  processStateOptions,
  useCreateDigestionPart,
  WorkflowSelect,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { createDigestionPartSchema } from "../schemas";

export function DigestionPartCreateForm() {
  const { digestionId } = useTypedParams(lqsRoutePaths.DIGESTION_PART_CREATE);

  const lqsNavigator = useLqsNavigator({ toDigestionPartDetails: true });

  return (
    <NewLqsResourceForm
      schema={createDigestionPartSchema}
      defaultValues={{
        sequence: null,
        workflowId: null,
        workflowContext: null,
        state: ProcessState.Ready,
        locked: false,
      }}
      resourceName="digestion part"
      mutation={useCreateDigestionPart(digestionId)}
      createDetailsLocation={(response) =>
        lqsNavigator.toDigestionPartDetails({
          digestionId,
          digestionPartId: response.data.id,
        })
      }
    >
      {(control) => (
        <>
          <NumberField control={control} name="sequence" required />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <CheckboxField control={control} name="locked" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
