import { TextField } from "~/components/Form";
import { EditLqsResourceForm, useLabel, useUpdateLabel } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_LABEL_FIELDS, editLabelSchema } from "../schemas";

export function LabelEditForm() {
  const { labelId } = useTypedParams(lqsRoutePaths.LABEL_EDIT);

  const lqsNavigator = useLqsNavigator({ toLabelDetails: true });

  return (
    <EditLqsResourceForm
      schema={editLabelSchema}
      resourceName="label"
      query={useLabel(labelId, { select: selectData })}
      editableFields={EDITABLE_LABEL_FIELDS}
      mutation={useUpdateLabel(labelId)}
      detailsLocation={lqsNavigator.toLabelDetails({ labelId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="value" required />
          <TextField control={control} name="note" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
