import { z } from "zod";
import {
  MAX_CHART_FIELDS,
  SplitOrientation,
  VisualizationType,
  FlipDirection,
  ClipInsetSide,
  MAX_IMAGE_CONTRAST_PCT,
  MAX_IMAGE_BRIGHTNESS_PCT,
} from "../constants";

/**
 * @file models.ts
 *
 * This module encapsulates all the logic for serializing and deserializing
 * layout profiles kept in web storage to ensure they haven't been corrupted in
 * some way, such as a user editing the stored value. It exposes parsers and
 * types for both the runtime and storage format of the layout profiles, though
 * only the runtime types should be exposed outside the parent module.
 *
 * The runtime types refer to the shape of the data Studio works with in the
 * panel layout feature. It's a tree structure of panels - leaf nodes
 * representing panels on screen the user can interact with - and containers -
 * internal nodes transparent to the user which track the position and size
 * of panels and other containers.
 *
 * The storage types refer to the shape of the data when it's to be kept in
 * web storage. It's also a tree structure but with a different shape for the
 * panel nodes.
 *
 * This module additionally provides logic for migrating previous versions of
 * panel nodes. Prior implementations of this feature treated non-layout-related
 * panel fields as "state" and stored it as its own object on the panel node.
 * The runtime types have since moved away from that implementation but the
 * storage types have remained the same, as migrations aren't strictly necessary
 * to translate between the runtime and storage formats. The migration feature
 * uses several zod schemas representing "versions" of the stored data. Schemas
 * for older versions parse the data to ensure it's valid according to that
 * version's requirements, after which transformers will sequentially migrate
 * the data to the newest version of the storage format, applying default field
 * values when needed. The migration is entirely encapsulated within this
 * module: only the most recent storage format is exposed to sibling modules.
 *
 * The panel nodes have a notion, though not explicitly referenced, of
 * "session-only" fields: fields describing state about a given panel that
 * must persist for the duration of the panel's lifetime but should not be
 * stored. For example, the field describing if the image visualization's
 * controls are visible is considered session-only. These fields can be
 * identified by looking at the transforms between the runtime and storage
 * formats: session-only fields are dropped when transforming to the storage
 * format and given hard-coded default values when transforming to the runtime
 * format.
 */

// Panel visualization schemas

const TopicNameSchema = z.string();
const TopicTypeNameSchema = z.string().nullable();
const FieldsSchema = z.array(z.string()).max(MAX_CHART_FIELDS);
const VisualizationSchema = z.nativeEnum(VisualizationType);
const ImageRotationSchema = z.number();
const ImageFlipDirectionSchema = z.nativeEnum(FlipDirection);
const ClipInsetSchema = z.object({
  side: z.nativeEnum(ClipInsetSide),
  percent: z.number().min(0).max(1),
});

// Parsing and migrating stored panel state

const PersistentPanelStateV1Schema = z.strictObject({
  // Existing v1 objects in storage may not have the version field set so it
  // needs to have a default
  version: z.literal("1").default("1"),
  name: TopicNameSchema,
  messageTypeName: TopicTypeNameSchema,
  fields: FieldsSchema,
  tab: VisualizationSchema,
});

const PersistentPanelStateV2Schema = PersistentPanelStateV1Schema.extend({
  version: z.literal("2"),
  image: z.strictObject({
    rotateDeg: ImageRotationSchema,
  }),
});

function transformV1ToV2(
  v1Data: z.infer<typeof PersistentPanelStateV1Schema>,
): z.infer<typeof PersistentPanelStateV2Schema> {
  return {
    ...v1Data,
    version: "2",
    image: {
      rotateDeg: 0,
    },
  };
}

const V1ToV2Schema = PersistentPanelStateV1Schema.transform(
  transformV1ToV2,
).pipe(PersistentPanelStateV2Schema);

// Extending v1 here but will migrate v2 -> v3
const PersistentPanelStateV3Schema = PersistentPanelStateV1Schema.extend({
  version: z.literal("3"),
  colorizeImage: z.boolean(),
  imageRotationDeg: ImageRotationSchema,
  imageFlipDirection: ImageFlipDirectionSchema.nullable(),
  pointCloudPointSize: z.number().min(0).max(0.1),
});

function transformV2ToV3(
  v2Data: z.infer<typeof PersistentPanelStateV2Schema>,
): z.infer<typeof PersistentPanelStateV3Schema> {
  const { image, ...rest } = v2Data;

  return {
    ...rest,
    version: "3",
    colorizeImage: false,
    imageRotationDeg: image.rotateDeg,
    imageFlipDirection: null,
    pointCloudPointSize: 0.05,
  };
}

const V2ToV3Schema = PersistentPanelStateV2Schema.transform(
  transformV2ToV3,
).pipe(PersistentPanelStateV3Schema);

const PersistentPanelStateV4Schema = PersistentPanelStateV3Schema.extend({
  version: z.literal("4"),
  imageBrightnessPct: z.number().min(0).max(MAX_IMAGE_BRIGHTNESS_PCT),
  imageContrastPct: z.number().min(0).max(MAX_IMAGE_CONTRAST_PCT),
});

function transformV3ToV4(
  v3Data: z.infer<typeof PersistentPanelStateV3Schema>,
): z.infer<typeof PersistentPanelStateV4Schema> {
  return {
    ...v3Data,
    version: "4",
    imageBrightnessPct: 1,
    imageContrastPct: 1,
  };
}

const V3ToV4Schema = PersistentPanelStateV3Schema.transform(
  transformV3ToV4,
).pipe(PersistentPanelStateV4Schema);

// TODO: This isn't sustainable. Need to handle migrations in a cleaner way,
//  maybe outside of zod
const PersistentPanelStateSchema = z.union([
  PersistentPanelStateV4Schema,
  V3ToV4Schema,
  V2ToV3Schema.pipe(V3ToV4Schema),
  V1ToV2Schema.pipe(V2ToV3Schema).pipe(V3ToV4Schema),
]);

// Base node schemas

const NodeIdSchema = z.number();
const ParentNodeIdSchema = z.number().nullable();
const FlexSchema = z.number();

const BasePanelNodeSchema = z.strictObject({
  type: z.literal("panel"),
  id: NodeIdSchema,
  parentId: ParentNodeIdSchema,
  flex: FlexSchema,
});

const BaseContainerNodeSchema = z.strictObject({
  type: z.literal("container"),
  id: NodeIdSchema,
  parentId: ParentNodeIdSchema,
  flex: FlexSchema,
  orientation: z.nativeEnum(SplitOrientation),
});

// Panel nodes schemas

const StoredPanelNodeSchema = BasePanelNodeSchema.extend({
  state: PersistentPanelStateSchema.nullable(),
});

type StoredPanelNode = z.infer<typeof StoredPanelNodeSchema>;

const TopicSelectionConfigSchema = z.object({
  search: z.string().nullable(),
  [VisualizationType.Image]: z.boolean(),
  [VisualizationType.ThreeD]: z.boolean(),
  [VisualizationType.Map]: z.boolean(),
  lastSelected: TopicNameSchema.nullable(),
});

export type TopicSelectionConfig = z.infer<typeof TopicSelectionConfigSchema>;

const BaseRuntimePanelNodeSchema = BasePanelNodeSchema.extend({
  topicSelection: TopicSelectionConfigSchema,
});

const UninitializedRuntimePanelNodeSchema = BaseRuntimePanelNodeSchema.extend({
  isInitialized: z.literal(false),
});

const InitializedRuntimePanelNodeSchema = BaseRuntimePanelNodeSchema.extend({
  isInitialized: z.literal(true),
  topicName: TopicNameSchema,
  topicTypeName: TopicTypeNameSchema,
  visualization: VisualizationSchema,
  fields: FieldsSchema,
  selectedTag: z.string().nullable(),
  imageBrightnessPct: z.number().min(0).max(MAX_IMAGE_BRIGHTNESS_PCT),
  imageContrastPct: z.number().min(0).max(MAX_IMAGE_CONTRAST_PCT),
  colorizeImage: z.boolean(),
  imageRotationDeg: ImageRotationSchema,
  imageFlipDirection: ImageFlipDirectionSchema.nullable(),
  inferenceTopicName: z.string().nullable(),
  lockInferenceTransform: z.boolean(),
  inferenceRotationDeg: ImageRotationSchema,
  inferenceFlipDirection: ImageFlipDirectionSchema.nullable(),
  showDetectionBoundingBoxes: z.boolean(),
  showDetectionClassNames: z.boolean(),
  hiddenObjectClassNames: z.array(z.string()),
  inferenceImageOpacity: z.number().min(0).max(1),
  inferenceImageClipInset: ClipInsetSchema,
  colorizeInferenceImage: z.boolean(),
  pointCloudPointSize: z.number().min(0).max(0.1),
});

const RuntimePanelNodeSchema = z.discriminatedUnion("isInitialized", [
  UninitializedRuntimePanelNodeSchema,
  InitializedRuntimePanelNodeSchema,
]);

type RuntimePanelNode = z.infer<typeof RuntimePanelNodeSchema>;

// Panel node transformations

// Given a parsed panel node in storage format, transform it into an equivalent
// panel node in runtime format.
function storedPanelToRuntimePanel(
  storedPanel: StoredPanelNode,
): RuntimePanelNode {
  const { state, ...baseFields } = storedPanel;

  const topicSelection: TopicSelectionConfig = {
    search: null,
    [VisualizationType.Image]: false,
    [VisualizationType.ThreeD]: false,
    [VisualizationType.Map]: false,
    lastSelected: null,
  };

  if (state === null) {
    return {
      ...baseFields,
      topicSelection,
      isInitialized: false,
    };
  } else {
    return {
      ...baseFields,
      topicSelection,
      isInitialized: true,
      topicName: state.name,
      topicTypeName: state.messageTypeName,
      visualization: state.tab,
      fields: state.fields,
      selectedTag: null,
      imageBrightnessPct: state.imageBrightnessPct,
      imageContrastPct: state.imageContrastPct,
      colorizeImage: state.colorizeImage,
      imageRotationDeg: state.imageRotationDeg,
      imageFlipDirection: state.imageFlipDirection,
      inferenceTopicName: null,
      lockInferenceTransform: true,
      inferenceRotationDeg: 0,
      inferenceFlipDirection: null,
      showDetectionBoundingBoxes: true,
      showDetectionClassNames: true,
      hiddenObjectClassNames: [],
      inferenceImageOpacity: 1,
      inferenceImageClipInset: {
        side: ClipInsetSide.Left,
        percent: 0.5,
      },
      colorizeInferenceImage: false,
      pointCloudPointSize: state.pointCloudPointSize,
    };
  }
}

// Given a parsed panel node in runtime format, transform it into an equivalent
// panel node in storage format.
function runtimePanelToStoredPanel(
  runtimePanel: RuntimePanelNode,
): StoredPanelNode {
  const basePanel = {
    type: runtimePanel.type,
    id: runtimePanel.id,
    parentId: runtimePanel.parentId,
    flex: runtimePanel.flex,
  };

  if (runtimePanel.isInitialized) {
    return {
      ...basePanel,
      state: {
        version: "4",
        name: runtimePanel.topicName,
        messageTypeName: runtimePanel.topicTypeName,
        fields: runtimePanel.fields,
        tab: runtimePanel.visualization,
        imageBrightnessPct: runtimePanel.imageBrightnessPct,
        imageContrastPct: runtimePanel.imageContrastPct,
        colorizeImage: runtimePanel.colorizeImage,
        imageRotationDeg: runtimePanel.imageRotationDeg,
        imageFlipDirection: runtimePanel.imageFlipDirection,
        pointCloudPointSize: runtimePanel.pointCloudPointSize,
      },
    };
  } else {
    return {
      ...basePanel,
      state: null,
    };
  }
}

// Container and layout node schemas

// zod has a concept of schema input and output types, with input essentially
// meaning "the shape of data that would be successfully parsed by the base
// schema" and output meaning "the shape of the parsed data after any
// transformations." If no transformations or refinements are used then there's
// no difference. Honestly, I don't think the input type has any meaningful
// usage but it's a required generic for all schemas. This pattern of creating
// separate input and output generics is necessary for defining lazy schemas
// when transformations are involved.
type StoredContainerNodeInput = z.input<typeof BaseContainerNodeSchema> & {
  firstChild: StoredLayoutNodeInput;
  secondChild: StoredLayoutNodeInput;
};

type StoredLayoutNodeInput =
  | z.input<typeof StoredPanelNodeSchema>
  | StoredContainerNodeInput;

type StoredContainerNodeOutput = z.output<typeof BaseContainerNodeSchema> & {
  firstChild: StoredLayoutNodeOutput;
  secondChild: StoredLayoutNodeOutput;
};

type StoredLayoutNodeOutput =
  | z.output<typeof StoredPanelNodeSchema>
  | StoredContainerNodeOutput;

type RuntimeContainerNode = z.infer<typeof BaseContainerNodeSchema> & {
  firstChild: RuntimeLayoutNode;
  secondChild: RuntimeLayoutNode;
};

type RuntimeLayoutNode =
  | z.infer<typeof RuntimePanelNodeSchema>
  | RuntimeContainerNode;

// The following unions with lazy schemas will parse a tree of panel or
// container nodes in one format and transform it to the other. Adding the
// transformations to the panel nodes in each union does mean there aren't
// schemas which can parse data in their base format (i.e. no parsing runtime
// format and outputting runtime format) but there's currently no need for that.
// When parsing the storage format, the goal is to make sure it's valid and
// immediately convert to runtime format, and vice versa. By applying the
// transformations to the panel node schemas we avoid needing to process the
// tree a second time to transform between formats which is difficult for TS.

const StoredToRuntimeLayoutNodeSchema: z.ZodType<
  RuntimeLayoutNode,
  z.ZodTypeDef,
  StoredLayoutNodeInput
> = z.union([
  StoredPanelNodeSchema.transform(storedPanelToRuntimePanel),
  BaseContainerNodeSchema.extend({
    firstChild: z.lazy(() => StoredToRuntimeLayoutNodeSchema),
    secondChild: z.lazy(() => StoredToRuntimeLayoutNodeSchema),
  }),
]);

const RuntimeToStoredLayoutNodeSchema: z.ZodType<
  StoredLayoutNodeOutput,
  z.ZodTypeDef,
  RuntimeLayoutNode
> = z.union([
  RuntimePanelNodeSchema.transform(runtimePanelToStoredPanel),
  BaseContainerNodeSchema.extend({
    firstChild: z.lazy(() => RuntimeToStoredLayoutNodeSchema),
    secondChild: z.lazy(() => RuntimeToStoredLayoutNodeSchema),
  }),
]);

// Layout profile schemas

const BaseLayoutProfileSchema = z.strictObject({
  name: z.string(),
});

export const StoredToRuntimeLayoutProfileSchema =
  BaseLayoutProfileSchema.extend({
    layout: StoredToRuntimeLayoutNodeSchema,
  });

export const RuntimeProfileArraySchema =
  StoredToRuntimeLayoutProfileSchema.array();

export const RuntimeToStoredLayoutProfileSchema =
  BaseLayoutProfileSchema.extend({
    layout: RuntimeToStoredLayoutNodeSchema,
  });

export const StoredProfileArraySchema =
  RuntimeToStoredLayoutProfileSchema.array();

// Curated exports

export type UninitializedRuntimePanelNode = z.infer<
  typeof UninitializedRuntimePanelNodeSchema
>;

export type InitializedRuntimePanelNode = z.infer<
  typeof InitializedRuntimePanelNodeSchema
>;

export type { RuntimePanelNode, RuntimeContainerNode, RuntimeLayoutNode };

export type RuntimeLayoutProfile = z.infer<
  typeof StoredToRuntimeLayoutProfileSchema
>;

export type RuntimeLayoutProfilesList = z.infer<
  typeof RuntimeProfileArraySchema
>;

export type ClipInset = z.infer<typeof ClipInsetSchema>;
