import {
  BooleanField,
  FuzzyField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { WorkflowSelect } from "~/lqs";
import { listLogQueriesSchema } from "../schemas";
import { useLogQuerySearchRequest } from "./LogQueryTable";

export function LogQueryFilters() {
  const [request, setRequest] = useLogQuerySearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listLogQueriesSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <FuzzyField
        control={control}
        exactName="statement"
        fuzzyName="statementLike"
      />
      <WorkflowSelect control={control} name="workflowId" />
      <BooleanField control={control} name="workflowIdNull" />
      <TextField control={control} name="noteLike" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
