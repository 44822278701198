/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProcessState } from "./ProcessState";
import {
  ProcessStateFromJSON,
  ProcessStateFromJSONTyped,
  ProcessStateToJSON,
} from "./ProcessState";

export interface IngestionUpdateRequest {
  locked?: boolean;
  workflowId?: string | null;
  workflowContext?: object | null;
  state?: ProcessState;
  progress?: number | null;
  error?: object | null;
  name?: string | null;
  objectStoreId?: string | null;
  objectKey?: string | null;
  note?: string | null;
  context?: object | null;
}

export function IngestionUpdateRequestFromJSON(
  json: any,
): IngestionUpdateRequest {
  return IngestionUpdateRequestFromJSONTyped(json);
}

export function IngestionUpdateRequestFromJSONTyped(
  json: any,
): IngestionUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    workflowId: json["workflow_id"],
    workflowContext: json["workflow_context"],
    state:
      json["state"] === undefined
        ? undefined
        : ProcessStateFromJSON(json["state"]),
    progress: json["progress"],
    error: json["error"],
    name: json["name"],
    objectStoreId: json["object_store_id"],
    objectKey: json["object_key"],
    note: json["note"],
    context: json["context"],
  };
}

export function IngestionUpdateRequestToJSON(
  value: IngestionUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    workflow_id: value.workflowId,
    workflow_context: value.workflowContext,
    state: ProcessStateToJSON(value.state),
    progress: value.progress,
    error: value.error,
    name: value.name,
    object_store_id: value.objectStoreId,
    object_key: value.objectKey,
    note: value.note,
    context: value.context,
  };
}
