import { getPjmUrl, getDataStoreUrl, getDsmUrl } from "~/config";
import type { DataStore } from "~/dsm";
import { Configuration as DSMConfiguration } from "~/dsm";
import { getAuthToken } from "~/lib/auth";
import { Configuration as DataStoreConfiguration } from "~/lqs";

export function createDataStoreManagementApiConfiguration(): DSMConfiguration {
  return new DSMConfiguration({
    basePath: getDsmUrl(),
    accessToken: getAuthToken,
  });
}

export function createPjmApiConfiguration(dataStoreId: DataStore["id"]): {
  basePath: string;
  accessToken: () => Promise<string>;
} {
  return {
    basePath: getPjmUrl(dataStoreId),
    accessToken: getAuthToken,
  };
}

export function createDataStoreApiConfiguration(
  dataStore: DataStore,
): DataStoreConfiguration {
  return new DataStoreConfiguration({
    basePath: getDataStoreUrl(dataStore),
    accessToken: getAuthToken,
  });
}
