import { invariant } from "~/lib/invariant";
import type { PlaybackSpeedValue, TimestepValue } from "../types";
import { Timestep } from "../types";

export type TickHandler = () => void;

export type TimerParameters = {
  onTick: TickHandler;
  speed: PlaybackSpeedValue;
  timestep: TimestepValue;
};

export class PlaybackTimer {
  #isSet = false;
  #timerId: ReturnType<typeof setTimeout> | null = null;
  #timerParameters: TimerParameters | null = null;

  #pauseCount = 0;

  set(timerParameters: TimerParameters) {
    this.#isSet = true;
    this.#timerParameters = timerParameters;

    this.#requestPlaybackTimerStart();
  }

  clear() {
    this.#isSet = false;
    this.#timerParameters = null;

    this.#clearPlaybackTimer();
  }

  pause() {
    this.#pauseCount++;

    this.#clearPlaybackTimer();
  }

  unpause() {
    this.#pauseCount--;

    this.#requestPlaybackTimerStart();
  }

  #requestPlaybackTimerStart() {
    if (!this.#isSet) {
      return;
    }

    invariant(this.#timerId === null, "A timer is already active");
    invariant(this.#timerParameters !== null, "No timer parameters");

    if (this.#pauseCount > 0) {
      return;
    }

    const { onTick, speed, timestep } = this.#timerParameters;

    const intervalFrequency = timestep === Timestep.Second ? 1 : 10;

    const intervalMs = Math.floor(1_000 / speed / intervalFrequency);

    this.#timerId = setInterval(onTick, intervalMs);
  }

  #clearPlaybackTimer() {
    if (this.#timerId !== null) {
      clearInterval(this.#timerId);

      this.#timerId = null;
    }
  }
}
