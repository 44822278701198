import { Schedule as Clock, Watch } from "@mui/icons-material";
import { SettingControl, SettingsSidebar } from "~/settings";
import { usePlaybackSettings } from "../playback";

export function PlayerSettingsSidebar() {
  const playbackSettings = usePlaybackSettings();

  return (
    <SettingsSidebar>
      <SettingControl
        title="Timestamp Format"
        value={playbackSettings.displayFormat}
        onChange={playbackSettings.setDisplayFormat}
        options={[
          { value: "elapsed", label: "Elapsed", icon: <Watch /> },
          { value: "original", label: "Original", icon: <Clock /> },
        ]}
      />
    </SettingsSidebar>
  );
}
