/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UserUpdateRequest {
  username?: string;
  roleId?: string | null;
  admin?: boolean;
  disabled?: boolean;
  externalId?: string | null;
  password?: string | null;
}

export function UserUpdateRequestFromJSON(json: any): UserUpdateRequest {
  return UserUpdateRequestFromJSONTyped(json);
}

export function UserUpdateRequestFromJSONTyped(json: any): UserUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    username: json["username"],
    roleId: json["role_id"],
    admin: json["admin"],
    disabled: json["disabled"],
    externalId: json["external_id"],
    password: json["password"],
  };
}

export function UserUpdateRequestToJSON(
  value: UserUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    username: value.username,
    role_id: value.roleId,
    admin: value.admin,
    disabled: value.disabled,
    external_id: value.externalId,
    password: value.password,
  };
}
