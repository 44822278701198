import prettyMilliseconds from "pretty-ms";
import {
  millisecondsToNanoseconds,
  nanosecondsToMilliseconds,
  nanosecondsToSeconds,
  secondsToNanoseconds,
} from "~/lib/dates";
import { floor, pipe } from "~/lib/std";
import type { Log } from "~/lqs";
import { assertNever } from "~/utils";
import type { BoundedLog, TimestepValue } from "./types";
import { Timestep } from "./types";

export interface FormatTimestampOptions extends prettyMilliseconds.Options {
  precision?: number;
  relativeTo?: bigint;
}

export function formatTimestamp(
  timestamp: bigint,
  {
    precision,
    relativeTo = 0n,
    colonNotation = true,
    keepDecimalsOnWholeSeconds = true,
    ...prettyMsOptions
  }: FormatTimestampOptions = {},
): string {
  let relativeStampMs = nanosecondsToMilliseconds(timestamp - relativeTo);

  if (precision !== undefined) {
    relativeStampMs = floor(relativeStampMs, precision);
  }

  return prettyMilliseconds(relativeStampMs, {
    colonNotation,
    keepDecimalsOnWholeSeconds,
    ...prettyMsOptions,
  });
}

export function floorTimestampForTimestep(
  timestamp: bigint,
  timestep: TimestepValue,
): bigint {
  switch (timestep) {
    case Timestep.Second: {
      return pipe(
        timestamp,
        (_) => nanosecondsToSeconds(_, { preserveFraction: false }),
        secondsToNanoseconds,
      );
    }
    case Timestep.Decisecond: {
      return pipe(
        timestamp,
        (_) => nanosecondsToMilliseconds(_, { preserveFraction: false }),
        (_) => floor(_, -2),
        millisecondsToNanoseconds,
      );
    }
    default: {
      assertNever(timestep);
    }
  }
}

export function logIsBounded(log: Log): log is BoundedLog {
  return log.startTime !== null && log.endTime !== null;
}

export function logIsTooLong(log: BoundedLog): boolean {
  const NS_IN_1_WEEK = secondsToNanoseconds(
    86_400 /* seconds/day */ * 7 /* days/week */,
  );

  return log.endTime - log.startTime >= NS_IN_1_WEEK;
}
