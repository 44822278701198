import { QueryClient } from "@tanstack/react-query";

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // Preserve v3 behavior, opting out of smart query tracking (for now).
        // Smart tracking should be done on a case-by-case basis to avoid
        // unnoticed bugs
        notifyOnChangeProps: "all",
        // API clients use `fetch-retry` library to handle refetching
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        // Default staleTime of Infinity for all queries. We deal with too
        // much data to be refetching willy-nilly and I'd prefer to control
        // when refetching happens anyways
        staleTime: Infinity,
      },
    },
  });
}
