import { CheckboxField, TextField } from "~/components/Form";
import { EditLqsResourceForm, useApiKey, useUpdateApiKey } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_API_KEY_FIELDS, editApiKeySchema } from "../schemas";

export function ApiKeyEditForm() {
  const { apiKeyId } = useTypedParams(lqsRoutePaths.API_KEY_EDIT);

  const lqsNavigator = useLqsNavigator({ toApiKeyDetails: true });

  return (
    <EditLqsResourceForm
      schema={editApiKeySchema}
      resourceName="API key"
      query={useApiKey(apiKeyId, { select: selectData })}
      editableFields={EDITABLE_API_KEY_FIELDS}
      mutation={useUpdateApiKey(apiKeyId)}
      detailsLocation={lqsNavigator.toApiKeyDetails({ apiKeyId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <CheckboxField control={control} name="disabled" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
