import { useSyncExternalStore } from "react";
import type { StrictOmit } from "ts-essentials";
import * as z from "zod";

type NotUndefined = Exclude<unknown, undefined>;

interface StorageItemParams<TValue extends NotUndefined> {
  storage: Storage;
  version: number;
  key: string;
  parser: z.ZodType<TValue>;
  defaultValue: TValue;
}

interface VersionedState<TValue extends NotUndefined> {
  version: number;
  value: TValue;
}

type VersionedStateParser<TValue extends NotUndefined> = z.ZodType<
  VersionedState<TValue>
>;

function createVersionedStateParser<TValue extends NotUndefined>(
  version: number,
  valueParser: z.ZodType<TValue>,
): VersionedStateParser<TValue> {
  return z.object({
    version: z.literal(version),
    value: valueParser,
  }) as VersionedStateParser<TValue>;
}

export class StorageItem<const TValue extends NotUndefined> {
  readonly #storage: Storage;

  readonly version: number;
  readonly key: string;
  readonly #defaultValue: TValue;
  #value: TValue | undefined;

  readonly #parser: VersionedStateParser<TValue>;

  readonly #subscribers = new Set<() => void>();

  constructor({
    storage,
    key,
    version,
    parser,
    defaultValue,
  }: StorageItemParams<TValue>) {
    this.#storage = storage;

    this.version = version;
    this.key = key;
    this.#defaultValue = defaultValue;

    this.#parser = createVersionedStateParser(version, parser);
  }

  static usingLocalStorage<TValue>(
    params: StrictOmit<StorageItemParams<TValue>, "storage">,
  ): StorageItem<TValue> {
    return new StorageItem({
      storage: window.localStorage,
      ...params,
    });
  }

  static usingSessionStorage<TValue>(
    params: StrictOmit<StorageItemParams<TValue>, "storage">,
  ): StorageItem<TValue> {
    return new StorageItem({
      storage: window.sessionStorage,
      ...params,
    });
  }

  get = (): TValue => {
    if (this.#value === undefined) {
      const serializedValue = this.#storage.getItem(this.key);

      if (serializedValue === null) {
        this.#value = this.#defaultValue;
      } else {
        try {
          // TODO: If JSON.parse throws then the serialized value was definitely
          //       corrupted somehow. May want to just completely overwrite
          //       what's in storage
          const deserializedValue = JSON.parse(serializedValue);

          // TODO: If the parser throws, the data could be corrupted or it could
          //       be the result of a bad migration (which isn't currently a
          //       feature but probably will be eventually). Not sure if the
          //       value in storage should just be overwritten.
          this.#value = this.#parser.parse(deserializedValue).value;
        } catch (e) {
          console.error(
            "Error attempting to deserialize and parse stored value",
            e,
          );

          // If the value can't be parsed from storage, fall back to the default
          // value without actually overwriting what's in storage right now.
          this.#value = this.#defaultValue;
        }
      }
    }

    return this.#value;
  };

  set = (newValue: TValue): void => {
    this.#value = newValue;

    this.#storage.setItem(
      this.key,
      JSON.stringify({
        version: this.version,
        value: newValue,
      }),
    );

    this.#notify();
  };

  subscribe = (notify: () => void): (() => void) => {
    this.#subscribers.add(notify);

    return () => {
      this.#subscribers.delete(notify);
    };
  };

  #notify(): void {
    this.#subscribers.forEach((notify) => notify());
  }
}

export function useStorageValue<TValue extends NotUndefined>(
  storageItem: StorageItem<TValue>,
): TValue {
  return useSyncExternalStore(storageItem.subscribe, storageItem.get);
}
