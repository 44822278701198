import {
  BooleanField,
  FuzzyField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { listObjectStoresSchema } from "../schemas";
import { useObjectStoreSearchRequest } from "./ObjectStoreTable";

export function ObjectStoreFilters() {
  const [request, setRequest] = useObjectStoreSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listObjectStoresSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <TextField control={control} name="bucketName" />
      <TextField control={control} name="accessKeyId" />
      <TextField control={control} name="regionName" />
      <TextField control={control} name="endpointUrl" />
      <FuzzyField control={control} exactName="note" fuzzyName="noteLike" />
      <BooleanField control={control} name="disabled" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
