import {
  CheckboxField,
  NumberField,
  ObjectField,
  SelectField,
  TextField,
} from "~/components/Form";
import {
  EditLqsResourceForm,
  ObjectStoreSelect,
  processStateOptions,
  useIngestion,
  useUpdateIngestion,
  WorkflowSelect,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_INGESTION_FIELDS, editIngestionSchema } from "../schemas";

export function IngestionEditForm() {
  const { ingestionId } = useTypedParams(lqsRoutePaths.INGESTION_EDIT);

  const lqsNavigator = useLqsNavigator({ toIngestionDetails: true });

  return (
    <EditLqsResourceForm
      schema={editIngestionSchema}
      resourceName="ingestion"
      query={useIngestion(ingestionId, { select: selectData })}
      editableFields={EDITABLE_INGESTION_FIELDS}
      mutation={useUpdateIngestion(ingestionId)}
      detailsLocation={lqsNavigator.toIngestionDetails({ ingestionId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <ObjectStoreSelect control={control} name="objectStoreId" />
          <TextField control={control} name="objectKey" />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <NumberField control={control} name="progress" />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <ObjectField control={control} name="error" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
