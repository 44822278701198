import {
  BooleanField,
  FuzzyField,
  TextField,
  TimestampField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { LabelSelectField, TopicSelect } from "~/lqs";
import { listTagsSchema } from "../schemas";
import { useTagSearchRequest } from "./TagTable";

export function TagFilters() {
  const [request, setRequest] = useTagSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listTagsSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <LabelSelectField control={control} name="labelId" />
      <TopicSelect control={control} name="topicId" />
      <FuzzyField control={control} exactName="note" fuzzyName="noteLike" />
      <TimestampField control={control} name="startTimeGte" />
      <TimestampField control={control} name="startTimeLte" />
      <BooleanField control={control} name="startTimeNull" />
      <TimestampField control={control} name="endTimeGte" />
      <TimestampField control={control} name="endTimeLte" />
      <BooleanField control={control} name="endTimeNull" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
