import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { DigestionPart, LqsColumn } from "~/lqs";
import { LqsResourceTable, useDigestionParts } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { listDigestionPartsSchema } from "../schemas";

function DigestionPartIdCell({
  digestionPart,
}: {
  digestionPart: DigestionPart;
}) {
  const lqsNavigator = useLqsNavigator({ toDigestionPartDetails: true });

  return (
    <TableCell>
      <Link
        component={RouterLink}
        to={lqsNavigator.toDigestionPartDetails({
          digestionId: digestionPart.digestionId,
          digestionPartId: digestionPart.id,
        })}
      >
        <Typography>{digestionPart.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<DigestionPart>> = [
  {
    header: "ID",
    renderCell: (digestionPart) => (
      <DigestionPartIdCell digestionPart={digestionPart} />
    ),
  },
  {
    accessor: "sequence",
    dataType: "number",
    isSortable: true,
  },
  {
    accessor: "state",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "progress",
    dataType: "percent",
    isSortable: true,
  },
  {
    accessor: "transitionedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "workflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
    defaultHidden: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const [
  useDigestionPartSearchRequest,
  DigestionPartSearchRequestProvider,
] = createSearchRequestProvider({
  columns,
  filterSchema: listDigestionPartsSchema,
});

export function DigestionPartTable() {
  const { digestionId } = useTypedParams(lqsRoutePaths.DIGESTION_PART_TABLE);

  const lqsNavigator = useLqsNavigator({ toDigestionPartCreate: true });

  const [request, setRequest] = useDigestionPartSearchRequest();

  const searchQuery = useDigestionParts(
    digestionId,
    preprocessSearchRequest(request),
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="digestion part"
      resourceCreateLocation={lqsNavigator.toDigestionPartCreate({
        digestionId,
      })}
      getRowKey={(digestionPart) => digestionPart.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
