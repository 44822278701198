import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Tag } from "~/lqs";
import { LqsResourceTable, useTags } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { listTagsSchema } from "../schemas";

function TagIdCell({ tag }: { tag: Tag }) {
  const lqsNavigator = useLqsNavigator({ toLogTagDetails: true });

  return (
    <TableCell>
      <Link
        component={RouterLink}
        to={lqsNavigator.toLogTagDetails({ logId: tag.logId, tagId: tag.id })}
      >
        <Typography variant="body2">{tag.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<Tag>> = [
  {
    header: "ID",
    renderCell: (tag) => <TagIdCell tag={tag} />,
  },
  {
    accessor: "labelId",
    dataType: "foreign-key",
    resourceType: "label",
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useTagSearchRequest, TagSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listTagsSchema,
  });

export function TagTable() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_TAG_TABLE);

  const lqsNavigator = useLqsNavigator({ toLogTagCreate: true });

  const [request, setRequest] = useTagSearchRequest();

  const searchQuery = useTags(logId, preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="tag"
      resourceCreateLocation={lqsNavigator.toLogTagCreate({ logId })}
      getRowKey={(tag) => tag.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
