import { useMutation } from "@tanstack/react-query";
import { useImmer } from "use-immer";
import { MultipartUploader } from "~/domain/network";
import type { Group, Log, LogDataResponse } from "~/lqs";
import {
  createObjectPartPresignedUrlFactory,
  ProcessState,
  ResponseError,
  useDataStoreClients,
} from "~/lqs";

export class UploadResponseError extends Error {
  readonly type: UploadErrorType;

  constructor(type: UploadErrorType, responseError: ResponseError) {
    super(type, { cause: responseError });

    this.type = type;
    this.name = this.constructor.name;
  }
}

export type UploadErrorType = "name:duplicate";

export interface UseUploadLogArgs {
  name: Log["name"];
  groupId: Group["id"];
  file: File;
}

export interface MultipartUploadStatus {
  isUploading: boolean;
  progress: number;
  isComplete: boolean;
}

export function useUploadLog() {
  const [uploadStatus, setUploadStatus] = useImmer<MultipartUploadStatus>({
    isUploading: false,
    progress: 0,
    isComplete: false,
  });

  const { ingestionApi, logApi } = useDataStoreClients();

  const mutation = useMutation({
    async mutationFn({ name, groupId, file }: UseUploadLogArgs) {
      let logDataResponse: LogDataResponse;
      try {
        logDataResponse = await logApi.createLog({
          logCreateRequest: { name, groupId },
        });
      } catch (e) {
        if (e instanceof ResponseError && e.response.status === 409) {
          throw new UploadResponseError("name:duplicate", e);
        }

        throw e;
      }

      setUploadStatus((draft) => {
        draft.isUploading = true;
      });

      const {
        data: { id: logId },
      } = logDataResponse;

      // This operation can fail if an object with this key already exists
      // for this log. Since Studio just created the log it's not worth
      // handling uniqueness errors that almost certainly won't happen.
      // If Studio ever supports uploading to an existing log then proper
      // error handling would be necessary.
      const {
        data: { key: objectKey },
      } = await logApi.createLogObject({
        logId,
        objectCreateRequest: {
          key: file.name,
        },
      });

      const multipartUploader = new MultipartUploader({
        blob: file,
        createPartPresignedUrl: createObjectPartPresignedUrlFactory(
          logApi,
          logId,
          objectKey,
        ),
        onUpdate(progress) {
          setUploadStatus((draft) => {
            draft.progress = progress;
          });
        },
      });

      await multipartUploader.start();

      setUploadStatus((draft) => {
        draft.progress = 1;
      });

      await logApi.updateLogObject({
        logId,
        objectKey,
        objectUpdateRequest: {
          uploadState: "complete",
        },
      });

      setUploadStatus((draft) => {
        draft.isComplete = true;
      });

      await ingestionApi.createIngestion({
        ingestionCreateRequest: {
          logId,
          objectKey,
          name: file.name,
          state: ProcessState.Queued,
        },
      });

      return logDataResponse;
    },
    // TODO: Abort upload if an error is thrown
    onError() {},
  });

  return { ...mutation, uploadStatus };
}
