import { FuzzyField, useStudioForm } from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters, UserSelect } from "~/domain/common";
import { listApiKeysSchema } from "../schemas";
import { useApiKeySearchRequest } from "./ApiKeyTable";

export function ApiKeyFilters() {
  const [request, setRequest] = useApiKeySearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listApiKeysSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <UserSelect control={control} name="userId" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
