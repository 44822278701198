import type {
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import type { Path } from "react-router-dom";
import { createSafeContext } from "~/contexts";
import type {
  ListRolesRequest,
  ListUsersRequest,
  MeDataResponse,
  Role,
  RoleDataResponse,
  RoleListResponse,
  User,
  UserCreateRequest,
  UserDataResponse,
  UserListResponse,
  UserUpdateRequest,
} from "~/dsm";

interface CommonResourcesContextValue {
  createCurrentUserQueryOptions: () => Required<
    Pick<UseQueryOptions<MeDataResponse>, "queryKey" | "queryFn">
  >;
  createRoleListQueryOptions: (
    request: ListRolesRequest,
  ) => Required<
    Pick<UseQueryOptions<RoleListResponse>, "queryKey" | "queryFn">
  >;
  createRoleFetchQueryOptions: (
    roleId: Role["id"] | null,
  ) => Required<
    Pick<UseQueryOptions<RoleDataResponse>, "queryKey" | "queryFn">
  >;
  createRoleDetailsLocation?: (params: { roleId: Role["id"] }) => Partial<Path>;
  createUserListQueryOptions: (
    request: ListUsersRequest,
  ) => Required<
    Pick<UseQueryOptions<UserListResponse>, "queryKey" | "queryFn">
  >;
  createUserFetchQueryOptions: (
    userId: User["id"] | null,
  ) => Required<
    Pick<UseQueryOptions<UserDataResponse>, "queryKey" | "queryFn">
  >;
  createUserCreateMutationOptions: () => Required<
    Pick<
      UseMutationOptions<UserDataResponse, unknown, UserCreateRequest>,
      "mutationFn"
    >
  >;
  createUserUpdateMutationOptions: (
    userId: User["id"],
  ) => Required<
    Pick<
      UseMutationOptions<UserDataResponse, unknown, UserUpdateRequest>,
      "mutationFn"
    >
  >;
  createUserDeleteMutationOptions: (
    userId: User["id"],
  ) => Required<Pick<UseMutationOptions<void>, "mutationFn">>;
  userTableLocation: Partial<Path>;
  userCreateLocation: Partial<Path>;
  createUserDetailsLocation: (params: { userId: User["id"] }) => Partial<Path>;
  createUserEditLocation: (params: { userId: User["id"] }) => Partial<Path>;
}

export const [useCommonResourcesContextValue, CommonResourcesContext] =
  createSafeContext<CommonResourcesContextValue>("CommonResources");

export const CommonResourcesContextProvider = CommonResourcesContext.Provider;
