import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import type { DigestionTopic } from "~/lqs";
import {
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteDigestionTopic,
  useDigestionTopic,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";

export function DigestionTopicDetails() {
  const { digestionId, topicId } = useTypedParams(
    lqsRoutePaths.DIGESTION_TOPIC_DETAILS,
  );

  const lqsNavigator = useLqsNavigator({
    toDigestionTopicEdit: true,
    toDigestionTopicTable: true,
  });

  const query = useDigestionTopic(digestionId, topicId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="digestion topic"
      query={query}
      editLocation={lqsNavigator.toDigestionTopicEdit({ digestionId, topicId })}
      deleteMutation={useDeleteDigestionTopic(digestionId, topicId)}
      getReadableName={(digestionTopic) => digestionTopic.id}
      listLocation={lqsNavigator.toDigestionTopicTable({ digestionId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<DigestionTopic> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "digestion",
            accessor: "digestionId",
          },
          {
            dataType: "foreign-key",
            resourceType: "topic",
            accessor: "topicId",
          },
          { dataType: "timestamp", accessor: "startTime" },
          { dataType: "timestamp", accessor: "endTime" },
          { dataType: "number", accessor: "frequency" },
          { dataType: "json", accessor: "queryDataFilter" },
          { dataType: "json", accessor: "contextFilter" },
        ]}
      />
    </Card>
  );
}
