import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Role } from "~/lqs";
import { LqsResourceTable, useRoles } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { listRolesSchema } from "../schemas";

function RoleNameCell({ role }: { role: Role }) {
  const lqsNavigator = useLqsNavigator({ toRoleDetails: true });

  return (
    <TableCell>
      {role.name}
      <Link
        component={RouterLink}
        to={lqsNavigator.toRoleDetails({ roleId: role.id })}
      >
        <Typography variant="body2">{role.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<Role>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell: (role) => <RoleNameCell role={role} />,
  },
  {
    accessor: "_default",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useRoleSearchRequest, RoleSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listRolesSchema,
  });

export function RoleTable() {
  const [request, setRequest] = useRoleSearchRequest();

  const lqsNavigator = useLqsNavigator({ toRoleCreate: true });

  const searchQuery = useRoles(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="role"
      resourceCreateLocation={lqsNavigator.toRoleCreate()}
      getRowKey={(role) => role.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
