import type React from "react";
import { createSafeContext } from "~/contexts";
import { usePlayerTopics } from "../../hooks";
import { usePanelLayoutContext } from "../../panels";
import { usePlaybackSource } from "../../playback";
import { useDigestionFinalizer } from "./digestion-sidebar";
import type { DraftDigestion } from "./useDraftDigestion";
import { useDraftDigestion } from "./useDraftDigestion";

const [useDigestionStateContext, DigestionStateContext] = createSafeContext<
  { draftDigestion: DraftDigestion } & ReturnType<typeof useDigestionFinalizer>
>("DigestionState");

export { useDigestionStateContext };

export function DigestionStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();

  const { layout } = usePanelLayoutContext();

  const draftDigestion = useDraftDigestion({
    playerTopics: topicsQuery.data,
    playerRange: playbackSource.range,
    layout,
  });

  const { createDigestion, workflowId, form } = useDigestionFinalizer(
    draftDigestion.topics,
  );

  return (
    <DigestionStateContext.Provider
      value={{
        draftDigestion,
        createDigestion,
        form,
        workflowId,
      }}
    >
      {children}
    </DigestionStateContext.Provider>
  );
}
