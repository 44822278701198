import React from "react";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { TimerSand } from "mdi-material-ui";
import {
  CartesianGrid,
  Cell,
  ReferenceLine,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import type { ElementOf } from "ts-essentials";
import useResizeObserver from "use-resize-observer";
import { Center } from "~/components/Center";
import { JsonTree } from "~/components/JsonTree";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import {
  millisecondsToNanoseconds,
  nanosecondsToMilliseconds,
  secondsToNanoseconds,
} from "~/lib/dates";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../actions";
import PanelLayout from "../components/PanelLayout";
import { calculateWindowTicks } from "../components/utils";
import { useUpdatePanelBuffering } from "../hooks";
import { calculateRecordWindow, timeRangeToDomain } from "../hooks/utils";
import type { InitializedPanelNode } from "../panels";
import { MAX_CHART_FIELDS, VisualizationType } from "../panels";
import {
  useFormatPlaybackTimestamp,
  useLoadedPlaybackSource,
} from "../playback";
import type { PlayerRecord } from "../record-store";
import { useRecords } from "../record-store";
import { useVisualizationStoreParams } from "./context";

interface TimelineVisualizationProps {
  panel: InitializedPanelNode;
  topic: Topic;
}

type Field = ElementOf<InitializedPanelNode["fields"]>;

const WINDOW_SIZE = secondsToNanoseconds(30);

export default function TimelineVisualization({
  panel,
  topic,
}: TimelineVisualizationProps) {
  const theme = useTheme();

  const { ref, width } = useResizeObserver();

  const playbackSource = useLoadedPlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const storeParams = useVisualizationStoreParams(VisualizationType.Timeline);
  const { snapshot, isPlaceholder } = useRecords({
    recordType: "default",
    topic,
    ...storeParams,
  });
  useUpdatePanelBuffering(snapshot.status === "pending" || isPlaceholder);

  const { fields } = panel;

  const playerActions = usePlayerActions();

  function handleAddField(
    currentRecord: PlayerRecord<"default">,
    field: Field,
  ) {
    playerActions.addChartField(panel, field, currentRecord.data);
  }

  function handleRemoveField(field: Field) {
    playerActions.removeChartField(panel, field);
  }

  function handleGraph() {
    playerActions.chooseVisualization(panel, VisualizationType.Chart);
  }

  let content;
  if (snapshot.status === "pending") {
    content = <Loading type="circular" />;
  } else if (snapshot.status === "rejected") {
    content = (
      <ErrorMessage>An error occurred. Couldn't get timeline data</ErrorMessage>
    );
  } else {
    const currentRecord = snapshot.value.findLast(
      (record) => record.timestamp <= snapshot.request.timestamp,
    );

    let body: React.ReactNode;
    if (currentRecord === undefined) {
      const firstRecord = snapshot.value.at(0);

      body = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p" paragraph>
            No recent message
          </Typography>
          {firstRecord !== undefined && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => playerActions.seek(firstRecord.timestamp)}
            >
              Skip to First Message
            </Button>
          )}
        </Center>
      );
    } else {
      let selection: React.ReactNode;
      if (fields.length > 0) {
        selection = (
          <Stack direction="row" spacing={1}>
            {fields.map((field) => (
              <Chip
                key={field}
                label={field}
                onDelete={() => handleRemoveField(field)}
              />
            ))}
          </Stack>
        );
      } else {
        selection = (
          <Typography paragraph>
            <i>No fields selected</i>
          </Typography>
        );
      }

      body = (
        <Box px={2}>
          <Box mb={2}>
            <Typography paragraph>
              {fields.length} / {MAX_CHART_FIELDS} fields selected
            </Typography>
            <Box mb={2}>{selection}</Box>
            <Button
              disabled={fields.length === 0}
              color="primary"
              variant="contained"
              onClick={handleGraph}
            >
              Graph Fields
            </Button>
          </Box>
          <JsonTree
            src={currentRecord.data}
            onSelect={(path) => handleAddField(currentRecord, path)}
          />
        </Box>
      );
    }

    const recordWindow = calculateRecordWindow(
      WINDOW_SIZE,
      snapshot.request.timestamp,
      playbackSource.bounds,
    );

    content = width !== undefined && (
      <Stack
        spacing={2}
        height={1}
        width={1}
        overflow="hidden"
        position="relative"
      >
        <ScatterChart
          height={75}
          width={width}
          data={snapshot.value}
          // Hide the start and end grid lines that I can't seem to get rid of
          margin={{ left: -1, right: -1 }}
        >
          <CartesianGrid horizontal={false} strokeDasharray="6 3" />
          <XAxis
            dataKey={(record) => nanosecondsToMilliseconds(record.timestamp)}
            type="number"
            allowDataOverflow
            ticks={calculateWindowTicks(
              recordWindow,
              secondsToNanoseconds(5),
              WINDOW_SIZE,
            )}
            tickLine={false}
            tickFormatter={(tick) =>
              formatPlaybackTimestamp(millisecondsToNanoseconds(tick))
            }
            orientation="top"
            domain={timeRangeToDomain(recordWindow)}
          />
          <YAxis
            hide
            domain={[0, 1]}
            dataKey={(record) => (record === currentRecord ? 0.8 : 0.4)}
          />
          {/* This is apparently the way you have to change the point size */}
          <ZAxis range={[150, 150]} />
          <Scatter isAnimationActive={false}>
            {snapshot.value.map((record) => (
              <Cell
                key={record.timestamp}
                stroke="white"
                strokeWidth={1}
                fill={record === currentRecord ? "tomato" : "darkgrey"}
              />
            ))}
          </Scatter>
          <ReferenceLine
            x={nanosecondsToMilliseconds(snapshot.request.timestamp)}
            strokeWidth={2}
            stroke={theme.palette.grey["600"]}
          />
        </ScatterChart>
        <Divider />
        <Box sx={{ flex: 1, minHeight: 0, overflowY: "auto" }}>{body}</Box>
        {isPlaceholder && (
          <Backdrop open sx={{ position: "absolute" }}>
            <CircularProgress />
          </Backdrop>
        )}
      </Stack>
    );
  }

  return <PanelLayout contentRef={ref}>{content}</PanelLayout>;
}
