/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DigestionPart } from "./DigestionPart";
import {
  DigestionPartFromJSON,
  DigestionPartFromJSONTyped,
  DigestionPartToJSON,
} from "./DigestionPart";

export interface DigestionPartDataResponse {
  data: DigestionPart;
}

export function DigestionPartDataResponseFromJSON(
  json: any,
): DigestionPartDataResponse {
  return DigestionPartDataResponseFromJSONTyped(json);
}

export function DigestionPartDataResponseFromJSONTyped(
  json: any,
): DigestionPartDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: DigestionPartFromJSON(json["data"]),
  };
}

export function DigestionPartDataResponseToJSON(
  value: DigestionPartDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: DigestionPartToJSON(value.data),
  };
}
