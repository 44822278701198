/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  LabelCreateRequest,
  LabelDataResponse,
  LabelListResponse,
  LabelUpdateRequest,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LabelCreateRequestFromJSON,
  LabelCreateRequestToJSON,
  LabelDataResponseFromJSON,
  LabelDataResponseToJSON,
  LabelListResponseFromJSON,
  LabelListResponseToJSON,
  LabelUpdateRequestFromJSON,
  LabelUpdateRequestToJSON,
} from "../models";

export interface CreateLabelRequest {
  labelCreateRequest: LabelCreateRequest;
}

export interface DeleteLabelRequest {
  labelId: string;
}

export interface FetchLabelRequest {
  labelId: string;
}

export interface ListLabelsRequest {
  id?: string | null;
  value?: string | null;
  valueLike?: string | null;
  noteLike?: string | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateLabelRequest {
  labelId: string;
  labelUpdateRequest: LabelUpdateRequest;
}

export class LabelApi extends runtime.BaseAPI {
  async createLabel(
    this: LabelApi,
    requestParameters: CreateLabelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LabelDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.labelCreateRequest,
      "labelCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/labels`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LabelCreateRequestToJSON(requestParameters.labelCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return LabelDataResponseFromJSON(jsonValue);
  }

  async deleteLabel(
    this: LabelApi,
    requestParameters: DeleteLabelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.labelId, "labelId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/labels/{label_id}`.replace(
          `{${"label_id"}}`,
          encodeURIComponent(String(requestParameters.labelId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchLabel(
    this: LabelApi,
    requestParameters: FetchLabelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LabelDataResponse> {
    runtime.assertParamIsPresent(requestParameters.labelId, "labelId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/labels/{label_id}`.replace(
          `{${"label_id"}}`,
          encodeURIComponent(String(requestParameters.labelId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return LabelDataResponseFromJSON(jsonValue);
  }

  async listLabels(
    this: LabelApi,
    requestParameters: ListLabelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LabelListResponse> {
    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.value != null) {
      queryParameters["value"] = requestParameters.value;
    }

    if (requestParameters.valueLike != null) {
      queryParameters["value_like"] = requestParameters.valueLike;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/labels`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return LabelListResponseFromJSON(jsonValue);
  }

  async updateLabel(
    this: LabelApi,
    requestParameters: UpdateLabelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LabelDataResponse> {
    runtime.assertParamIsPresent(requestParameters.labelId, "labelId");

    runtime.assertParamIsPresent(
      requestParameters.labelUpdateRequest,
      "labelUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/labels/{label_id}`.replace(
          `{${"label_id"}}`,
          encodeURIComponent(String(requestParameters.labelId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: LabelUpdateRequestToJSON(requestParameters.labelUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return LabelDataResponseFromJSON(jsonValue);
  }
}
