import { NumberField, ObjectField, TimestampField } from "~/components/Form";
import {
  EditLqsResourceForm,
  useDigestionTopic,
  useUpdateDigestionTopic,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import {
  EDITABLE_DIGESTION_TOPIC_FIELDS,
  editDigestionTopicSchema,
} from "../schemas";

export function DigestionTopicEditForm() {
  const { digestionId, topicId } = useTypedParams(
    lqsRoutePaths.DIGESTION_TOPIC_EDIT,
  );

  const lqsNavigator = useLqsNavigator({ toDigestionTopicDetails: true });

  return (
    <EditLqsResourceForm
      schema={editDigestionTopicSchema}
      resourceName="digestion topic"
      query={useDigestionTopic(digestionId, topicId, { select: selectData })}
      editableFields={EDITABLE_DIGESTION_TOPIC_FIELDS}
      mutation={useUpdateDigestionTopic(digestionId, topicId)}
      detailsLocation={lqsNavigator.toDigestionTopicDetails({
        digestionId,
        topicId,
      })}
    >
      {(control) => (
        <>
          <TimestampField control={control} name="startTime" />
          <TimestampField control={control} name="endTime" />
          <NumberField control={control} name="frequency" />
          <ObjectField control={control} name="queryDataFilter" />
          <ObjectField control={control} name="contextFilter" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
