import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { Hook, LqsColumn } from "~/lqs";
import { LqsResourceTable, useWorkflowHooks } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { listWorkflowHooksSchema } from "../schemas";

function WorkflowHookNameCell({ workflowHook }: { workflowHook: Hook }) {
  const lqsNavigator = useLqsNavigator({ toWorkflowHookDetails: true });

  return (
    <TableCell>
      {workflowHook.name ?? "-"}
      <Link
        component={RouterLink}
        to={lqsNavigator.toWorkflowHookDetails({
          workflowId: workflowHook.workflowId,
          hookId: workflowHook.id,
        })}
      >
        <Typography variant="body2">{workflowHook.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<Hook>> = [
  {
    header: "Name",
    sortKey: "name",
    renderCell: (workflowHook) => (
      <WorkflowHookNameCell workflowHook={workflowHook} />
    ),
  },
  {
    accessor: "triggerProcess",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "triggerState",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "uri",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useWorkflowHookSearchRequest, WorkflowHookSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listWorkflowHooksSchema,
  });

export function WorkflowHookTable() {
  const { workflowId } = useTypedParams(lqsRoutePaths.WORKFLOW_HOOK_TABLE);

  const lqsNavigator = useLqsNavigator({ toWorkflowHookCreate: true });

  const [request, setRequest] = useWorkflowHookSearchRequest();

  const searchQuery = useWorkflowHooks(
    workflowId,
    preprocessSearchRequest(request),
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="hook"
      resourceCreateLocation={lqsNavigator.toWorkflowHookCreate({ workflowId })}
      getRowKey={(workflowHook) => workflowHook.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
