/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface QueryCreateRequest {
  name?: string | null;
  note?: string | null;
  context?: object | null;
  statement?: string | null;
  parameters?: object | null;
}

export function QueryCreateRequestFromJSON(json: any): QueryCreateRequest {
  return QueryCreateRequestFromJSONTyped(json);
}

export function QueryCreateRequestFromJSONTyped(json: any): QueryCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    name: json["name"],
    note: json["note"],
    context: json["context"],
    statement: json["statement"],
    parameters: json["parameters"],
  };
}

export function QueryCreateRequestToJSON(
  value: QueryCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value.name,
    note: value.note,
    context: value.context,
    statement: value.statement,
    parameters: value.parameters,
  };
}
