import { useEffect, useState } from "react";
import { checkUserIsAuthenticated } from "~/lib/auth";

export function useAuthenticationStatus() {
  const [authenticationStatus, setAuthenticationStatus] = useState<
    "pending" | "unauthenticated" | "authenticated"
  >("pending");

  useEffect(() => {
    (async () => {
      if (await checkUserIsAuthenticated()) {
        setAuthenticationStatus("authenticated");
      } else {
        setAuthenticationStatus("unauthenticated");
      }
    })();
  }, []);

  return authenticationStatus;
}
