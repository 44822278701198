import type { FormEventHandler } from "react";
import { useState } from "react";
import { Error } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import type { UseMutationResult } from "@tanstack/react-query";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { produce } from "immer";
import { useSnackbar } from "notistack";
import { renderQuery } from "~/components/QueryRenderer";
import { invariant } from "~/lib/invariant";
import type { LogDataResponse } from "~/lqs";
import { logKeys, useDataStoreClients } from "~/lqs";
import { usePlayerParams } from "../hooks";
import type { LayoutProfile } from "../panels";
import {
  useCreateLayoutProfile,
  useDeleteLayoutProfile,
  useLayoutProfiles,
  usePanelLayoutContext,
} from "../panels";
import { RuntimeToStoredLayoutProfileSchema } from "../panels/api/models";

export function ProfileDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const [selected, setSelected] = useState<LayoutProfile["name"] | null>(null);

  const { layout } = usePanelLayoutContext();

  const layoutProfilesQuery = useLayoutProfiles();
  const createLayoutProfile = useCreateLayoutProfile();
  const deleteLayoutProfile = useDeleteLayoutProfile();
  const setDefaultLayoutProfileMutation = useSetDefaultLayoutProfile();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit: FormEventHandler = function handleSubmit(e) {
    e.preventDefault();

    invariant(
      layoutProfilesQuery.isSuccess,
      "Cannot create profile before others have loaded",
    );

    invariant(layout !== null, "Layout cannot be null");

    if (name === "") {
      setError("Name is required");

      return;
    }

    if (layoutProfilesQuery.data.some((profile) => profile.name === name)) {
      setError("A profile with this name already exists");

      return;
    }

    createLayoutProfile.mutate(
      { name, layout },
      {
        onSuccess() {
          enqueueSnackbar("Layout profile created", {
            variant: "success",
          });
        },
        onError() {
          enqueueSnackbar("Unable to create layout profile", {
            variant: "error",
          });
        },
      },
    );
  };

  function handleSetDefaultLayoutProfile(): void {
    invariant(selected !== null, "No profile selected");
    invariant(layoutProfilesQuery.isSuccess, "Haven't fetched layout profiles");

    const profile = layoutProfilesQuery.data.find(
      (profile) => profile.name === selected,
    );
    invariant(profile != null, `No profile found with name "${selected}"`);

    setDefaultLayoutProfileMutation.mutate(
      { profile },
      {
        onSuccess() {
          enqueueSnackbar(`Profile "${selected}" set as log's default`, {
            variant: "success",
          });
        },
        onError() {
          enqueueSnackbar(
            `Unable to set profile "${selected}" as log's default`,
            { variant: "error" },
          );
        },
      },
    );
  }

  function handleDelete() {
    invariant(selected !== null, "Must select a profile to delete");

    deleteLayoutProfile.mutate(selected, {
      onSuccess() {
        setSelected(null);

        enqueueSnackbar(`Deleted profile "${selected}"`, {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("Unable to delete profile", {
          variant: "error",
        });
      },
    });
  }

  return (
    <Dialog
      aria-labelledby="profile-dialog-title"
      fullWidth
      open={open}
      onClose={() => {
        // Don't let user close dialog while request in progress
        if (!(createLayoutProfile.isLoading || deleteLayoutProfile.isLoading)) {
          setOpen(false);
        }
      }}
      TransitionProps={{
        onExited() {
          setName("");
          setError("");
          createLayoutProfile.reset();

          setSelected(null);
          deleteLayoutProfile.reset();
        },
      }}
    >
      <DialogTitle id="profile-dialog-title">
        Manage Your Layout Profiles
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" component="p" gutterBottom>
          Create a New Profile
        </Typography>
        <Typography paragraph>
          Save your current layout in a profile. You'll be able to quickly load
          the layout later for any log.
        </Typography>
        <Stack
          component="form"
          onSubmit={handleSubmit}
          spacing={2}
          sx={{ alignItems: "baseline" }}
        >
          <TextField
            fullWidth
            label="Profile name"
            helperText={error}
            error={Boolean(error)}
            disabled={createLayoutProfile.isLoading}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            loading={createLayoutProfile.isLoading}
            disabled={name === ""}
          >
            Create Profile
          </LoadingButton>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" component="p" gutterBottom>
          Your Profiles
        </Typography>
        <List>
          {renderQuery(layoutProfilesQuery, {
            loading: (
              <ListItem>
                <ListItemText>Fetching your profiles...</ListItemText>
              </ListItem>
            ),
            error: (
              <ListItem>
                <ListItemIcon>
                  <Error color="error" />
                </ListItemIcon>
                <ListItemText>Unable to get your profiles</ListItemText>
              </ListItem>
            ),
            success(data) {
              if (data.length === 0) {
                return (
                  <ListItem>
                    <ListItemText>No profiles</ListItemText>
                  </ListItem>
                );
              } else {
                return data.map((profile) => (
                  <ListItem key={profile.name} disablePadding>
                    <ListItemButton
                      role={undefined}
                      disabled={deleteLayoutProfile.isLoading}
                      selected={profile.name === selected}
                      onClick={() => setSelected(profile.name)}
                    >
                      <ListItemText>{profile.name}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ));
              }
            },
          })}
        </List>
        {layoutProfilesQuery.isSuccess && (
          <>
            {selected === null ? (
              <Typography paragraph sx={{ fontStyle: "italic" }}>
                No profile selected
              </Typography>
            ) : (
              <Typography sx={{ "& span": { fontWeight: "bold" } }} paragraph>
                Selected profile: <span>{selected}</span>
              </Typography>
            )}
            <Stack direction="row" spacing={2}>
              <LoadingButton
                color="primary"
                variant="contained"
                disableElevation
                disabled={selected === null || !layoutProfilesQuery.isSuccess}
                loading={setDefaultLayoutProfileMutation.isLoading}
                onClick={handleSetDefaultLayoutProfile}
              >
                Set as Log's Default
              </LoadingButton>
              <LoadingButton
                color="primary"
                variant="text"
                disableElevation
                disabled={selected === null}
                loading={deleteLayoutProfile.isLoading}
                onClick={handleDelete}
              >
                Delete Profile
              </LoadingButton>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

function useSetDefaultLayoutProfile(): UseMutationResult<
  LogDataResponse,
  unknown,
  { profile: LayoutProfile }
> {
  const { logId } = usePlayerParams();

  const queryClient = useQueryClient();

  const { logApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({ profile }) {
      invariant(logId != null, "Log ID is required");

      const { data: log } = await logApi.fetchLog({ logId });

      const updatedContext = mergeDefaultLayoutIntoContext(
        log.context,
        profile,
      );

      return logApi.updateLog({
        logId,
        logUpdateRequest: {
          context: updatedContext,
        },
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: logKeys.lists(),
        type: "inactive",
      });
      queryClient.setQueryData<LogDataResponse>(logKeys.fetch(logId), response);
      return queryClient.invalidateQueries({ queryKey: logKeys.lists() });
    },
  });
}

function mergeDefaultLayoutIntoContext(
  context: object | null,
  profile: LayoutProfile,
): object {
  return produce(context ?? {}, (draft: any) => {
    // Create the "studio" namespace if it doesn't exist yet.
    draft.studio ??= {};

    // Always overwrite an existing default layout profile if one is set.
    draft.studio.default_layout_profile = profile.name;

    // Create the list of layout profiles if they don't yet exist, then push
    // the new default profile onto the list.
    draft.studio.layout_profiles ??= [];

    // Drop any existing layout profile with the same name as the new default.
    draft.studio.layout_profiles = draft.studio.layout_profiles.filter(
      (existingProfile: any) => existingProfile.name !== profile.name,
    );
    draft.studio.layout_profiles.push(
      // Expected to be in stored form
      RuntimeToStoredLayoutProfileSchema.parse(profile),
    );
  });
}
