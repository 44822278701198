import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { DigestionTopic, LqsColumn } from "~/lqs";
import { LqsResourceTable, useDigestionTopics } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { listDigestionTopicsSchema } from "../schemas";

function DigestionTopicIdCell({
  digestionTopic,
}: {
  digestionTopic: DigestionTopic;
}) {
  const lqsNavigator = useLqsNavigator({ toDigestionTopicDetails: true });

  return (
    <TableCell>
      <Link
        component={RouterLink}
        to={lqsNavigator.toDigestionTopicDetails({
          digestionId: digestionTopic.digestionId,
          topicId: digestionTopic.id,
        })}
      >
        <Typography variant="body2">{digestionTopic.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<DigestionTopic>> = [
  {
    header: "Digestion Topic ID",
    renderCell: (digestionTopic) => (
      <DigestionTopicIdCell digestionTopic={digestionTopic} />
    ),
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "frequency",
    dataType: "number",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [
  useDigestionTopicSearchRequest,
  DigestionTopicSearchRequestProvider,
] = createSearchRequestProvider({
  columns,
  filterSchema: listDigestionTopicsSchema,
});

export function DigestionTopicTable() {
  const { digestionId } = useTypedParams(lqsRoutePaths.DIGESTION_TOPIC_TABLE);

  const lqsNavigator = useLqsNavigator({ toDigestionTopicCreate: true });

  const [request, setRequest] = useDigestionTopicSearchRequest();

  const searchQuery = useDigestionTopics(
    digestionId,
    preprocessSearchRequest(request),
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="topic"
      resourceCreateLocation={lqsNavigator.toDigestionTopicCreate({
        digestionId,
      })}
      getRowKey={(topic) => topic.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
