import React from "react";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  cardHeaderClasses,
} from "@mui/material";

export function Card({
  title,
  action,
  error = false,
  children,
  className,
}: {
  title?: string;
  action?: React.ReactNode;
  error?: boolean;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <MuiCard
      className={className}
      sx={{
        ...(error && {
          border: "1px solid",
          borderColor: (theme) => theme.palette.error.main,
        }),
      }}
    >
      {(title !== undefined || action !== undefined) && (
        <CardHeader
          title={title}
          titleTypographyProps={{ component: "h2" }}
          action={action}
          sx={{
            [`& .${cardHeaderClasses.action}`]: {
              m: 0,
            },
          }}
        />
      )}
      <CardContent>{children}</CardContent>
    </MuiCard>
  );
}
