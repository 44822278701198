import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import { usePlayerActions } from "../actions";
import { usePlayerLog, usePlayerTopics } from "../hooks";
import { Panels, usePanelContext } from "../panels";
import { VisualizationSwitch } from "../visualizations";

export default function TopicSection() {
  const logQuery = usePlayerLog();

  const topicsQuery = usePlayerTopics();

  return (
    <Box flex={1} minHeight={0}>
      {!logQuery.isSuccess || !topicsQuery.isSuccess ? (
        <Loading type="circular">
          <Typography variant="h6" component="p">
            Loading...
          </Typography>
        </Loading>
      ) : (
        <Panels
          element={
            <ErrorBoundary fallback={<PanelErrorFallback />}>
              <VisualizationSwitch topics={topicsQuery.data} />
            </ErrorBoundary>
          }
        />
      )}
    </Box>
  );
}

function PanelErrorFallback() {
  const panel = usePanelContext();

  const playerActions = usePlayerActions();

  const { resetBoundary } = useErrorBoundary();

  function handleCloseOnError() {
    playerActions.closePanel(panel);
    resetBoundary();
  }

  return (
    <ErrorMessage disableTypography>
      <ErrorMessage.Paragraph>
        Unexpected error visualizing data
      </ErrorMessage.Paragraph>
      <Button variant="text" color="primary" onClick={handleCloseOnError}>
        Close panel
      </Button>
    </ErrorMessage>
  );
}
