/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  DataStoreCreateRequest,
  DataStoreDataResponse,
  DataStoreListResponse,
  DataStoreUpdateRequest,
  HTTPValidationError,
} from "../models";
import {
  DataStoreCreateRequestFromJSON,
  DataStoreCreateRequestToJSON,
  DataStoreDataResponseFromJSON,
  DataStoreDataResponseToJSON,
  DataStoreListResponseFromJSON,
  DataStoreListResponseToJSON,
  DataStoreUpdateRequestFromJSON,
  DataStoreUpdateRequestToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models";

export interface CreateDatastoreRequest {
  dataStoreCreateRequest: DataStoreCreateRequest;
}

export interface DeleteDatastoreRequest {
  datastoreId: string;
}

export interface FetchDatastoreRequest {
  datastoreId: string;
}

export interface ListDatastoresRequest {
  id?: string | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  name?: string | null;
  nameLike?: string | null;
  noteLike?: string | null;
  ownerId?: string | null;
  endpoint?: string | null;
  endpointLike?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateDatastoreRequest {
  datastoreId: string;
  dataStoreUpdateRequest: DataStoreUpdateRequest;
}

export class DataStoreApi extends runtime.BaseAPI {
  async createDatastore(
    this: DataStoreApi,
    requestParameters: CreateDatastoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DataStoreDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.dataStoreCreateRequest,
      "dataStoreCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/dataStores`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DataStoreCreateRequestToJSON(
          requestParameters.dataStoreCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DataStoreDataResponseFromJSON(jsonValue);
  }

  async deleteDatastore(
    this: DataStoreApi,
    requestParameters: DeleteDatastoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.datastoreId, "datastoreId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/dataStores/{datastore_id}`.replace(
          `{${"datastore_id"}}`,
          encodeURIComponent(String(requestParameters.datastoreId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchDatastore(
    this: DataStoreApi,
    requestParameters: FetchDatastoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DataStoreDataResponse> {
    runtime.assertParamIsPresent(requestParameters.datastoreId, "datastoreId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/dataStores/{datastore_id}`.replace(
          `{${"datastore_id"}}`,
          encodeURIComponent(String(requestParameters.datastoreId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DataStoreDataResponseFromJSON(jsonValue);
  }

  async listDatastores(
    this: DataStoreApi,
    requestParameters: ListDatastoresRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DataStoreListResponse> {
    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.ownerId != null) {
      queryParameters["owner_id"] = requestParameters.ownerId;
    }

    if (requestParameters.endpoint != null) {
      queryParameters["endpoint"] = requestParameters.endpoint;
    }

    if (requestParameters.endpointLike != null) {
      queryParameters["endpoint_like"] = requestParameters.endpointLike;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/dataStores`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DataStoreListResponseFromJSON(jsonValue);
  }

  async updateDatastore(
    this: DataStoreApi,
    requestParameters: UpdateDatastoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DataStoreDataResponse> {
    runtime.assertParamIsPresent(requestParameters.datastoreId, "datastoreId");

    runtime.assertParamIsPresent(
      requestParameters.dataStoreUpdateRequest,
      "dataStoreUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/dataStores/{datastore_id}`.replace(
          `{${"datastore_id"}}`,
          encodeURIComponent(String(requestParameters.datastoreId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: DataStoreUpdateRequestToJSON(
          requestParameters.dataStoreUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DataStoreDataResponseFromJSON(jsonValue);
  }
}
