import type { RefCallback } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NoPhotography } from "@mui/icons-material";
import type { ButtonBaseActions } from "@mui/material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Dd, Dl, DlGroup, Dt } from "~/components/DescriptionList";
import { useBlobSource } from "~/hooks";
import { secondsToMilliseconds } from "~/lib/dates";
import type { Log } from "~/lqs";
import { usePreviewImages } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { renderDuration, renderRecorded } from "./utils";

export function LogThumbnailCard({
  log,
  dense = false,
  actionRef,
  tags,
}: {
  log: Log;
  dense?: boolean;
  actionRef?: React.Ref<ButtonBaseActions>;
  tags?: React.ReactNode;
}) {
  const lqsNavigator = useLqsNavigator();

  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const [shouldLoad, ref] = useShouldLoadImages();

  const logNameVariant = dense ? "body1" : "h6";
  const logAttributeVariant = dense ? "body2" : "body1";

  const showTags = tags != null && tags !== false;

  let thumbnailWidth: string | number;
  let thumbnailHeight: string | number;
  if (showTags) {
    thumbnailWidth = 175;
    thumbnailHeight = "100%";
  } else {
    thumbnailWidth = "100%";

    if (dense) {
      thumbnailHeight = 175;
    } else {
      thumbnailHeight = 200;
    }
  }

  let logName;
  if (showTags) {
    logName = (
      <Link
        underline="hover"
        variant={logNameVariant}
        component={RouterLink}
        to={lqsNavigator.toPlayer({ logId: log.id })}
        sx={{
          wordBreak: "break-all",
          fontWeight: "bold",
        }}
      >
        {log.name}
      </Link>
    );
  } else {
    logName = (
      <Typography
        variant={logNameVariant}
        component="p"
        sx={{
          wordBreak: "break-all",
          fontWeight: "bold",
        }}
      >
        {log.name}
      </Typography>
    );
  }

  const innerContent = (
    <>
      <Thumbnail
        log={log}
        width={thumbnailWidth}
        height={thumbnailHeight}
        shouldLoad={shouldLoad}
        cycle={hovered || focused}
      />
      <CardContent>
        <Stack spacing={2}>
          {logName}
          <Dl spacing={4}>
            <DlGroup xs={12} md="auto">
              <Dt variant={logAttributeVariant}>Recorded</Dt>
              <Dd variant={logAttributeVariant}>{renderRecorded(log)}</Dd>
            </DlGroup>
            <DlGroup xs={12} md="auto">
              <Dt variant={logAttributeVariant}>Duration</Dt>
              <Dd variant={logAttributeVariant}>{renderDuration(log)}</Dd>
            </DlGroup>
          </Dl>
          {tags}
        </Stack>
      </CardContent>
    </>
  );

  const commonProps = {
    sx: {
      display: "flex",
      flexDirection: showTags ? "row" : "column",
      alignItems: "start",
      ...(showTags && {
        justifyContent: "start",
      }),
    },
    onFocus() {
      setFocused(true);
    },
    onBlur() {
      setFocused(false);
    },
  };

  let content;
  if (showTags) {
    content = <Box {...commonProps}>{innerContent}</Box>;
  } else {
    content = (
      <CardActionArea
        {...commonProps}
        action={actionRef}
        component={RouterLink}
        to={lqsNavigator.toPlayer({ logId: log.id })}
        disableTouchRipple
      >
        {innerContent}
      </CardActionArea>
    );
  }

  return (
    <Card
      ref={ref}
      variant="outlined"
      sx={{
        width: 1,
        bgcolor: "inherit",
        overflow: "hidden",
        // Making this a flex container allows the card action area's children
        // to use relative lengths like "100%"
        display: "flex",
        // Required in Safari for an element with rounded corners to
        // hide its childrens' overflow
        isolation: "isolate",
      }}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      {content}
    </Card>
  );
}

function Thumbnail({
  log,
  width,
  height,
  shouldLoad,
  cycle,
}: {
  log: Log;
  width: number | string;
  height: number | string;
  shouldLoad: boolean;
  cycle: boolean;
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const isLogPlayable = log.startTime !== null && log.endTime !== null;

  const previewImagesQuery = usePreviewImages(log.id, {
    enabled: shouldLoad && isLogPlayable,
    select(thumbnailResults) {
      return thumbnailResults.flatMap((result) =>
        result.blob !== null ? result.blob : [],
      );
    },
  });

  const imgRef = useBlobSource(previewImagesQuery.data?.[currentImageIndex]);

  const numThumbnails = previewImagesQuery.data?.length;
  useEffect(
    function cycleThumbnails() {
      if (!cycle) {
        setCurrentImageIndex(0);
        return;
      }

      if (numThumbnails === undefined || numThumbnails === 0) {
        setCurrentImageIndex(0);
        return;
      }

      const intervalId = setInterval(
        () =>
          setCurrentImageIndex((currIndex) => (currIndex + 1) % numThumbnails),
        secondsToMilliseconds(1),
      );

      return () => {
        clearInterval(intervalId);
      };
    },
    [cycle, numThumbnails],
  );

  let content;
  if (
    previewImagesQuery.status === "error" ||
    !isLogPlayable ||
    numThumbnails === 0
  ) {
    content = (
      <Box
        sx={{
          width,
          height,
          bgcolor: "grey.800",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <NoPhotography fontSize="large" />
      </Box>
    );
  } else if (previewImagesQuery.status === "success") {
    content = <CardMedia ref={imgRef} component="img" sx={{ width, height }} />;
  } else {
    content = <Skeleton variant="rectangular" sx={{ width, height }} />;
  }

  return (
    <Box
      sx={{
        width,
        height,
        display: "flex",
      }}
    >
      {content}
    </Box>
  );
}

function useShouldLoadImages() {
  const [shouldLoad, setShouldLoad] = useState(false);

  const observerRef = useRef<IntersectionObserver | null>(null);
  const observedElementRef = useRef<HTMLElement | null>(null);

  const ref: RefCallback<HTMLElement | null> = useCallback((element) => {
    if (observerRef.current === null) {
      observerRef.current = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;

          if (entry.isIntersecting) {
            // Once lazy loading begins, the target can be unobserved as
            // we no longer care about its intersection status
            observer.unobserve(entry.target);
            observedElementRef.current = null;

            setShouldLoad(true);
          }
        },
        {
          root: document.querySelector("[data-scroll-root]"),
          rootMargin: "0px 0px 400px 0px",
        },
      );
    }

    if (observedElementRef.current !== null) {
      // Stop observing previous element to avoid memory leaks
      observerRef.current.unobserve(observedElementRef.current);
    }

    observedElementRef.current = element;

    if (element !== null) {
      observerRef.current.observe(element);
    }
  }, []);

  return [shouldLoad, ref] as const;
}
