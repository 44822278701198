/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Policy } from "./Policy";
import { PolicyFromJSON, PolicyFromJSONTyped, PolicyToJSON } from "./Policy";

export interface Role {
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  name: string;
  policy: Policy;
  note: string | null;
  disabled: boolean;
  _default: boolean;
  managed: boolean;
}

export function RoleFromJSON(json: any): Role {
  return RoleFromJSONTyped(json);
}

export function RoleFromJSONTyped(json: any): Role {
  if (json == null) {
    return json;
  }

  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    name: json["name"],
    policy: PolicyFromJSON(json["policy"]),
    note: json["note"],
    disabled: json["disabled"],
    _default: json["default"],
    managed: json["managed"],
  };
}

export function RoleToJSON(value: Role | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    name: value.name,
    policy: PolicyToJSON(value.policy),
    note: value.note,
    disabled: value.disabled,
    default: value._default,
    managed: value.managed,
  };
}
