import React, { useState } from "react";
import { Container } from "@mui/material";
import * as Layout from "~/layout";
import { LogMap } from "./log-map";
import { LogThumbnails } from "./log-thumbnails";

export function DashboardSections() {
  const [showLogMap, setShowLogMap] = useState(false);

  function handleCloseLogMap(): void {
    setShowLogMap(false);
  }

  function handleShowLogMap(): void {
    setShowLogMap(true);
  }

  if (showLogMap) {
    return <LogMap onClose={handleCloseLogMap} />;
  } else {
    return (
      <Layout.ScrollableContainer
        // Used for thumbnail cards' IntersectionObservers
        data-scroll-root=""
      >
        <Container fixed>
          <LogThumbnails onOpenMap={handleShowLogMap} />
        </Container>
      </Layout.ScrollableContainer>
    );
  }
}
