import { FuzzyField, TextField, useStudioForm } from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters, UserSelect } from "~/domain/common";
import {
  listDataStoresSchema,
  useDataStoreSearchRequest,
} from "./DataStoreTable";

export function DataStoreFilters() {
  const [request, setRequest] = useDataStoreSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listDataStoresSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <TextField control={control} name="noteLike" label="Note" />
      <UserSelect control={control} name="ownerId" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
