import React from "react";
import { People } from "@mui/icons-material";
import { Database } from "mdi-material-ui";
import { Link as RouterLink } from "react-router-dom";
import {
  GlobalNavigation,
  NavigationLink,
  useIsActivePath,
  useShouldUseMiniDrawerStyles,
} from "~/components/GlobalNavigation";
import { Logo } from "~/components/Logo";
import { AdminGuard } from "~/domain/common";
import { makeStudioHomepageLocation, STUDIO_HOMEPAGE } from "~/paths/paths";
import * as dsmPaths from "./paths";

export function DsmNavigation() {
  const isActivePath = useIsActivePath();
  const shouldUseMiniDrawerStyles = useShouldUseMiniDrawerStyles();

  return (
    <GlobalNavigation
      homepageLink={
        isActivePath(STUDIO_HOMEPAGE, true) ? (
          <Logo alt="LogQS logo" icon={shouldUseMiniDrawerStyles} />
        ) : (
          <RouterLink
            className="homepage-link"
            to={makeStudioHomepageLocation()}
          >
            <Logo alt="Homepage" icon={shouldUseMiniDrawerStyles} />
          </RouterLink>
        )
      }
    >
      <AdminGuard>
        <NavigationLink
          to={dsmPaths.createDsmPath(dsmPaths.DATASTORE_TABLE)}
          Icon={Database}
          text="DataStores"
          path={dsmPaths.DATASTORE_TABLE}
        />
        <NavigationLink
          to={dsmPaths.createDsmPath(dsmPaths.USER_TABLE)}
          Icon={People}
          text="Users"
          path={dsmPaths.USER_TABLE}
        />
      </AdminGuard>
    </GlobalNavigation>
  );
}
