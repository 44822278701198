import React from "react";
import type { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { TextField as MuiTextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";
import { useField } from "./hooks";
import type { BaseInputProps, FieldPropsFromInputProps } from "./types";

interface TextInputProps
  extends BaseInputProps<string | null>,
    Pick<MuiTextFieldProps, "size" | "autoComplete"> {
  disabled?: boolean;
  multiline?: boolean;
  monospace?: boolean;
  endAdornment?: React.ReactNode;
  noHelperText?: boolean;
}

export function TextInput({
  name,
  label = getFieldLabel(name),
  required,
  autoComplete,
  size = "medium",
  disabled = false,
  multiline = false,
  monospace = false,
  noHelperText = false,
  endAdornment,
  value,
  onChange,
  errorMessage,
}: TextInputProps) {
  return (
    <MuiTextField
      fullWidth
      size={size}
      required={required}
      autoComplete={autoComplete}
      disabled={disabled}
      {...(multiline && {
        multiline,
        minRows: 3,
        maxRows: 6,
      })}
      {...(monospace && {
        sx: {
          "& textarea": {
            fontFamily: "monospace",
          },
        },
      })}
      label={label}
      error={errorMessage !== undefined}
      helperText={noHelperText ? undefined : errorMessage ?? " "}
      value={value ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onChange(null);
        } else {
          onChange(e.target.value);
        }
      }}
      InputProps={{
        endAdornment,
      }}
    />
  );
}

export interface TextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
> extends FieldPropsFromInputProps<TFieldValues, TName, TextInputProps> {}

export function TextField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
>({
  control,
  onChange: onChangeProp,
  ...rest
}: TextFieldProps<TFieldValues, TName>) {
  const { value, onChange, errorMessage } = useField({
    control,
    name: rest.name,
  });

  const handleChange: typeof onChange = (value): void => {
    onChange(value);
    onChangeProp?.(value);
  };

  return (
    <TextInput
      {...rest}
      value={value}
      onChange={handleChange}
      errorMessage={errorMessage}
    />
  );
}
