import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { invariant } from "~/lib/invariant";
import { scheduleQueriesRemoval } from "~/utils";
import { DSMQueryClientContext, useDsmContext } from "../provider";
import type {
  ListUsersRequest,
  MeDataResponse,
  User,
  UserCreateRequest,
  UserDataResponse,
  UserListResponse,
  UserUpdateRequest,
} from "../sdk";
import type { DsmMutationOptions, DsmQueryOptions } from "./utils";
import { useDsmQueryClient } from "./utils";

export const userKeys = {
  all: ["users"] as const,
  lists: () => [...userKeys.all, "list"] as const,
  list: (request: ListUsersRequest) => [...userKeys.lists(), request] as const,
  fetches: () => [...userKeys.all, "fetch"] as const,
  fetch: (userId: User["id"] | null) =>
    [...userKeys.fetches(), userId] as const,
};

export function useUsersQueryOptionsFactory(): (
  request: ListUsersRequest,
) => DsmQueryOptions<UserListResponse> {
  const { userApi } = useDsmContext();

  return (request) => ({
    queryKey: userKeys.list(request),
    queryFn({ signal }) {
      return userApi.listUsers(request, { signal });
    },
    context: DSMQueryClientContext,
  });
}

export function useUserQueryOptionsFactory(): (
  userId: User["id"] | null,
) => DsmQueryOptions<UserDataResponse, "enabled"> {
  const { userApi } = useDsmContext();

  return (userId) => {
    const enabled = userId != null;

    return {
      queryKey: userKeys.fetch(userId),
      queryFn({ signal }) {
        invariant(enabled, "User ID not provided");

        return userApi.fetchUser({ userId }, { signal });
      },
      enabled,
      context: DSMQueryClientContext,
    };
  };
}

export function useUser<TData = UserDataResponse>(
  userId: User["id"] | null,
  options?: Pick<UseQueryOptions<UserDataResponse, unknown, TData>, "select">,
): UseQueryResult<TData> {
  const createUserQueryOptions = useUserQueryOptionsFactory();

  return useQuery({
    ...options,
    ...createUserQueryOptions(userId),
  });
}

export function useUserCreateMutationOptionsFactory(): () => DsmMutationOptions<
  UserDataResponse,
  UserCreateRequest,
  "onSuccess"
> {
  const { userApi } = useDsmContext();

  const queryClient = useDsmQueryClient();

  return () => ({
    mutationFn(request) {
      return userApi.createUser({ userCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.removeQueries({ queryKey: userKeys.lists() });
      queryClient.setQueryData(userKeys.fetch(response.data.id), response);
    },
    context: DSMQueryClientContext,
  });
}

export function useUserUpdateMutationOptionsFactory(): (
  userId: User["id"],
) => DsmMutationOptions<UserDataResponse, UserUpdateRequest, "onSuccess"> {
  const { userApi } = useDsmContext();

  const queryClient = useDsmQueryClient();

  return (userId) => ({
    mutationFn(request) {
      return userApi.updateUser({ userId, userUpdateRequest: request });
    },
    onSuccess(response) {
      queryClient.removeQueries({ queryKey: userKeys.lists() });
      queryClient.setQueryData(userKeys.fetch(userId), response);
    },
    context: DSMQueryClientContext,
  });
}

export function useUserDeleteMutationOptionsFactory(): (
  userId: User["id"],
) => DsmMutationOptions<void, void> {
  const { userApi } = useDsmContext();

  const queryClient = useDsmQueryClient();

  return (userId) => ({
    mutationFn() {
      return userApi.deleteUser({ userId });
    },
    onSuccess() {
      queryClient.removeQueries({ queryKey: userKeys.lists() });
      scheduleQueriesRemoval(() =>
        queryClient.removeQueries({ queryKey: userKeys.fetch(userId) }),
      );
    },
    context: DSMQueryClientContext,
  });
}

export function useCurrentUserQueryOptionsFactory(): () => DsmQueryOptions<MeDataResponse> {
  const { userApi } = useDsmContext();

  return () => ({
    queryKey: ["current-user"],
    queryFn({ signal }) {
      return userApi.fetchUserMe({ signal });
    },
    context: DSMQueryClientContext,
  });
}

export function useCurrentDsmUser<TData = MeDataResponse>(
  options?: Pick<UseQueryOptions<MeDataResponse, unknown, TData>, "select">,
): UseQueryResult<TData> {
  const currentUserQueryOptionsFactory = useCurrentUserQueryOptionsFactory();

  return useQuery({
    ...currentUserQueryOptionsFactory(),
    ...options,
  });
}
