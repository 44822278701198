import {
  BooleanField,
  FuzzyField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { listWorkflowsSchema } from "../schemas";
import { useWorkflowSearchRequest } from "./WorkflowTable";

export function WorkflowFilters() {
  const [request, setRequest] = useWorkflowSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listWorkflowsSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <BooleanField control={control} name="_default" />
      <BooleanField control={control} name="managed" />
      <BooleanField control={control} name="disabled" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
