import { z } from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterText,
  filterUuid,
  requiredText,
  requiredUuid,
} from "~/domain/common";
import type {
  APIKeyCreateRequest,
  APIKeyUpdateRequest,
  ListApiKeysRequest,
} from "~/lqs";

export const listApiKeysSchema = z.object({
  name: filterText,
  nameLike: filterText,
  userId: filterUuid,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<ListApiKeysRequest>);

export const createApiKeySchema = z.object({
  name: requiredText,
  userId: requiredUuid,
  disabled: boolean,
} satisfies SchemaShape<APIKeyCreateRequest>);

export const editApiKeySchema = z.object({
  name: requiredText,
  disabled: boolean,
} satisfies SchemaShape<APIKeyUpdateRequest>);

export const EDITABLE_API_KEY_FIELDS = editApiKeySchema.keyof().options;
