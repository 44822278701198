import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import { EditLqsResourceForm, useRole, useUpdateRole } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_ROLE_FIELDS, editRoleSchema } from "../schemas";

export function RoleEditForm() {
  const { roleId } = useTypedParams(lqsRoutePaths.ROLE_EDIT);

  const lqsNavigator = useLqsNavigator({ toRoleDetails: true });

  return (
    <EditLqsResourceForm
      schema={editRoleSchema}
      resourceName="role"
      query={useRole(roleId, { select: selectData })}
      editableFields={EDITABLE_ROLE_FIELDS}
      mutation={useUpdateRole(roleId)}
      detailsLocation={lqsNavigator.toRoleDetails({ roleId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <ObjectField control={control} name="policy" required />
          <CheckboxField control={control} name="_default" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="note" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
