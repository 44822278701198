import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { secondsToMilliseconds } from "~/lib/dates";
import type { ListRecordsRequest, Record, Topic } from "~/lqs";
import { circumventPagination, useDataStoreClients } from "~/lqs";
import { SampleFrequency } from "../types";

export function useAllRecords<TData = Array<Record>>({
  topic,
  enabled,
  select,
}: {
  topic: Topic;
  enabled?: boolean;
  select?: (records: Array<Record>) => TData;
}): UseQueryResult<TData> {
  const { topicApi } = useDataStoreClients();

  return useQuery({
    queryKey: ["all-records", topic.id],
    async queryFn({ signal }) {
      const baseRequest: ListRecordsRequest = {
        topicId: topic.id,
        sort: "asc",
        order: "timestamp",
        frequency: SampleFrequency.Second,
      };

      const { data } = await circumventPagination(
        topicApi.listRecords.bind(topicApi),
        100,
        baseRequest,
        { signal },
      );

      return data;
    },
    select,
    enabled,
    cacheTime: secondsToMilliseconds(20),
  });
}
