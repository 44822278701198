import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Card } from "~/components/Card";
import {
  CheckboxField,
  Form,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { UserSelect } from "~/domain/common";
import { useCreateDataStore } from "../../../api";
import * as dsmPaths from "../../../paths";
import { createDataStoreSchema } from "../schemas";

export function DataStoreCreateForm() {
  const createDataStore = useCreateDataStore();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useStudioForm({
    schema: createDataStoreSchema,
    defaultValues: {
      name: null,
      ownerId: null,
      version: null,
      region: null,
      endpoint: null,
      config: null,
      disabled: false,
      note: null,
      context: null,
    },
    onSubmit(values) {
      createDataStore.mutate(values, {
        onSuccess(response) {
          navigate(
            dsmPaths.createDsmPath(dsmPaths.DATASTORE_DETAILS, {
              dataStoreId: response.data.id,
            }),
          );
        },
        onError() {
          enqueueSnackbar("Unable to create DataStore", {
            variant: "error",
          });
        },
      });
    },
  });

  return (
    <Card>
      <Form
        onSubmit={handleSubmit}
        loading={createDataStore.isLoading}
        submitText="Create"
      >
        <TextField control={control} name="name" required />
        <UserSelect control={control} name="ownerId" />
        <TextField control={control} name="version" />
        <TextField control={control} name="region" />
        <TextField control={control} name="endpoint" />
        <ObjectField control={control} name="config" />
        <CheckboxField control={control} name="disabled" />
        <TextField control={control} name="note" multiline />
        <ObjectField control={control} name="context" />
      </Form>
    </Card>
  );
}
