import { z } from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterBoolean,
  filterText,
  optionalText,
  requiredObject,
  requiredText,
} from "~/domain/common";
import type {
  ListRolesRequest,
  RoleCreateRequest,
  RoleUpdateRequest,
} from "~/lqs";

export const listRolesSchema = z.object({
  name: filterText,
  nameLike: filterText,
  disabled: filterBoolean,
  _default: filterBoolean,
  managed: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<ListRolesRequest>);

export const createRoleSchema = z.object({
  name: requiredText,
  policy: requiredObject,
  note: optionalText,
  _default: boolean,
  managed: boolean,
  disabled: boolean,
} satisfies SchemaShape<RoleCreateRequest>);

export const editRoleSchema = z.object({
  name: requiredText,
  policy: requiredObject,
  note: optionalText,
  _default: boolean,
  disabled: boolean,
} satisfies SchemaShape<RoleUpdateRequest>);

export const EDITABLE_ROLE_FIELDS = editRoleSchema.keyof().options;
