import React from "react";
import { CommonResourcesContextProvider } from "~/domain/common";
import { useLqsNavigator } from "~/paths";
import {
  useCurrentUserQueryOptionsFactory,
  useRoleQueryOptionsFactory,
  useRolesQueryOptionsFactory,
  useUserCreateMutationOptionsFactory,
  useUserDeleteMutationOptionsFactory,
  useUserQueryOptionsFactory,
  useUsersQueryOptionsFactory,
  useUserUpdateMutationOptionsFactory,
} from "./api";

export function LqsCommonResourcesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const lqsNavigator = useLqsNavigator({
    toRoleDetails: true,
    toUserTable: true,
    toUserCreate: true,
    toUserDetails: true,
    toUserEdit: true,
  });

  const createCurrentUserQueryOptions = useCurrentUserQueryOptionsFactory();

  const createRoleListQueryOptions = useRolesQueryOptionsFactory();
  const createRoleFetchQueryOptions = useRoleQueryOptionsFactory();

  const createUserListQueryOptions = useUsersQueryOptionsFactory();
  const createUserFetchQueryOptions = useUserQueryOptionsFactory();
  const createUserCreateMutationOptions = useUserCreateMutationOptionsFactory();
  const createUserUpdateMutationOptions = useUserUpdateMutationOptionsFactory();
  const createUserDeleteMutationOptions = useUserDeleteMutationOptionsFactory();

  return (
    <CommonResourcesContextProvider
      value={{
        createCurrentUserQueryOptions,
        createRoleListQueryOptions,
        createRoleFetchQueryOptions,
        createRoleDetailsLocation: lqsNavigator.toRoleDetails,
        createUserListQueryOptions,
        createUserFetchQueryOptions,
        createUserCreateMutationOptions,
        createUserUpdateMutationOptions,
        createUserDeleteMutationOptions,
        userTableLocation: lqsNavigator.toUserTable(),
        userCreateLocation: lqsNavigator.toUserCreate(),
        createUserDetailsLocation: lqsNavigator.toUserDetails,
        createUserEditLocation: lqsNavigator.toUserEdit,
      }}
    >
      {children}
    </CommonResourcesContextProvider>
  );
}
