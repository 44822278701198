/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  RecordCreateRequest,
  RecordDataResponse,
  RecordListResponse,
  RecordUpdateRequest,
  TopicCreateRequest,
  TopicDataResponse,
  TopicListResponse,
  TopicUpdateRequest,
  TypeEncoding,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  RecordCreateRequestFromJSON,
  RecordCreateRequestToJSON,
  RecordDataResponseFromJSON,
  RecordDataResponseToJSON,
  RecordListResponseFromJSON,
  RecordListResponseToJSON,
  RecordUpdateRequestFromJSON,
  RecordUpdateRequestToJSON,
  TopicCreateRequestFromJSON,
  TopicCreateRequestToJSON,
  TopicDataResponseFromJSON,
  TopicDataResponseToJSON,
  TopicListResponseFromJSON,
  TopicListResponseToJSON,
  TopicUpdateRequestFromJSON,
  TopicUpdateRequestToJSON,
  TypeEncodingFromJSON,
  TypeEncodingToJSON,
} from "../models";

export interface CreateRecordRequest {
  topicId: string;
  recordCreateRequest: RecordCreateRequest;
  lockToken?: string | null;
}

export interface CreateTopicRequest {
  topicCreateRequest: TopicCreateRequest;
  lockToken?: string | null;
}

export interface DeleteRecordRequest {
  timestamp: bigint;
  topicId: string;
  lockToken?: string | null;
}

export interface DeleteTopicRequest {
  topicId: string;
  lockToken?: string | null;
}

export interface FetchRecordRequest {
  timestamp: bigint;
  topicId: string;
  includeAuxiliaryData?: boolean;
  includeRawData?: boolean;
}

export interface FetchTopicRequest {
  topicId: string;
}

export interface ListRecordsRequest {
  topicId: string;
  timestamp?: bigint | null;
  logId?: string | null;
  ingestionId?: string | null;
  workflowId?: string | null;
  workflowIdNull?: boolean | null;
  errorLike?: string | null;
  noteLike?: string | null;
  source?: string | null;
  queryDataFilter?: string | null;
  contextFilter?: string | null;
  altered?: boolean | null;
  frequency?: number | null;
  timestampGt?: bigint | null;
  timestampLt?: bigint | null;
  timestampGte?: bigint | null;
  timestampLte?: bigint | null;
  dataLengthGte?: number | null;
  dataLengthLte?: number | null;
  dataOffsetGte?: number | null;
  dataOffsetLte?: number | null;
  chunkCompression?: string | null;
  chunkOffsetGte?: number | null;
  chunkOffsetLte?: number | null;
  chunkLengthGte?: number | null;
  chunkLengthLte?: number | null;
  includeAuxiliaryData?: boolean | null;
  includeRawData?: boolean | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface ListTopicsRequest {
  id?: string | null;
  logId?: string | null;
  groupId?: string | null;
  name?: string | null;
  nameLike?: string | null;
  associatedTopicId?: string | null;
  latched?: boolean | null;
  strict?: boolean | null;
  locked?: boolean | null;
  contextFilter?: string | null;
  startTimeNull?: boolean | null;
  startTimeGte?: bigint | null;
  startTimeLte?: bigint | null;
  endTimeNull?: boolean | null;
  endTimeGte?: bigint | null;
  endTimeLte?: bigint | null;
  recordSizeGte?: number | null;
  recordSizeLte?: number | null;
  recordCountGte?: number | null;
  recordCountLte?: number | null;
  typeName?: string | null;
  typeNameLike?: string | null;
  typeEncoding?: TypeEncoding | null;
  typeData?: string | null;
  typeDataLike?: string | null;
  typeSchemaFilter?: string | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateRecordRequest {
  timestamp: bigint;
  topicId: string;
  recordUpdateRequest: RecordUpdateRequest;
  lockToken?: string | null;
}

export interface UpdateTopicRequest {
  topicId: string;
  topicUpdateRequest: TopicUpdateRequest;
  lockToken?: string | null;
}

export class TopicApi extends runtime.BaseAPI {
  async createRecord(
    this: TopicApi,
    requestParameters: CreateRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RecordDataResponse> {
    runtime.assertParamIsPresent(requestParameters.topicId, "topicId");

    runtime.assertParamIsPresent(
      requestParameters.recordCreateRequest,
      "recordCreateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RecordCreateRequestToJSON(requestParameters.recordCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return RecordDataResponseFromJSON(jsonValue);
  }

  async createTopic(
    this: TopicApi,
    requestParameters: CreateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TopicDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.topicCreateRequest,
      "topicCreateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/topics`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TopicCreateRequestToJSON(requestParameters.topicCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TopicDataResponseFromJSON(jsonValue);
  }

  async deleteRecord(
    this: TopicApi,
    requestParameters: DeleteRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.timestamp, "timestamp");

    runtime.assertParamIsPresent(requestParameters.topicId, "topicId");

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/topics/{topic_id}/records/{timestamp}`
          .replace(
            `{${"timestamp"}}`,
            encodeURIComponent(String(requestParameters.timestamp)),
          )
          .replace(
            `{${"topic_id"}}`,
            encodeURIComponent(String(requestParameters.topicId)),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async deleteTopic(
    this: TopicApi,
    requestParameters: DeleteTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.topicId, "topicId");

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/topics/{topic_id}`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchRecord(
    this: TopicApi,
    requestParameters: FetchRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RecordDataResponse> {
    runtime.assertParamIsPresent(requestParameters.timestamp, "timestamp");

    runtime.assertParamIsPresent(requestParameters.topicId, "topicId");

    const queryParameters: any = {};

    if (requestParameters.includeAuxiliaryData != null) {
      queryParameters["include_auxiliary_data"] =
        requestParameters.includeAuxiliaryData;
    }

    if (requestParameters.includeRawData != null) {
      queryParameters["include_raw_data"] = requestParameters.includeRawData;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records/{timestamp}`
          .replace(
            `{${"timestamp"}}`,
            encodeURIComponent(String(requestParameters.timestamp)),
          )
          .replace(
            `{${"topic_id"}}`,
            encodeURIComponent(String(requestParameters.topicId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return RecordDataResponseFromJSON(jsonValue);
  }

  async fetchTopic(
    this: TopicApi,
    requestParameters: FetchTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TopicDataResponse> {
    runtime.assertParamIsPresent(requestParameters.topicId, "topicId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TopicDataResponseFromJSON(jsonValue);
  }

  async listRecords(
    this: TopicApi,
    requestParameters: ListRecordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RecordListResponse> {
    runtime.assertParamIsPresent(requestParameters.topicId, "topicId");

    const queryParameters: any = {};

    if (requestParameters.timestamp != null) {
      queryParameters["timestamp"] = requestParameters.timestamp;
    }

    if (requestParameters.logId != null) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.ingestionId != null) {
      queryParameters["ingestion_id"] = requestParameters.ingestionId;
    }

    if (requestParameters.workflowId != null) {
      queryParameters["workflow_id"] = requestParameters.workflowId;
    }

    if (requestParameters.workflowIdNull != null) {
      queryParameters["workflow_id_null"] = requestParameters.workflowIdNull;
    }

    if (requestParameters.errorLike != null) {
      queryParameters["error_like"] = requestParameters.errorLike;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.source != null) {
      queryParameters["source"] = requestParameters.source;
    }

    if (requestParameters.queryDataFilter != null) {
      queryParameters["query_data_filter"] = requestParameters.queryDataFilter;
    }

    if (requestParameters.contextFilter != null) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.altered != null) {
      queryParameters["altered"] = requestParameters.altered;
    }

    if (requestParameters.frequency != null) {
      queryParameters["frequency"] = requestParameters.frequency;
    }

    if (requestParameters.timestampGt != null) {
      queryParameters["timestamp_gt"] = requestParameters.timestampGt;
    }

    if (requestParameters.timestampLt != null) {
      queryParameters["timestamp_lt"] = requestParameters.timestampLt;
    }

    if (requestParameters.timestampGte != null) {
      queryParameters["timestamp_gte"] = requestParameters.timestampGte;
    }

    if (requestParameters.timestampLte != null) {
      queryParameters["timestamp_lte"] = requestParameters.timestampLte;
    }

    if (requestParameters.dataLengthGte != null) {
      queryParameters["data_length_gte"] = requestParameters.dataLengthGte;
    }

    if (requestParameters.dataLengthLte != null) {
      queryParameters["data_length_lte"] = requestParameters.dataLengthLte;
    }

    if (requestParameters.dataOffsetGte != null) {
      queryParameters["data_offset_gte"] = requestParameters.dataOffsetGte;
    }

    if (requestParameters.dataOffsetLte != null) {
      queryParameters["data_offset_lte"] = requestParameters.dataOffsetLte;
    }

    if (requestParameters.chunkCompression != null) {
      queryParameters["chunk_compression"] = requestParameters.chunkCompression;
    }

    if (requestParameters.chunkOffsetGte != null) {
      queryParameters["chunk_offset_gte"] = requestParameters.chunkOffsetGte;
    }

    if (requestParameters.chunkOffsetLte != null) {
      queryParameters["chunk_offset_lte"] = requestParameters.chunkOffsetLte;
    }

    if (requestParameters.chunkLengthGte != null) {
      queryParameters["chunk_length_gte"] = requestParameters.chunkLengthGte;
    }

    if (requestParameters.chunkLengthLte != null) {
      queryParameters["chunk_length_lte"] = requestParameters.chunkLengthLte;
    }

    if (requestParameters.includeAuxiliaryData != null) {
      queryParameters["include_auxiliary_data"] =
        requestParameters.includeAuxiliaryData;
    }

    if (requestParameters.includeRawData != null) {
      queryParameters["include_raw_data"] = requestParameters.includeRawData;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return RecordListResponseFromJSON(jsonValue);
  }

  async listTopics(
    this: TopicApi,
    requestParameters: ListTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TopicListResponse> {
    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.logId != null) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.groupId != null) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.associatedTopicId != null) {
      queryParameters["associated_topic_id"] =
        requestParameters.associatedTopicId;
    }

    if (requestParameters.latched != null) {
      queryParameters["latched"] = requestParameters.latched;
    }

    if (requestParameters.strict != null) {
      queryParameters["strict"] = requestParameters.strict;
    }

    if (requestParameters.locked != null) {
      queryParameters["locked"] = requestParameters.locked;
    }

    if (requestParameters.contextFilter != null) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.startTimeNull != null) {
      queryParameters["start_time_null"] = requestParameters.startTimeNull;
    }

    if (requestParameters.startTimeGte != null) {
      queryParameters["start_time_gte"] = requestParameters.startTimeGte;
    }

    if (requestParameters.startTimeLte != null) {
      queryParameters["start_time_lte"] = requestParameters.startTimeLte;
    }

    if (requestParameters.endTimeNull != null) {
      queryParameters["end_time_null"] = requestParameters.endTimeNull;
    }

    if (requestParameters.endTimeGte != null) {
      queryParameters["end_time_gte"] = requestParameters.endTimeGte;
    }

    if (requestParameters.endTimeLte != null) {
      queryParameters["end_time_lte"] = requestParameters.endTimeLte;
    }

    if (requestParameters.recordSizeGte != null) {
      queryParameters["record_size_gte"] = requestParameters.recordSizeGte;
    }

    if (requestParameters.recordSizeLte != null) {
      queryParameters["record_size_lte"] = requestParameters.recordSizeLte;
    }

    if (requestParameters.recordCountGte != null) {
      queryParameters["record_count_gte"] = requestParameters.recordCountGte;
    }

    if (requestParameters.recordCountLte != null) {
      queryParameters["record_count_lte"] = requestParameters.recordCountLte;
    }

    if (requestParameters.typeName != null) {
      queryParameters["type_name"] = requestParameters.typeName;
    }

    if (requestParameters.typeNameLike != null) {
      queryParameters["type_name_like"] = requestParameters.typeNameLike;
    }

    if (requestParameters.typeEncoding != null) {
      queryParameters["type_encoding"] = requestParameters.typeEncoding;
    }

    if (requestParameters.typeData != null) {
      queryParameters["type_data"] = requestParameters.typeData;
    }

    if (requestParameters.typeDataLike != null) {
      queryParameters["type_data_like"] = requestParameters.typeDataLike;
    }

    if (requestParameters.typeSchemaFilter != null) {
      queryParameters["type_schema_filter"] =
        requestParameters.typeSchemaFilter;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/topics`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TopicListResponseFromJSON(jsonValue);
  }

  async updateRecord(
    this: TopicApi,
    requestParameters: UpdateRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RecordDataResponse> {
    runtime.assertParamIsPresent(requestParameters.timestamp, "timestamp");

    runtime.assertParamIsPresent(requestParameters.topicId, "topicId");

    runtime.assertParamIsPresent(
      requestParameters.recordUpdateRequest,
      "recordUpdateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records/{timestamp}`
          .replace(
            `{${"timestamp"}}`,
            encodeURIComponent(String(requestParameters.timestamp)),
          )
          .replace(
            `{${"topic_id"}}`,
            encodeURIComponent(String(requestParameters.topicId)),
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: RecordUpdateRequestToJSON(requestParameters.recordUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return RecordDataResponseFromJSON(jsonValue);
  }

  async updateTopic(
    this: TopicApi,
    requestParameters: UpdateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TopicDataResponse> {
    runtime.assertParamIsPresent(requestParameters.topicId, "topicId");

    runtime.assertParamIsPresent(
      requestParameters.topicUpdateRequest,
      "topicUpdateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: TopicUpdateRequestToJSON(requestParameters.topicUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TopicDataResponseFromJSON(jsonValue);
  }
}
