import {
  CheckboxField,
  ObjectField,
  SelectField,
  TextField,
} from "~/components/Form";
import {
  LogSelect,
  NewLqsResourceForm,
  ProcessState,
  processStateOptions,
  useCreateDigestion,
  WorkflowSelect,
} from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createDigestionSchema } from "../schemas";

export function DigestionCreateForm() {
  const lqsNavigator = useLqsNavigator({ toDigestionDetails: true });

  return (
    <NewLqsResourceForm
      schema={createDigestionSchema}
      defaultValues={{
        name: null,
        logId: null,
        state: ProcessState.Ready,
        workflowId: null,
        workflowContext: null,
        locked: false,
        note: null,
        context: null,
      }}
      resourceName="digestion"
      mutation={useCreateDigestion()}
      createDetailsLocation={(response) =>
        lqsNavigator.toDigestionDetails({ digestionId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" />
          <LogSelect control={control} name="logId" required />
          <SelectField
            control={control}
            name="state"
            options={processStateOptions}
            required
          />
          <WorkflowSelect control={control} name="workflowId" />
          <ObjectField control={control} name="workflowContext" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
