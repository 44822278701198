import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Card } from "~/components/Card";
import {
  CheckboxField,
  Form,
  getChangedFields,
  ObjectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { Loading } from "~/components/Loading";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { UserSelect } from "~/domain/common";
import { pick } from "~/lib/std";
import { selectData } from "~/utils";
import { useDataStore, useUpdateDataStore } from "../../../api";
import * as dsmPaths from "../../../paths";
import type { DataStore } from "../../../sdk";
import { editDataStoreSchema } from "../schemas";

export function DataStoreEditForm() {
  const { dataStoreId } = dsmPaths.useDsmPathParams(dsmPaths.DATASTORE_EDIT);

  const dataStoreQuery = useDataStore(dataStoreId, { select: selectData });

  return (
    <Card>
      <QueryRenderer
        query={dataStoreQuery}
        loading={<Loading type="circular" />}
        error={<ErrorMessage>Error fetching DataStore</ErrorMessage>}
        success={(dataStore) => <DataStoreEditFormImpl dataStore={dataStore} />}
      />
    </Card>
  );
}

function DataStoreEditFormImpl({ dataStore }: { dataStore: DataStore }) {
  const updateDataStore = useUpdateDataStore(dataStore.id);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editDataStoreSchema,
    defaultValues: pick(dataStore, editDataStoreSchema.keyof().options),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateDataStore.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("Updated DataStore", { variant: "success" });

          navigate(
            dsmPaths.createDsmPath(dsmPaths.DATASTORE_DETAILS, {
              dataStoreId: dataStore.id,
            }),
          );
        },
        onError() {
          enqueueSnackbar("Unable to update DataStore", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateDataStore.isLoading}
      submitText="Update"
    >
      <TextField control={control} name="name" required />
      <UserSelect control={control} name="ownerId" />
      <TextField control={control} name="version" />
      <TextField control={control} name="region" />
      <TextField control={control} name="endpoint" />
      <ObjectField control={control} name="config" />
      <CheckboxField control={control} name="disabled" />
      <TextField control={control} name="note" multiline />
      <ObjectField control={control} name="context" />
    </Form>
  );
}
