import { Auth } from "aws-amplify";

export async function getAuthToken(): Promise<string> {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}

export async function checkUserIsAuthenticated(): Promise<boolean> {
  try {
    await Auth.currentSession();
  } catch {
    return false;
  }

  return true;
}

export function redirectToSignIn(): void {
  Auth.federatedSignIn();
}

export function redirectToSignOut(): void {
  Auth.signOut();
}
