/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  MeDataResponse,
  UserCreateRequest,
  UserDataResponse,
  UserListResponse,
  UserUpdateRequest,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  MeDataResponseFromJSON,
  MeDataResponseToJSON,
  UserCreateRequestFromJSON,
  UserCreateRequestToJSON,
  UserDataResponseFromJSON,
  UserDataResponseToJSON,
  UserListResponseFromJSON,
  UserListResponseToJSON,
  UserUpdateRequestFromJSON,
  UserUpdateRequestToJSON,
} from "../models";

export interface CreateUserRequest {
  userCreateRequest: UserCreateRequest;
}

export interface DeleteUserRequest {
  userId: string;
}

export interface FetchUserRequest {
  userId: string;
}

export interface ListUsersRequest {
  username?: string | null;
  usernameLike?: string | null;
  roleId?: string | null;
  managed?: boolean | null;
  disabled?: boolean | null;
  admin?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateUserRequest {
  userId: string;
  userUpdateRequest: UserUpdateRequest;
}

export class UserApi extends runtime.BaseAPI {
  async createUser(
    this: UserApi,
    requestParameters: CreateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.userCreateRequest,
      "userCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/users`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserCreateRequestToJSON(requestParameters.userCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return UserDataResponseFromJSON(jsonValue);
  }

  async deleteUser(
    this: UserApi,
    requestParameters: DeleteUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.userId, "userId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchUser(
    this: UserApi,
    requestParameters: FetchUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserDataResponse> {
    runtime.assertParamIsPresent(requestParameters.userId, "userId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return UserDataResponseFromJSON(jsonValue);
  }

  async fetchUserMe(
    this: UserApi,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MeDataResponse> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/users/me`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return MeDataResponseFromJSON(jsonValue);
  }

  async listUsers(
    this: UserApi,
    requestParameters: ListUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserListResponse> {
    const queryParameters: any = {};

    if (requestParameters.username != null) {
      queryParameters["username"] = requestParameters.username;
    }

    if (requestParameters.usernameLike != null) {
      queryParameters["username_like"] = requestParameters.usernameLike;
    }

    if (requestParameters.roleId != null) {
      queryParameters["role_id"] = requestParameters.roleId;
    }

    if (requestParameters.managed != null) {
      queryParameters["managed"] = requestParameters.managed;
    }

    if (requestParameters.disabled != null) {
      queryParameters["disabled"] = requestParameters.disabled;
    }

    if (requestParameters.admin != null) {
      queryParameters["admin"] = requestParameters.admin;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/users`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return UserListResponseFromJSON(jsonValue);
  }

  async updateUser(
    this: UserApi,
    requestParameters: UpdateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserDataResponse> {
    runtime.assertParamIsPresent(requestParameters.userId, "userId");

    runtime.assertParamIsPresent(
      requestParameters.userUpdateRequest,
      "userUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UserUpdateRequestToJSON(requestParameters.userUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return UserDataResponseFromJSON(jsonValue);
  }
}
