import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import { NewLqsResourceForm, useCreateGroup, WorkflowSelect } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createGroupSchema } from "../schemas";

export function GroupCreateForm() {
  const lqsNavigator = useLqsNavigator({ toGroupDetails: true });

  return (
    <NewLqsResourceForm
      schema={createGroupSchema}
      defaultValues={{
        name: null,
        defaultWorkflowId: null,
        locked: false,
        note: null,
        context: null,
      }}
      resourceName="group"
      mutation={useCreateGroup()}
      createDetailsLocation={(response) =>
        lqsNavigator.toGroupDetails({ groupId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <WorkflowSelect control={control} name="defaultWorkflowId" />
          <CheckboxField control={control} name="locked" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </NewLqsResourceForm>
  );
}
