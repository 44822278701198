import { useMutation } from "@tanstack/react-query";
import { CheckboxField, NewResourceForm, TextField } from "~/components/Form";
import { useCommonResourcesContextValue } from "../../../context";
import { RoleSelect } from "../../../role-select";
import { createUserSchema } from "../schemas";

export function UserCreateForm() {
  const { createUserCreateMutationOptions, createUserDetailsLocation } =
    useCommonResourcesContextValue();

  return (
    <NewResourceForm
      schema={createUserSchema}
      defaultValues={{
        username: null,
        roleId: null,
        admin: false,
        managed: false,
        disabled: false,
        externalId: null,
      }}
      resourceName="user"
      mutation={useMutation(createUserCreateMutationOptions())}
      createDetailsLocation={(response) =>
        createUserDetailsLocation({ userId: response.data.id })
      }
    >
      {(control) => (
        <>
          <TextField control={control} name="username" required />
          <RoleSelect control={control} name="roleId" />
          <CheckboxField control={control} name="admin" />
          <CheckboxField control={control} name="managed" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="externalId" />
        </>
      )}
    </NewResourceForm>
  );
}
