import { useMutation, useQuery } from "@tanstack/react-query";
import { CheckboxField, EditResourceForm, TextField } from "~/components/Form";
import { selectData } from "~/utils";
import { useCommonResourcesContextValue } from "../../../context";
import { RoleSelect } from "../../../role-select";
import { useUserParams } from "../hooks";
import { editUserSchema } from "../schemas";

export function UserEditForm() {
  const { userId } = useUserParams();

  const {
    createUserDetailsLocation,
    createUserFetchQueryOptions,
    createUserUpdateMutationOptions,
  } = useCommonResourcesContextValue();

  return (
    <EditResourceForm
      schema={editUserSchema}
      resourceName="user"
      query={useQuery({
        ...createUserFetchQueryOptions(userId),
        select: selectData,
      })}
      editableFields={editUserSchema.keyof().options}
      mutation={useMutation(createUserUpdateMutationOptions(userId))}
      detailsLocation={createUserDetailsLocation({ userId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="username" required />
          <RoleSelect control={control} name="roleId" />
          <CheckboxField control={control} name="admin" />
          <CheckboxField control={control} name="disabled" />
          <TextField control={control} name="externalId" />
        </>
      )}
    </EditResourceForm>
  );
}
