import type React from "react";
import { useQuery } from "@tanstack/react-query";
import { useCommonResourcesContextValue } from "../context";

export function AdminGuard({ children }: { children: React.ReactNode }) {
  const { createCurrentUserQueryOptions } = useCommonResourcesContextValue();

  const isAdminQuery = useQuery({
    ...createCurrentUserQueryOptions(),
    select(response) {
      return response.data === null || response.data.admin;
    },
  });

  if (isAdminQuery.isSuccess && isAdminQuery.data) {
    return children;
  }

  return null;
}
