/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export const ProcessState = {
  Ready: "ready",
  Queued: "queued",
  Processing: "processing",
  Finalizing: "finalizing",
  Completed: "completed",
  Failed: "failed",
  Cancelled: "cancelled",
  Archived: "archived",
} as const;
export type ProcessState = (typeof ProcessState)[keyof typeof ProcessState];

export function ProcessStateFromJSON(json: any): ProcessState {
  return ProcessStateFromJSONTyped(json);
}

export function ProcessStateFromJSONTyped(json: any): ProcessState {
  return json as ProcessState;
}

export function ProcessStateToJSON(value?: ProcessState | null): any {
  return value as any;
}
