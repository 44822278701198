import React, { useRef, useState } from "react";
import type {
  AutocompleteInputChangeReason,
  AutocompleteProps,
  TextFieldProps,
} from "@mui/material";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  outlinedInputClasses,
  TextField,
} from "@mui/material";
import type { QueryClient, UseQueryOptions } from "@tanstack/react-query";
import { useQueries, useQuery } from "@tanstack/react-query";
import type { FieldValues, Path } from "react-hook-form";
import * as z from "zod";
import { getFieldLabel } from "~/domain/common";
import { identity } from "~/lib/std";
import { useField } from "./hooks";
import type { BaseInputProps, FieldPropsFromInputProps, Option } from "./types";

export interface ResourceSelectInputProps
  extends BaseInputProps<string | null, string | null> {
  size?: "small" | "medium";
  noHelperText?: boolean;
}

interface InternalResourceSelectInputProps<TResource extends object>
  extends ResourceSelectInputProps {
  createListQueryOptions: (
    text: string,
  ) => Pick<
    UseQueryOptions<{ data: ReadonlyArray<TResource> }>,
    "queryKey" | "queryFn"
  >;
  createFetchQueryOptions: (
    uuid: string | null,
  ) => Pick<UseQueryOptions<{ data: TResource }>, "queryKey" | "queryFn">;
  selectOption: (resource: TResource) => Option;
}

export function ResourceSelectInput<TResource extends object>({
  value,
  onChange,
  name,
  errorMessage,
  label = getFieldLabel(name),
  size = "medium",
  noHelperText = false,
  required,
  createListQueryOptions,
  createFetchQueryOptions,
  selectOption,
}: InternalResourceSelectInputProps<TResource>) {
  const {
    open,
    handleOpen,
    handleClose,
    inputValue,
    handleInputValueChange,
    autocompleteValue,
    handleAutocompleteValueChange,
    isOptionEqualToValue,
    loading,
    options,
    filterOptions,
  } = useResourceSelectInput({
    value,
    onChange,
    createListQueryOptions,
    createFetchQueryOptions,
    selectOption,
  });

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      size={size}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={inputValue}
      onInputChange={handleInputValueChange}
      value={autocompleteValue}
      onChange={handleAutocompleteValueChange}
      isOptionEqualToValue={isOptionEqualToValue}
      options={options}
      filterOptions={filterOptions}
      loading={loading}
      renderInput={(props) => (
        <TextField
          {...props}
          required={required}
          label={label}
          error={errorMessage !== undefined}
          helperText={noHelperText ? undefined : errorMessage ?? " "}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {props.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

function useResourceSelectInput<TResource extends object>({
  value,
  onChange,
  createListQueryOptions,
  createFetchQueryOptions,
  selectOption,
}: Pick<
  InternalResourceSelectInputProps<TResource>,
  | "value"
  | "onChange"
  | "createListQueryOptions"
  | "createFetchQueryOptions"
  | "selectOption"
>) {
  // Fetch the resource corresponding to the selected ID, if any
  const valueQuery = useQuery({
    ...createFetchQueryOptions(value),
    select({ data }) {
      return selectOption(data);
    },
  });

  const [open, setOpen] = useState(false);

  const [inputValue, setInputValue] = useState("");
  // If the text field's value is a UUID, a direct lookup is performed to
  // see if a resource matches that ID
  const isUuid = checkIsUuid(inputValue);

  // If the text field's value is a UUID, then the component should "search"
  // by that UUID. Essentially, perform a GET request and if it returns a 404
  // then no options matched the "search value"
  const uuidQuery = useQuery({
    ...createFetchQueryOptions(isUuid ? inputValue : null),
    select({ data }) {
      return selectOption(data);
    },
  });
  const searchQuery = useQuery({
    ...createListQueryOptions(inputValue),
    keepPreviousData: true,
    select({ data }) {
      return data.map(selectOption);
    },
  });

  let loading: boolean;
  let options: Array<Option>;
  if (open) {
    if (isUuid) {
      loading = uuidQuery.isFetching;
      options = uuidQuery.isSuccess ? [uuidQuery.data] : [];
    } else {
      loading = searchQuery.isFetching;
      options = searchQuery.data ?? [];
    }
  } else {
    loading = valueQuery.isFetching;
    options = valueQuery.isSuccess ? [valueQuery.data] : [];
  }

  const autocompleteValue = valueQuery.data ?? null;

  return {
    autocompleteValue,
    handleAutocompleteValueChange(_: unknown, newValue: Option | null) {
      onChange(newValue?.value ?? null);
    },
    isOptionEqualToValue(option: Option, value: Option) {
      return option.value === value.value;
    },
    open,
    handleOpen() {
      setOpen(true);
    },
    handleClose() {
      setOpen(false);
    },
    inputValue,
    handleInputValueChange(_: unknown, newInputValue: string) {
      setInputValue(newInputValue);
    },
    loading,
    options,
    // Disable client-side filtering
    filterOptions: identity,
  };
}

function checkIsUuid(input: string): boolean {
  return z.string().uuid().safeParse(input).success;
}

export interface ResourceSelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> extends FieldPropsFromInputProps<
    TFieldValues,
    TName,
    ResourceSelectInputProps
  > {}

interface InternalResourceSelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TResource extends object,
> extends FieldPropsFromInputProps<
    TFieldValues,
    TName,
    InternalResourceSelectInputProps<TResource>
  > {}

export function ResourceSelectField<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TResource extends object,
>({
  control,
  ...rest
}: InternalResourceSelectFieldProps<TFieldValues, TName, TResource>) {
  const { value, onChange, errorMessage } = useField({
    control,
    name: rest.name,
  });

  return (
    <ResourceSelectInput
      value={value}
      onChange={onChange}
      errorMessage={errorMessage}
      {...rest}
    />
  );
}

export interface ResourceMultiSelectInputProps
  extends BaseInputProps<ReadonlyArray<string>, Array<string>> {
  size?: "small" | "medium";
  noHelperText?: boolean;
}

interface InternalResourceMultiSelectInputProps<TResource extends object>
  extends ResourceMultiSelectInputProps {
  createListQueryOptions: (
    text: string,
  ) => Pick<
    UseQueryOptions<{ data: ReadonlyArray<TResource> }>,
    "queryKey" | "queryFn"
  >;
  createFetchQueryOptions: (
    uuid: string | null,
  ) => Pick<UseQueryOptions<{ data: TResource }>, "queryKey" | "queryFn">;
  selectOption: (resource: TResource) => Option;
  context?: React.Context<QueryClient | undefined>;
}

export function ResourceMultiSelectInput<TResource extends object>({
  value,
  onChange,
  name,
  errorMessage,
  label = getFieldLabel(name),
  size = "medium",
  noHelperText = false,
  required,
  createListQueryOptions,
  createFetchQueryOptions,
  selectOption,
  context,
}: InternalResourceMultiSelectInputProps<TResource>) {
  const { inputRef, ...autocompleteProps } = useResourceMultiSelectInput({
    value,
    onChange,
    createListQueryOptions,
    createFetchQueryOptions,
    selectOption,
    context,
  });

  const helperText = noHelperText ? undefined : errorMessage ?? " ";

  return (
    <Autocomplete
      {...autocompleteProps}
      fullWidth
      autoHighlight
      size={size}
      sx={{
        // Increase specificity so padding-right takes effect
        [`&&& .${autocompleteClasses.inputRoot}`]: {
          scrollBehavior: "smooth",
          pr: 0.75,
          overflowX: "auto",
          overflowY: "hidden",
          flexWrap: "nowrap",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          [`& .${autocompleteClasses.input}`]: {
            minWidth: 60,
          },
        },
        [`& .${autocompleteClasses.endAdornment}`]: {
          position: "static",
          display: "flex",
        },
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          // Notched outline is a child of the input root but it shouldn't
          // scroll, so give it a fixed position relative to a containing block
          // that'll be rendered below
          position: "fixed",
        },
      }}
      renderInput={({
        disabled,
        fullWidth,
        size,
        id,
        InputLabelProps,
        InputProps,
        inputProps,
      }) => (
        // Need to wrap the outlined input in a containing block so the notched
        // outline can be positioned according to it. Can't just render a
        // typical text field since the form control would have to be the
        // notched outline's containing block which would break the styling when
        // helper text was present since it's also a direct child of the form
        // control
        <FormControl
          disabled={disabled}
          fullWidth={fullWidth}
          size={size}
          required={required}
          error={errorMessage !== undefined}
        >
          <InputLabel {...InputLabelProps}>{label}</InputLabel>
          <Box
            sx={{
              // Makes this a containing block for the fixed-position notched
              // outline
              willChange: "transform",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <OutlinedInput
              id={id}
              aria-describedby={`${id}-helper-text`}
              inputRef={inputRef}
              inputProps={inputProps}
              {...InputProps}
              label={label}
              endAdornment={
                <>
                  <Box
                    sx={{
                      width: 20,
                      alignSelf: "stretch",
                      flex: "none",
                      position: "relative",
                    }}
                  >
                    {autocompleteProps.loading && (
                      <CircularProgress
                        color="inherit"
                        size={18}
                        sx={{
                          position: "absolute",
                          inset: 0,
                          m: "auto",
                        }}
                      />
                    )}
                  </Box>
                  {InputProps.endAdornment}
                </>
              }
            />
          </Box>
          {helperText != null && (
            <FormHelperText id={`${id}-helper-text`}>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

function useResourceMultiSelectInput<TResource extends object>({
  value: fieldValue,
  onChange: onFieldChange,
  createListQueryOptions,
  createFetchQueryOptions,
  selectOption,
  context,
}: Pick<
  InternalResourceMultiSelectInputProps<TResource>,
  | "value"
  | "onChange"
  | "createListQueryOptions"
  | "createFetchQueryOptions"
  | "selectOption"
  | "context"
>): Required<
  Pick<
    AutocompleteProps<string, true, undefined, undefined>,
    | "multiple"
    | "disableCloseOnSelect"
    | "value"
    | "onChange"
    | "open"
    | "onOpen"
    | "onClose"
    | "onFocus"
    | "onBlur"
    | "inputValue"
    | "onInputChange"
    | "getOptionLabel"
    | "loading"
    | "options"
    | "filterOptions"
  > &
    Required<Pick<TextFieldProps, "inputRef">>
> {
  const [open, setOpen] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const valuesQueries = useQueries({
    queries: (fieldValue as Array<string>).map((resourceId) => ({
      ...createFetchQueryOptions(resourceId),
      select({ data }: { data: TResource }) {
        return selectOption(data);
      },
    })),
    context,
  });

  const searchQuery = useQuery({
    ...createListQueryOptions(inputValue),
    keepPreviousData: true,
    select({ data }) {
      return data.map(selectOption);
    },
    context,
  });

  let loading: boolean;
  let options: Array<Option>;
  if (open) {
    loading = searchQuery.isFetching;
    options = searchQuery.data ?? [];
  } else {
    loading = valuesQueries.some((query) => query.isFetching);
    options = valuesQueries.flatMap((query) =>
      query.isSuccess ? query.data : [],
    );
  }

  // MUI's autocomplete resets the input state each time the value prop passed
  // to it changes, so need to use the underlying form field value here since
  // it's kept in state
  const value = fieldValue as Array<string>;
  const autocompleteOptions = options.map((option) => option.value);

  return {
    multiple: true,
    disableCloseOnSelect: true,
    inputRef,
    value,
    onChange(_: unknown, newValues: Array<string>) {
      onFieldChange(newValues);
    },
    open,
    onOpen() {
      setOpen(true);
    },
    onClose() {
      setOpen(false);
    },
    onFocus() {
      inputRef.current?.parentElement?.scroll({
        left: inputRef.current.offsetLeft,
      });
    },
    onBlur() {
      inputRef.current?.parentElement?.scroll({ left: 0 });
    },
    inputValue,
    onInputChange(
      e: React.SyntheticEvent | null,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) {
      // MUI resets the input value when an option is (de)selected but doesn't
      // provide a way to disable this behavior:
      // https://github.com/mui/material-ui/issues/36085#issuecomment-1468024330
      //
      // Instead, need to detect when it's _likely_ happening (since they also
      // don't expose the underlying cause when `reason === "reset"`) and ignore
      // the event
      if (reason !== "reset") {
        setInputValue(newInputValue);
      } else if (e?.type === "blur" || e?.type === "change") {
        setInputValue(newInputValue);
      }
    },
    getOptionLabel(optionValue: string) {
      // Considerations:
      // 1. `optionValue` might not correspond to any option if MUI's trying to
      //    render a chip for one of the selected values
      // 2. If `optionValue` corresponds to a selected value but not an option
      //    then there might not even be an option loaded yet, so would need
      //    some kind of loading text

      let foundOption = options.find((option) => option.value === optionValue);
      if (foundOption !== undefined) {
        return foundOption.label;
      }

      if (open) {
        foundOption = valuesQueries.find(
          (query) => query.isSuccess && query.data.value === optionValue,
        )?.data;
        if (foundOption !== undefined) {
          return foundOption.label;
        }
      }

      return "Loading...";
    },
    loading,
    options: autocompleteOptions,
    // Disable client-side filtering
    filterOptions: identity,
  };
}

export interface ResourceMultiSelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> extends FieldPropsFromInputProps<
    TFieldValues,
    TName,
    ResourceMultiSelectInputProps
  > {}

interface InternalResourceMultiSelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TResource extends object,
> extends FieldPropsFromInputProps<
    TFieldValues,
    TName,
    InternalResourceMultiSelectInputProps<TResource>
  > {}

export function ResourceMultiSelectField<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TResource extends object,
>({
  control,
  ...rest
}: InternalResourceMultiSelectFieldProps<TFieldValues, TName, TResource>) {
  const { value, onChange, errorMessage } = useField({
    control,
    name: rest.name,
  });

  return (
    <ResourceMultiSelectInput
      value={value}
      onChange={onChange}
      errorMessage={errorMessage}
      {...rest}
    />
  );
}
