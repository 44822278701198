import { CheckboxField, ObjectField, TextField } from "~/components/Form";
import { EditLqsResourceForm, useUpdateWorkflow, useWorkflow } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { EDITABLE_WORKFLOW_FIELDS, editWorkflowSchema } from "../schemas";

export function WorkflowEditForm() {
  const { workflowId } = useTypedParams(lqsRoutePaths.WORKFLOW_EDIT);

  const lqsNavigator = useLqsNavigator({ toWorkflowDetails: true });

  return (
    <EditLqsResourceForm
      schema={editWorkflowSchema}
      resourceName="workflow"
      query={useWorkflow(workflowId, { select: selectData })}
      editableFields={EDITABLE_WORKFLOW_FIELDS}
      mutation={useUpdateWorkflow(workflowId)}
      detailsLocation={lqsNavigator.toWorkflowDetails({ workflowId })}
    >
      {(control) => (
        <>
          <TextField control={control} name="name" required />
          <CheckboxField control={control} name="_default" />
          <CheckboxField control={control} name="disabled" />
          <ObjectField control={control} name="contextSchema" />
          <TextField control={control} name="note" multiline />
          <ObjectField control={control} name="context" />
        </>
      )}
    </EditLqsResourceForm>
  );
}
