import { BooleanField, FuzzyField, useStudioForm } from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "../../../filters";
import { RoleSelect } from "../../../role-select";
import { listUsersSchema } from "../schemas";
import { useUserSearchRequest } from "./user-table";

export function UserTableFilters() {
  const [request, setRequest] = useUserSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listUsersSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <FuzzyField
        control={control}
        exactName="username"
        fuzzyName="usernameLike"
      />
      <RoleSelect control={control} name="roleId" />
      <BooleanField control={control} name="admin" />
      <BooleanField control={control} name="managed" />
      <BooleanField control={control} name="disabled" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
