import {
  BooleanField,
  FuzzyField,
  SelectField,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { FiltersSidebar, withoutBaseTableModel } from "~/components/Table";
import { CommonFilters } from "~/domain/common";
import { LogSelect, TopicSelect, typeEncodingOptions } from "~/lqs";
import { listTopicsSchema } from "../schemas";
import { useTopicSearchRequest } from "./TopicTable";

export function TopicFilters() {
  const [request, setRequest] = useTopicSearchRequest();

  const { control, handleSubmit } = useStudioForm({
    schema: listTopicsSchema,
    values: withoutBaseTableModel(request),
    onSubmit: setRequest,
  });

  return (
    <FiltersSidebar handleSubmit={handleSubmit}>
      <TextField control={control} name="id" />
      <FuzzyField control={control} exactName="name" fuzzyName="nameLike" />
      <FuzzyField
        control={control}
        exactName="typeName"
        fuzzyName="typeNameLike"
      />
      <SelectField
        control={control}
        name="typeEncoding"
        options={typeEncodingOptions}
      />
      <LogSelect control={control} name="logId" />
      <TopicSelect control={control} name="associatedTopicId" />
      <BooleanField control={control} name="locked" />
      <BooleanField control={control} name="strict" />
      <BooleanField control={control} name="latched" />
      <CommonFilters control={control} />
    </FiltersSidebar>
  );
}
