import type { UseQueryResult } from "@tanstack/react-query";
import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { selectData } from "~/utils";
import { useTopics } from "../queries";
import { usePlayerParams } from "./use-player-params";

export function usePlayerTopics(): UseQueryResult<Array<Topic>> {
  const playerConfig = usePlayerParams();

  const logId = playerConfig.logId ?? undefined;

  return useTopics(
    {
      logId,
      limit: -1,
      sort: "asc",
      order: "name",
    },
    { select: selectData, enabled: logId !== undefined },
  );
}

export function useLoadedPlayerTopics(): Array<Topic> {
  const query = usePlayerTopics();

  invariant(query.isSuccess, "Expected player topics to be loaded");

  return query.data;
}
