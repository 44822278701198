import React from "react";
import { Public } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Card } from "~/components/Card";
import { useStudioForm } from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { selectCountableListResponse } from "~/domain/common";
import {
  filterSchema,
  getSortParams,
  GroupsField,
  LabelsField,
  LogThumbnailCard,
  NameField,
  PaginationControls,
  PlayableLogsField,
  SortField,
  useLogsSearchRequest,
} from "~/domain/logs";
import { useLogs } from "~/lqs";
import { pluralize } from "~/utils";

const GridRoot = styled("div")(({ theme }) => ({
  marginBlockEnd: theme.spacing(3),
  display: "grid",
  gap: theme.spacing(3),
  gridTemplateColumns: "minmax(0, 1fr)",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
}));

export function LogThumbnails({ onOpenMap }: { onOpenMap: () => void }) {
  const [request, setRequest] = useLogsSearchRequest();

  const logsQuery = useLogs(
    {
      nameLike: request.name,
      startTimeNull: request.playable ? false : undefined,
      groupId: request.groupIds,
      tagLabelIdsIncludes: request.labelIds,
      limit: request.limit,
      offset: request.offset,
      ...getSortParams(request),
      includeCount: true,
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: { name: request.name },
    onSubmit: setRequest,
  });

  function handleOpenMap(): void {
    onOpenMap();
    setRequest({ offset: 0 });
  }

  return (
    <Card
      title="Logs"
      action={
        <Button
          color="primary"
          variant="contained"
          disableElevation
          startIcon={<Public />}
          onClick={handleOpenMap}
        >
          Open Map
        </Button>
      }
    >
      <Stack direction="row" spacing={1.5} sx={{ alignItems: "center" }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: "25ch" }}>
          <NameField control={control} />
        </Box>
        <PlayableLogsField request={request} setRequest={setRequest} />
        <Box sx={{ width: "23ch" }}>
          <GroupsField request={request} setRequest={setRequest} />
        </Box>
        <Box sx={{ width: "23ch" }}>
          <LabelsField request={request} setRequest={setRequest} />
        </Box>
        <SortField request={request} setRequest={setRequest} />
      </Stack>
      <Box sx={{ position: "relative", my: 2 }}>
        <Divider />
        {logsQuery.isFetching && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </Box>
      <QueryRenderer
        query={logsQuery}
        error={
          <Typography
            variant="h4"
            component="p"
            sx={{ textAlign: "center", my: 4 }}
          >
            An error occurred searching for logs
          </Typography>
        }
        success={(response) => {
          if (response.count === 0) {
            return (
              <Typography
                variant="h4"
                component="p"
                sx={{ textAlign: "center", my: 4 }}
              >
                No logs matched the filters
              </Typography>
            );
          } else {
            return (
              <>
                <Typography sx={{ mb: 2 }}>
                  {pluralize(response.count, "log")}
                </Typography>
                <GridRoot>
                  {response.data.map((log) => (
                    <LogThumbnailCard key={log.id} log={log} />
                  ))}
                </GridRoot>
              </>
            );
          }
        }}
      />
      {logsQuery.isSuccess && (
        <>
          <Divider sx={{ mb: 2 }} />
          <PaginationControls
            logCount={logsQuery.data.count}
            request={request}
            setRequest={setRequest}
          />
        </>
      )}
    </Card>
  );
}
