import React from "react";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { FieldValues } from "react-hook-form";
import type { Path } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import type { StrictOmit } from "ts-essentials";
import type { z } from "zod";
import { pick } from "~/lib/std";
import { Card } from "../Card";
import { Loading } from "../Loading";
import { QueryRenderer } from "../QueryRenderer";
import { ErrorMessage } from "../error-message";
import { Form } from "./Form";
import type { UseStudioFormProps, UseStudioFormReturn } from "./hooks";
import { useStudioForm } from "./hooks";
import { getChangedFields } from "./utils";

export interface EditResourceFormProps<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
> extends Pick<
    UseStudioFormProps<z.ZodType<TFormValues, z.ZodTypeDef, unknown>>,
    "schema"
  > {
  resourceName: string;
  query: UseQueryResult<TResource>;
  mutation: UseMutationResult<{ data: TResource }, unknown, TRequest>;
  editableFields: ReadonlyArray<keyof TResource & string>;
  detailsLocation: Partial<Path>;
  children: (
    control: UseStudioFormReturn<TFormValues>["control"],
    resource: TResource,
  ) => React.ReactNode;
}

export function EditResourceForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
>({
  schema,
  resourceName,
  query,
  editableFields,
  mutation,
  detailsLocation,
  children,
}: EditResourceFormProps<TRequest, TFormValues, TResource>) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<Loading type="circular" />}
        error={<ErrorMessage>Error fetching {resourceName}</ErrorMessage>}
        success={(resource) => (
          <InnerForm
            schema={schema}
            resourceName={resourceName}
            resource={resource}
            editableFields={editableFields}
            mutation={mutation}
            detailsLocation={detailsLocation}
          >
            {children}
          </InnerForm>
        )}
      />
    </Card>
  );
}

function InnerForm<
  TRequest extends FieldValues,
  TFormValues extends TRequest,
  TResource extends object,
>({
  schema,
  resourceName,
  resource,
  editableFields,
  mutation,
  detailsLocation,
  children,
}: StrictOmit<
  EditResourceFormProps<TRequest, TFormValues, TResource>,
  "query"
> & { resource: TResource }) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema,
    defaultValues: pick(resource, editableFields) as any,
    onSubmit: function onSubmit(values: TFormValues) {
      const changedFields = getChangedFields(values, dirtyFields as any);

      mutation.mutate(changedFields as TRequest, {
        onSuccess() {
          enqueueSnackbar(`Updated ${resourceName}`, { variant: "success" });

          navigate(detailsLocation);
        },
        onError() {
          enqueueSnackbar(`Unable to update ${resourceName}`, {
            variant: "error",
          });
        },
      });
    } as any,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={mutation.isLoading}
      submitText="Update"
    >
      {children(control, resource)}
    </Form>
  );
}
