import React from "react";
import { Button, Stack, styled } from "@mui/material";
import { useScreenConfiguration, SidebarHeader } from "~/layout";
import type { useStudioForm } from "../Form";

// Goal: stick the submit button to the bottom of the sidebar so user can see
// it without needing to scroll to the bottom of the sidebar.
//
// Problem: Sidebar has bottom padding where form fields will be visible
// between bottom of stickily-positioned button and bottom of sidebar as
// they're scrolled out of view.
//
// Solution: Use ::before and ::after pseudo elements around button to hide
// any elements below button and make them nicely fade out as the get close to
// top of button.
//
// Note: can't style the button's ::before and ::after because entire button
// needs to appear on top of ::before and ::after. If button were stickily-
// positioned it would create a new stacking context for its pseudo element
// children and they'd no longer be able to be stacked underneath it. Instead,
// need to put the button in a container, make the container sticky, and style
// the container's ::before and ::after pseudo elements. As long as the
// container doesn't do anything to change its height, it'll be sized entirely
// by the inner button which will let the absolute position calculation below
// work correctly with percentages.
const ButtonContainer = styled("div")(({ theme }) => ({
  // ::before element will extend 3 spacing units above button's top edge.
  // The parent stack component will put 2 spacing units between all elements
  // so an additional 1 spacing unit is necessary at the top so the last form
  // field's helper text isn't cut off by the ::before's gradient when the
  // sidebar is scrolled to its bottom. Using margin rather than padding keeps
  // percentage absolute positioning calculations below simple.
  marginTop: theme.spacing(1),

  // The submit button needs to stick to the bottom of the sidebar
  position: "sticky",
  bottom: 0,

  // MUI's form field labels have a z-index set so this container must as well
  // to prevent those labels from appearing on top of the button
  zIndex: 1,

  "&::before": {
    // Put this pseudo element underneath the button
    zIndex: -1,

    content: '""',
    display: "block",

    // Want background image to extend from 3 spacing units above button's top
    // edge to its center
    position: "absolute",
    top: "50%",
    width: "100%",
    height: `calc(${theme.spacing(3)} + 50%)`,
    transform: "translateY(-100%)",

    // Form fields should fade to the background color as they get close to
    // the top of the button
    backgroundImage: `linear-gradient(to bottom, transparent, ${theme.palette.background.default} ${theme.spacing(3)})`,
  },
  "&::after": {
    // Put this pseudo element underneath the button
    zIndex: -1,

    content: '""',
    display: "block",

    // Want background to extend from center of button to 5 spacing units below
    // button's bottom edge. The sidebar layout component has 5 spacing units
    // of bottom padding and the button is at the bottom of the sidebar's
    // content box, so this background will ensure nothing from the center of
    // the button to the bottom of the screen will be visible
    position: "absolute",
    bottom: "50%",
    width: "100%",
    height: `calc(${theme.spacing(5)} + 50%)`,
    transform: "translateY(100%)",

    // Don't show anything below the button
    backgroundColor: theme.palette.background.default,
  },
}));

export function FiltersSidebar({
  handleSubmit,
  children,
}: {
  handleSubmit: ReturnType<typeof useStudioForm>["handleSubmit"];
  children: React.ReactNode;
}) {
  const screenConfiguration = useScreenConfiguration();

  const button = (
    <Button type="submit" variant="contained" fullWidth disableElevation>
      Filter
    </Button>
  );

  return (
    <>
      <SidebarHeader title="Filters" />
      <Stack spacing={2} component="form" onSubmit={handleSubmit}>
        {children}
        {/* Issues getting pseudo element colors to work properly in dark mode
        modal drawers, so disabling stickiness on mobile screens for now */}
        {screenConfiguration === "mobile" ? (
          button
        ) : (
          <ButtonContainer>{button}</ButtonContainer>
        )}
      </Stack>
    </>
  );
}
