import React, { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { createSafeContext } from "~/contexts";
import { ValidationError } from "~/errors";
import type { ValidationSchema, ValidationSchemaOutput } from "~/types";
import type { SearchParamAliases } from "~/utils";
import { createSearchParamHandlers } from "~/utils";
import { getSortableFields } from "./columns";
import { LIMIT_OPTIONS, SortDirection } from "./constants";
import type { Column, ResourceTableModel } from "./types";

const number = z.coerce.number();

function makeRequestSchema<TFilterSchema extends z.AnyZodObject>(
  columns: ReadonlyArray<Column<any, any>>,
  filterSchema: TFilterSchema,
  // This isn't type-safe but all resources (as of now) hav a `createdAt` field
  defaultOrder = "createdAt",
  defaultSortDirection: SortDirection = SortDirection.Desc,
): ValidationSchema<z.output<TFilterSchema> & ResourceTableModel> {
  const sortableFields = getSortableFields(columns);

  const baseSchema = z
    .object({
      sort: z.nativeEnum(SortDirection).default(defaultSortDirection),
      order: z
        .string()
        .refine((value) => sortableFields.includes(value as any))
        .default(defaultOrder),
      limit: number
        .refine((value) => LIMIT_OPTIONS.includes(value))
        .default(LIMIT_OPTIONS[1]),
      offset: number.nonnegative().int().default(0),
    }) // Check if `offset` is a multiple of `limit`
    .refine(({ limit, offset }) => offset % limit === 0);

  return filterSchema.and(baseSchema);
}

export function useSearchRequest<TRequestParams extends Record<any, any>>(
  requestSchema: ValidationSchema<TRequestParams>,
  aliases: NoInfer<SearchParamAliases<TRequestParams>> = {},
): [TRequestParams, (updates: Partial<TRequestParams>) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlers = useMemo(
    () => createSearchParamHandlers(requestSchema, aliases),
    [requestSchema, aliases],
  );

  let request: TRequestParams;
  try {
    request = handlers.deserialize(searchParams);
  } catch (e) {
    throw new ValidationError({
      source: "search",
      cause: e,
    });
  }

  const setRequest = useCallback(
    (updates: Partial<TRequestParams>) => {
      setSearchParams((prevSearchParams) => {
        let updatedSearchParams = new URLSearchParams(prevSearchParams);

        // The offset should always reset when the filters, page size or
        // sort parameters change
        if (!("offset" in updates)) {
          // Offset search param may be aliased
          updatedSearchParams.delete((aliases as any).offset ?? "offset");
        }

        updatedSearchParams = handlers.serialize(updates, updatedSearchParams);

        return updatedSearchParams;
      });
    },
    [setSearchParams, aliases, handlers],
  );

  return [request, setRequest];
}

export const [useEmbedded, EmbeddedContext] =
  createSafeContext<boolean>("Embedded");

export function createSearchRequestProvider<
  TFilterSchema extends z.AnyZodObject,
>({
  columns,
  filterSchema,
  defaultOrder,
  defaultSortDirection,
  aliases,
}: {
  columns: ReadonlyArray<Column<any, any>>;
  filterSchema: TFilterSchema;
  defaultOrder?: string;
  defaultSortDirection?: SortDirection;
  aliases?: Partial<Record<keyof z.infer<TFilterSchema> & string, string>>;
}) {
  const requestSchema = makeRequestSchema(
    columns,
    filterSchema,
    defaultOrder,
    defaultSortDirection,
  );

  type RequestParams = ValidationSchemaOutput<typeof requestSchema>;

  type ContextValue = ReturnType<typeof useSearchRequest<RequestParams>>;

  const [useSearchRequestContext, SearchRequestContext] =
    createSafeContext<ContextValue>("SearchRequest");

  function FullSearchRequestProvider({
    children,
  }: {
    children: React.ReactNode;
  }) {
    return (
      <SearchRequestContext.Provider
        value={useSearchRequest(requestSchema, aliases)}
      >
        {children}
      </SearchRequestContext.Provider>
    );
  }

  function EmbeddedSearchRequestProvider({
    children,
  }: {
    children: React.ReactNode;
  }) {
    const [request, _setRequest] = useState(() =>
      // This is assumed to not throw. All the filters should be `null` or
      // default values but they'll be ignored. The sort and pagination
      // parameters will also be set to their defaults and should be the only
      // ones actually used in an embedded setting.
      requestSchema.parse({}),
    );

    const setRequest = useCallback(
      (updates: Partial<RequestParams>) => {
        _setRequest((prevRequest) => {
          return {
            ...prevRequest,
            ...updates,
            ...(!("offset" in updates) && {
              offset: 0,
            }),
          };
        });
      },
      [_setRequest],
    );

    return (
      <SearchRequestContext.Provider
        value={[{ ...request, limit: 10 }, setRequest]}
      >
        {children}
      </SearchRequestContext.Provider>
    );
  }

  function SearchRequestProvider({
    children,
    embedded = false,
  }: {
    children: React.ReactNode;
    embedded?: boolean;
  }) {
    const ProviderImpl = embedded
      ? EmbeddedSearchRequestProvider
      : FullSearchRequestProvider;

    return (
      <EmbeddedContext.Provider value={embedded}>
        <ProviderImpl>{children}</ProviderImpl>
      </EmbeddedContext.Provider>
    );
  }

  return [useSearchRequestContext, SearchRequestProvider] as const;
}
