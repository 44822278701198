/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Query } from "./Query";
import { QueryFromJSON, QueryFromJSONTyped, QueryToJSON } from "./Query";

export interface QueryDataResponse {
  data: Query;
}

export function QueryDataResponseFromJSON(json: any): QueryDataResponse {
  return QueryDataResponseFromJSONTyped(json);
}

export function QueryDataResponseFromJSONTyped(json: any): QueryDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: QueryFromJSON(json["data"]),
  };
}

export function QueryDataResponseToJSON(
  value: QueryDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: QueryToJSON(value.data),
  };
}
