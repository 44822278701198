/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Workflow } from "./Workflow";
import {
  WorkflowFromJSON,
  WorkflowFromJSONTyped,
  WorkflowToJSON,
} from "./Workflow";

export interface WorkflowDataResponse {
  data: Workflow;
}

export function WorkflowDataResponseFromJSON(json: any): WorkflowDataResponse {
  return WorkflowDataResponseFromJSONTyped(json);
}

export function WorkflowDataResponseFromJSONTyped(
  json: any,
): WorkflowDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: WorkflowFromJSON(json["data"]),
  };
}

export function WorkflowDataResponseToJSON(
  value: WorkflowDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: WorkflowToJSON(value.data),
  };
}
